import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbAccordionConfig } from "@ng-bootstrap/ng-bootstrap";
import { GlobalService } from "app/linknet-common/globals/global.service";
import { VoucherService, WebshopService } from "app/linknet-common/restful-services/restful.service";
import { OwlOptions } from "ngx-owl-carousel-o";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: 'shop-app-voucher-filter',
    templateUrl: './voucher-filter.component.html',
    styleUrls: ['./voucher-filter.component.scss']
})

export class VoucherFilterComponent implements OnInit{
    public itemsList: any;
    loaderShow: boolean;
    redirect: boolean = false;
    products: any;
    responseProducts: any;
    redeemType: any = 'Omnichannel';
    discountOption: any;
    sendGift: boolean = false;
    hideBanner: boolean = false;
    isGift:boolean =false;
    filter: boolean = true;
    isVoucherKart: boolean = false;
    discountVal: any = '';
    usag_icon: string;
    cartParam: any;
    shoppingCartItemID:any;
    logIndata:any;
    userID:any;
    customerID:any;
    recieverName: string;
    recieverEmail: string;
    recieverMobile: string;
    brandName:string;
    comment:any;
    imageURL: any;
    campaignId: any = 177;
    voucherFinalPrice: number;
    productItem: any;
    vouherQuantity: any = 1;
    finalPrice: any = 0;
    giftImg: string = '../../../../assets/theme.jpg';
    selected: any = 0;
    cartParamPrice: any;
    discountValue: any = 0;
    cartItem: boolean = false;
    isSendgift:boolean = false;
    isMobile:boolean=false;
    isGiftList: any;
    productId:any;
    denomination:any;
    voucherAmount:any;
    effectivePriceAmount:any;
    giftRecieverName = "Reciever";
    giftMessage: string = "You have received a Gift Vouhcer";
    recieverEmailVaild: boolean;
    denominationError: string;
    quantityList : number[]=Array(50).fill(0);
    decqty : boolean = false;
    disableCheckout: Boolean = false;
    isParentCustomer: boolean = true;
    addcartCount : boolean = false;
    addcountList:boolean = false;
    selectedItems: number[] = [];
    totalPrice: any = 0;
    indexData: any = {};
    selectedProductIndex: number = -1;
    selectedProductIndexview: number = -1;
    selectedProductIndexgift :number = -1
    customOwlOptions: OwlOptions = {
        loop: false,
        margin: 10,
        autoplay:false,
        dots: false,
        navSpeed: 700,  
        slideBy: 5,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        responsive: {
          0: {
            items: 2,
            slideBy: 2
          },
          480: {
            items: 5,
            slideBy: 5
          },
          940: {
            items: 5,
            slideBy: 5
          }
        },
        nav: true
    }
    public customOptions = { allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'] };
    selectedTheme: any;
    themeList: any;
    constructor(private router: Router,private toastr: ToastrService,config: NgbAccordionConfig,public globalService: GlobalService, public route: ActivatedRoute,private _httpWebshop: WebshopService, private httpVoucher: VoucherService){
        if (window.innerWidth > 768) {
            this.isMobile = false;
          }else{
            this.isMobile = true;
            config.closeOthers = true;
          } 
        const webshopData = JSON.parse(sessionStorage.getItem('webshopDetail'));
        if (webshopData && webshopData.DomainName === "voucherkart") {
         
        }else{
          this.router.navigate(['/'])
        }
        if(!history.state.rdOption && !history.state.discountOption){
            this.router.navigate(['/'])
        }
        let a = sessionStorage.getItem('vouchersearch');
        if(a && a === 'yes'){
            this.hideBanner = true;
            sessionStorage.removeItem('vouchersearch')
        }
    }
    ngOnInit() {
        this.logIndata = this.globalService.getLoggedInData();
        const webshopSettings = this.globalService.webShopSettings();
        this.customerID = webshopSettings.CustomerID;
        if(this.logIndata){
            this.userID = this.logIndata.UserID;
        }
        this.getProducts();
    }

    ngAfterViewInit(){
        this.getThemes();
    }
    createVoucher(prod){
        let item = prod
        this.router.navigate(['/create-voucher'], {state: {item}, replaceUrl: false});
    }

    inrFormat(val: number) {
        return Number(val).toLocaleString("en-IN");
    }

    getProducts(){
        this.loaderShow = true
            this.httpVoucher.getAllProducts().subscribe(data=>{
                this.loaderShow = false;
                this.products = data;
                this.responseProducts = data;
                if(history.state.discountOption){
                    this.discountVal = history.state.discountOption
                    this.discountFilter(this.discountVal);
                }
                if(history.state.rdOption){
                    this.redeemType = history.state.rdOption
                    this.redeemFilter(this.redeemType);
                }
                this.redirect = true; 
                this.effectivePrice()
                setTimeout(()=>{
                    this.redirect = false;
                },100)
            },_error=>{
                this.loaderShow = false;
            });
    }

    getFilterProducts(){
      this.loaderShow = true
      this.httpVoucher.getAllProducts().subscribe(data=>{
          this.loaderShow = false;
          this.responseProducts = data;
      })
    }

    redeemFilter(type){
      this.getFilterProducts();
        this.redeemType = type;
        if(type === 'In-Store' || type === 'Online'){
            this.products = this.responseProducts.filter(e=>{ return e.usage_mode === type});
        }
        else if(type === 'Online + In-Store'){
          this.products = this.responseProducts.filter(e=>{ return e.usage_mode === type});
        }
        else{
            let a =[]
            this.responseProducts.forEach(e=>{ 
                if(e.usage_mode){
                    a.push(e)
                }
            });
            this.products = a;
        }
        this.discountFilter(this.discountVal);
    }

    discountFilter(type){
        this.discountVal = type;
        this.getFilterProducts();
        if(type && this.discountOption === type){
            this.discountOption = null;
            this.products = this.responseProducts;
        }else{
            
            if(type === '5'){
                this.products = this.responseProducts.filter(e=>
                    e.ProductDiscountPercentage < 6
                );
            }
            else if(type === '10'){
                this.products = this.responseProducts.filter(e=>
                    e.ProductDiscountPercentage > 5 && e.ProductDiscountPercentage < 11
                );
            }
            else if(type === '25'){
                this.products = this.responseProducts.filter(e=>
                    e.ProductDiscountPercentage > 10 && e.ProductDiscountPercentage < 26
                );
            }else if(type === 'above25'){
                this.products = this.responseProducts.filter(e=>
                    e.ProductDiscountPercentage > 25
                );
            }
            if(this.redeemType === 'In-Store' || this.redeemType === 'Online'){
                this.products = this.products.filter(e=>{ return e.usage_mode === this.redeemType});
            }
            else if(this.redeemType === "Online + In-Store"){
              this.products = this.products.filter(e=>{ return e.usage_mode === this.redeemType})
            }
            else{
                let a =[]
                this.products.forEach(e=>{ 
                    if(e.usage_mode){
                        a.push(e)
                    }
                });
                this.products = a;
            }
        }
        this.effectivePrice()
    }

    clearFilter(_e?:any){
        this.discountVal = '';
        this.redeemType = '';
        this.getFilterProducts();
        this.products = this.responseProducts;
        this.effectivePrice()
    }
    effectivePrice(){
        this.products.forEach(e=>{ 
            let discountValue = (e.price  * e.ProductDiscountPercentage)/100;
            e.effectivePrice = e.price - discountValue - e.RewardPoints;
        });
    }
    addCart(product?:any,index?:number){
        this.addcountList = true;
    this.sendGift= false;   
                
        if(this.selectedProductIndex !== index){
            this.shoppingCartItemID = undefined
        }
        // if(!this.addcartCount){
        //   this.shoppingCartItemID = undefined;
        //   }
       
        this.selectedProductIndex = index;
        if(!this.sendGift && (this.selectedProductIndex == index )){
            this.scrolltoView(index)
        }

        // this.selectedProductIndex = index;
        // if(this.selectedProductIndex == index ){
        //     this.intexHide = true;
        // }
    if(product){
        this.productId = product.MappingId;
        this.denomination = Number(product.price);
    }
    
    this.voucherAmount = this.denomination;
    // this.vouherQuantity = this.quantityList[index]+1;
  
   this.effectivePriceAmount = Number(this.voucherAmount) * Number(this.vouherQuantity);
   if(product.ProductDiscountPercentage){
       this.discountValue = (this.effectivePriceAmount  * product.ProductDiscountPercentage)/100;  
   }
   this.voucherFinalPrice = this.effectivePriceAmount - this.discountValue;
        let date = new Date()        
        if(!this.shoppingCartItemID ){
            this.cartParam = {
                "UserID": this.userID,
                "CustomerID": this.customerID,
                "CurrencyID": '20',
                "InitialDeliveryCountry": "IN",
                "CampaignId": this.campaignId,
                "VoucherMRP":  Number(this.voucherFinalPrice),
                "Item": {
                    "UserID": this.userID,
                    "ProductID": this.productId,
                    "DeliveryDate": date,
                    "OrderedQuantity": this.vouherQuantity,
                    "isExpressOrder": false,
                    "isSampleWithoutLogo": false,
                    "isSampleWithLogo": false,
                    "isProceedWithoutLogo": false,
                    "isEmailLogoLater": false,
                    "isChecked": true,
                    "isInitialStepCompleted": true,
                    "isColorStepCompleted": false,
                    "isSizesStepCompleted": false,
                    "isAdditionalCostsStepCompleted": false,
                    "isProductPropertiesStepCompleted": false,
                    "isLogosStepCompleted": false,
                    "VoucherMRP":  Number(this.voucherFinalPrice),
                    "isVisible": true,
                    "ReceiverMobile": Number(this.recieverMobile),
                    "ReceiverName": this.recieverName,
                    "ReceiverEmail": this.recieverEmail,
                    "CustomMessage": this.comment,
                    "CustomImageName": this.giftImg,
                    "TemplateID": this.sendGift ? this.selected: ''
                }
            }
        }else{
            this.cartParam.Item.OrderedQuantity = this.vouherQuantity;
            this.cartParam.Item.VoucherMRP = Number(this.voucherFinalPrice);
            this.cartParam.Item.ShoppingCartItemID = this.shoppingCartItemID;
            this.cartParam.Item.Price = this.cartParamPrice;
            this.cartParam.Item.Price.ProductPrice = Number(this.discountValue);
            this.cartParam.Item.Price.OrderedQuantity = this.vouherQuantity;
            this.cartParam.GrandTotal = Number(this.finalPrice);
            this.cartParam.TotalWithoutVAT = Number(this.finalPrice);
            this.cartParam.Item.ReceiverMobile = Number(this.recieverMobile),
            this.cartParam.Item.ReceiverName = this.recieverName,
            this.cartParam.Item.ReceiverEmail = this.recieverEmail,
            this.cartParam.Item.CustomMessage = this.comment,
            this.cartParam.Item.CustomImageName = this.giftImg,
            this.cartParam.Item.TemplateID =  this.sendGift ? this.selected: ''
            delete this.cartParam.Items;
        }
        if(this.logIndata){
            
        this.loaderShow = true;
        this._httpWebshop.addShoppingCart(this.cartParam).subscribe((data:any)=>{
            this.loaderShow = false;
            if(data){
                this.cartItem = true;
                this.cartParamPrice = data.Items[0].Price
                //this.shoppingCartItemID = data.Item.ShoppingCartItemID;
                if(this.sendGift && (this.recieverName || this.recieverMobile || this.recieverEmailVaild || this.recieverEmail || this.comment)){
                    this.sendGift = false;
                    this.recieverName = "";
                    this.recieverMobile = "";
                    this.recieverEmail = "";
                    this.comment = "";
                }
                if(!this.shoppingCartItemID){
                    const shoppingCartCount = Number(sessionStorage.getItem("shoppingCartCount")) + 1;
                    sessionStorage.setItem("shoppingCartCount", shoppingCartCount.toString());
                    this._httpWebshop.setCount(sessionStorage.getItem("shoppingCartCount"));
                  
                    this.toastr.success('Voucher added to cart!');
                }else{
                    this.toastr.success('Voucher updated!');
                }
                this.getShoppingCart();
                //this.router.navigate(['/checkout'])
            }
        }, _error=>{
            this.loaderShow = false;
        })
        }else{
            sessionStorage.setItem('vocuherCartsItems', JSON.stringify(this.cartParam))
        }
        }
        getShoppingCart(){
            let param = {
                "UserID": this.userID,
                "CustomerID": this.customerID,
                "CurrencyID":20,
                "InitialDeliveryCountry":"IN",
                "ReturnOnlyVisibleItems":true,
                "IsVoucher":true
            }
            this.loaderShow = true;
            this._httpWebshop.getShoppingCart(param).subscribe((data:any)=>{
                this.loaderShow = false;
                this.cartParam = data;
        
                
                let l = data.Items.length;
                this.cartParam.Item = data.Items[l-1];
                
                this.shoppingCartItemID = this.cartParam.Item.ShoppingCartItemID;
                if(this.shoppingCartItemID && this.sendGift){
                    let sendParams = {
                        "ReceiverName": this.recieverName,
                        "ReceiverEmail": this.recieverEmail,
                        "Message": this.comment,
                        "mobile": Number(this.recieverMobile),
                        "ImageURL": this.imageURL,
                        "UserID": this.userID
                    }
                    this.httpVoucher.insertGiftingDetails(sendParams).subscribe(_data=>{
    
                    });
                }
            }, _error=>{
                this.loaderShow = false;
            });
        }
        scrolltoView(id){
            setTimeout(() => {
                // const targetElement = document.querySelector(`#target${id}`);
                // if (targetElement) {
                //   targetElement.scrollIntoView({ behavior: "smooth" });
                // }
                const panelContent = document.querySelector(`#target${id}`) as HTMLDivElement;
                    const panelContentPosition = panelContent.getBoundingClientRect();
                    window.scrollTo({
                    top: window.scrollY + panelContentPosition.top - 150,
                    behavior: 'smooth'
                    });
              }, 1500);
        }

        addqtyCount(){
            this.addcartCount = true;
          } 

        sendasgift(event: any,i:number){
              
               if (event.target.checked) {  
                this.selectedItems = [i];  
                // if (!this.selectedItems.includes(i)) {
                //   this.selectedItems.push(i);
                             
                // }
              } else {
              
                this.selectedItems = this.selectedItems.filter(index => index !== i);
              }
              this.selectedProductIndexgift = i;
              if( (this.selectedProductIndexgift == i) && i != 0){
                this.scrolltoView(i)
               }
             
             if(this.selectedProductIndexgift == i){
              this.sendGift = event.target.checked;
             }
             if(i === this.selectedProductIndexgift ){           
              event.target.checked = !event.target.checked;     
            
                  }
              
            this.recieverName = "";
            this.recieverMobile = "";
            this.recieverEmail = "";
            this.comment = "";
            this.imageURL="";  
            this.giftImg = "";          
          
        }
        checkBalance(qty, price, i) {
            this.disableCheckout = true;
            //qty = qty.replace(/\b0+/g, "");
            if (!this.isParentCustomer) {
              if (!this.isVoucherKart && qty > 50) {
                this.indexData.Name = this.itemsList[i].ProductName;
                this.indexData.ProductManualId = this.itemsList[i].ProductIdManual;
                this.indexData.Quantity = qty;
                this.indexData.ImageURL = this.itemsList[i].ProductImageURL;
         
                qty = 0;
              }
        
            } else {
              if (qty > 500) {
                this.indexData.Name = this.itemsList[i].ProductName;
                this.indexData.ProductManualId = this.itemsList[i].ProductIdManual;
                this.indexData.Quantity = qty;
                this.indexData.ImageURL = this.itemsList[i].ProductImageURL;
                qty = 0;
                return false;
              }
             
            }
            if (parseInt(qty) === 0 || !qty) {
              if (this.itemsList[i].isCheckboxChecked) {
                this.totalPrice = this.totalPrice - this.itemsList[i].Price.TotalProductPrice;
              }else{
                //this.disableCheckout = true;
              }
              this.itemsList[i].Price.OrderedQuantity = 0;
              this.itemsList[i].Price.TotalProductPrice = 0;
              this.itemsList[i].disableUpdate = true;
              return false;
            } else {
        
              this.itemsList[i].disableUpdate = false;
              let item = this.itemsList[i];
              let productPrintPrices = price;
              for (let x = 0; x < item.ProductPrices.length; x++) {
                if (
                  qty >= item.ProductPrices[x].MinimumQuantity && item.ProductPrices[x].MaximumQuantity >= qty
                ) {
                  productPrintPrices = Math.round(item.ProductPrices[x].Price);
                }
              }
              
              if(!this.isVoucherKart){
                this.itemsList[i].Price.ProductPrice = productPrintPrices;
              }else{
                productPrintPrices = this.itemsList[i].Price.ProductPrice;
              }
             
              this.itemsList[i].Price.TotalProductPrice = parseInt(productPrintPrices) * qty;
        
              let allTempCost = 0;
              for (let a = 0; a < this.itemsList.length; a++) {
                if (this.itemsList[a].isCheckboxChecked) {
                  allTempCost = allTempCost + this.itemsList[a].Price.TotalProductPrice;
                }
              }
              this.totalPrice = allTempCost;
            }
            if (!this.isParentCustomer && !this.isVoucherKart) {
              /*if (this.balancePoints - this.totalPrice < 0){
                if (this.toastRef) {
                  this.toastr.clear(this.toastRef.ToastId);
                }
                this.avlPoints = 0;
                this.toastRef = this.toastr.error("You don't have enough points to continue");
                this.itemsList[i].disableUpdate = true;
              }else{
                if (this.toastRef) {
                  this.toastr.clear(this.toastRef.ToastId);
                }
              }*/
            }
          }  

        decQty(i){
            if(this.quantityList[i] > 0) {
              this.quantityList[i]-- ;
            }
            this.decqty = true;
              
              if(this.itemsList[i].Price.OrderedQuantity > 0){
                  this.itemsList[i].Price.OrderedQuantity = this.itemsList[i].Price.OrderedQuantity - 1;
                  this.checkBalance(this.itemsList[i].Price.OrderedQuantity, this.itemsList[i].Price.ProductPrice, i)
              }else{
                  this.itemsList[i].Price.OrderedQuantity = 0;
              }
            
            
            if( this.quantityList[i] >= 0){
              this.toastr.success('Voucher updated!');
            }
            else{
              return false;
            }
            }

            incQty(i:number){
    
                //   let inCount = Array.from(Array(this.itemsList.length).keys());
                 this.selectedProductIndexview = i;    
                   if( this.selectedProductIndexview === i && this.logIndata){
                     this.quantityList[i]++; 
                   }
                  if(this.isParentCustomer && this.itemsList[i].Price.OrderedQuantity < 501){
                      this.itemsList[i].Price.OrderedQuantity = this.itemsList[i].Price.OrderedQuantity + 1;
                      this.checkBalance(this.itemsList[i].Price.OrderedQuantity, this.itemsList[i].Price.ProductPrice, i)
                  }
                  if(!this.isParentCustomer && this.itemsList[i].Price.OrderedQuantity < 51){
                      this.itemsList[i].Price.OrderedQuantity = this.itemsList[i].Price.OrderedQuantity + 1;
                      this.checkBalance(this.itemsList[i].Price.OrderedQuantity, this.itemsList[i].Price.ProductPrice, i)
                  }
          
                }
    
        setUploadedImageData(ev) {
            try {
              this.imageURL = ev[2];
              this.giftImg = ev[2];
            } catch (error) {
              this.toastr.error(error);
            }
          }
          getThemes(){
            this.httpVoucher.getGiftingTheme().subscribe((res:any)=>{
                let data = JSON.parse(res);
                this.themeList = (data.result);
                this.selectedTheme = this.themeList[0]; 
                this.selected = this.themeList[0].id;
                this.imgSelect(this.selectedTheme.header_image);            
            })
        }
    
        themeSelect(i){
            this.selectedTheme = this.themeList[i];
            this.selected = this.selectedTheme.id;
            this.imgSelect(this.selectedTheme.header_image);
        }
        imgSelect(img){
            this.giftImg = img;
        }
        nameInput(_e){
            this.giftRecieverName = this.recieverName;
        }
    
        emailCheck(){
            if (this.recieverEmail && this.recieverEmail.match(/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/)) {
                this.recieverEmailVaild = true;
            } else {
                this.recieverEmailVaild = false;
            }
        }
    
        emailVal(){
            if(!this.recieverEmailVaild){
                this.toastr.error('Please input valid email id')
            }
        }
    
        onlyNumberKey(event) {
            this.denominationError = '';
            const pattern = new RegExp("^[0-9\b]+$");
            const key = String.fromCharCode(
              !event.charCode ? event.which : event.charCode
            );
            if (!pattern.test(key) && event.charCode !== 0) {
              event.preventDefault();
              return false;
            }
        }
    
        commentInput(_e){
            this.giftMessage = this.comment;
        }
        
}