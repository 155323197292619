import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})

export class LandingPageComponent implements OnInit{
    @Output() setup = new EventEmitter<any>();
    constructor(private router: Router){
        let project = this.getParameterByName('project', window.location.href);
        setTimeout(()=>{
            if(project !== "91sb"){
                this.router.navigate(['/']);
                this.setup.emit('signup');
            }
        }, 100)
        
    }
    ngOnInit(){
        
    }
    
    signup(){
        let params = {};
        params['IsSb91'] = 'Y';
        this.router.navigate(['/'], { queryParams: params, replaceUrl: false });
        this.setup.emit('signup')
    }

    importuser(){
        window.location.href = 'https://91sb.rewardkartadmin.in';
    }

    enqyiryForm(){
        this.router.navigate(['/enquiryform']);
        this.setup.emit('enquiryform')
    }

    getParameterByName(name, url) {
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&|]" + name + "(=([^&#|]*)|&|#|$)", 'i'),
        results = regex.exec(url);
        if (!results)
          return null;
        if (!results[2])
          return '';
        return results[2].replace(/\+/g, " ");
      }
}