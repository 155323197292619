import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { GlobalService } from "../../globals/global.service";
import { BehaviorSubject, Observable } from "rxjs/Rx";
import "rxjs/Rx";

@Injectable()
export class VoucherService{
    selectedProductIndex: number = -1;
    constructor(private http: HttpClient) {
    }

    getTopVoucherCategories() {
        return this.http.get(environment.ENVIRONMENT.baseURL + "en/Categories/GetTOPVoucherCategoryList", { responseType: "json" });
    }

    getVoucherCategories() {
        return this.http.get(environment.ENVIRONMENT.baseURL + "en/Categories/GetVoucherCategoryList", { responseType: "json" });
    }

    getVoucherBrandsSearch() {
        return this.http.get(environment.ENVIRONMENT.baseURL + "en/Categories/GetSearchBrandList", { responseType: "json" });
    }

    getVoucherBrands() {
        return this.http.get(environment.ENVIRONMENT.baseURL + "en/Categories/GetVoucherBrandList", { responseType: "json" });
    }

    getAllProducts() {
        return this.http.get(environment.ENVIRONMENT.baseURL + "en/Categories/GetVoucherProductList", { responseType: "json" });
    }

    getOMGBrands(i) {
        const countryID = JSON.parse(sessionStorage.getItem('dropCountryID')) || "";
        const currencyID = JSON.parse(sessionStorage.getItem('countryID')) || "";
        let url = `${environment.ENVIRONMENT.baseURL}en/Categories/GetOMGBrand?Source=${i}`;
    
        if (countryID) {
            url += `&NewCountryID=${countryID}`;
        }
        if (currencyID && currencyID !== "ALL") {
            url += `&NewCurrencyID=${currencyID}`;
        }
    
        return this.http.get(url, { responseType: "json" });
    }
    

    getProducts(type, id) {
        const countryID = JSON.parse(sessionStorage.getItem('dropCountryID')) || "";
        const currencyID = JSON.parse(sessionStorage.getItem('countryID')) || "";
        const baseURL = environment.ENVIRONMENT.baseURL;
        let url = "";
    
        if (type === 'brands') {
            url = `${baseURL}en/Categories/GetBrandProductMappingList?BrandId=${id}`;
        } else if (type === 'brandname') {
            url = `${baseURL}en/Categories/GetSearchBrandName?BrandName=${id}`;
        } else {
            url = `${baseURL}en/Categories/GetCategoryProductMappingList?CategoryId=${id}`;
        }
    
        if (countryID) {
            url += `&NewCountryID=${countryID}`;
        }
        if (currencyID && currencyID !== "ALL") {
            url += `&NewCurrencyID=${currencyID}`;
        }
    
        return this.http.get(url, { responseType: "json" });
    }
    

    insertGiftingDetails(params){
        return this.http.post(environment.ENVIRONMENT.baseURL + "en/Categories/InsertGiftingDetails", params, { responseType: "json" });
    }

    getGiftingTheme(){
        return this.http.get(environment.ENVIRONMENT.baseURL + "en/Categories/GetGiftingDetails", { responseType: "json" });
    }
    
}