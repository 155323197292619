import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WebshopRoutingModule } from './webshop-routing.module';
import { ShopCommonModule } from '../linknet-common/linknet-common.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AboutComponent } from './about/about-component';
import { FaqComponent } from './faq/faq-component';
import { LogoComplimentComponent } from './banner-forms/logo-compliment/logo-compliment.component';
import { HeaderOneComponent } from './header-one/header-one.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { ProductCardComponent } from './product-list/product-card/product-card.component';
import { ProductListComponent } from './product-list/product-list.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderTwoComponent } from './header-two/header-two.component';
import { HeaderMobileComponent } from './header-mobile/header-mobile.component';
import { MenuComponent } from './header-one/menu/menu.component';
import { FilterMobileComponent } from './header-mobile/mobile-filter/mobile-filter.component';
import { CreditEnquiryComponent } from './header-one/credit-enquiry/credit-enquiry.component';
import { MenuRightComponent } from './header-one/menu-right/menu-right.component';
import { MenuLeftComponent } from './header-one/menu-left/menu-left.component';
import { PriceRangeComponent } from './price-range/price-range.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ProductBlocksComponent } from './home-page/product-blocks/product-blocks.component';
import { MDBBootstrapModule } from '../typescripts/free';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbAccordionConfig, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule } from '@angular/router';
import { ValidationService } from '../linknet-common/validators/index';
import { MDBBootstrapModulePro } from '../typescripts/pro';
import { LoginComponent } from 'app/linknet-common/login/login.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ImageBlockComponent } from './product-detail/image-block/image-block.component';
import { ProductLikesComponent } from './product-likes/product-likes.component';
import { ProductFoldersComponent } from './product-folders/product-folders.component';
import { ProductLikeModalComponent } from './product-likes/product-like-modal/product-like-modal.component';
import { CreateFolderComponent } from './product-folders/create-folder/create-folder.component';
import { ListFoldersComponent } from './product-folders/list-folders/list-folders.component';
import { ProductInputComponent } from './product-detail/product-input/product-input.component';
import { NewOrderComponent } from './product-detail/new-order/new-order.component';
import { JoinOrderComponent } from './product-detail/join-order/join-order.component';
import { PrintPriceComponent } from './product-detail/print-price/print-price.component';
import { ProductInfoComponent } from './product-detail/product-info/product-info.component';
import { ProductColorsComponent } from './product-detail/product-colors/product-colors.component';
import { ProductCostComponent } from './product-detail/product-cost/product-cost.component';
import { LogoBankComponent } from './logo-bank/logo-bank.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { OrderProductionComponent } from './order-production/order-production.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { MomentModule } from 'angular2-moment';
import { AuthGuardService } from 'app/linknet-common/auth-guard.service';
import { SettingsComponent } from './settings/settings.component';
import { EditUserComponent } from './settings/edit-user/edit-user.component';
import { AppComponent } from 'app/app.component';
import { MessageDataService } from 'app/linknet-common/globals/global.service';
import { ChangeUserPasswordComponent } from './settings/change-user-password/change-user-password.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NG_SELECT_DEFAULT_CONFIG } from '@ng-select/ng-select';
import { FiltersComponent } from './product-list/filters/filters.component';
import { ProductPdfComponent } from './product-pdf/product-pdf.component';
import { ProductSizeComponent } from './product-detail/product-size/product-size.component';
import { NewsLetterComponent } from './product-detail/news-letter/news-letter.component';
import { OrderSampleComponent } from './order-sample/order-sample.component';
import { ProductInquiryComponent } from './product-detail/product-inquiry/product-inquiry.component';
import { ProductPropertiesComponent } from './product-detail/product-properties/product-properties.component';
import { ProductBatchColorsComponent } from './product-detail/product-batch-colors/product-batch-colors.component';
import { NgPipesModule } from 'ngx-pipes';
import { ShoppingCartComponent } from './header-one/shopping-cart/shopping-cart.component';
import { ShoppingCartMobileComponent } from './header-mobile/shoppingcart-mobile/shoppingcart-mobile.component';
import { DatePipe } from '@angular/common';
import { CheckoutComponent } from './checkout/checkout.component';
import { OrderClaimsComponent } from './order-claims/order-claims.component';
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { StockModalComponent } from './product-detail/new-order/stock-modal/stock-modal.component';
import { SetTabindexDirective } from './set-tabindex.directive';
import { IonRangeSliderModule } from 'ng2-ion-range-slider';
import { OrderStatusComponent } from './order-status/order-status.component';
import { OrderUnauthorisedComponent } from './order-unauthorised/order-unauthorised.component';
import { PointsHistoryComponent } from './points-history/points-history.component'
import { UserBudgetComponent } from './settings/user-budget/user-budget.component';
import { MoreAddressComponent } from './settings/more-address/more-address.component';
// import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AccordionModule } from "ngx-accordion";

import { AngularcliStarRatingModule } from 'angularcli-star-rating';
import { TooltipModule } from "ngx-tooltip";
import { ProductDetailInputModifiedComponent } from './product-detail/product-detail-input-modified/product-detail-input-modified.component';
import { NewOrderModifiedComponent } from './product-detail/new-order-modified/new-order-modified.component';
import { JoinOrderModifiedComponent } from './product-detail/join-order-modified/join-order-modified.component';

import { JoinOrderModifiedNewComponent } from './product-detail/join-order-modified-new/join-order-modified-new.component';
import { NewOrderModifiedNewComponent } from './product-detail/new-order-modified-new/new-order-modified-new.component';
import { StockModalModifiedComponent } from './product-detail/new-order-modified/stock-modal-modified/stock-modal-modified.component';

import { StockModalModifiedNewComponent } from './product-detail/new-order-modified-new/stock-modal-modified-new/stock-modal-modified-new.component';
import { ShowProductPriceComponent } from './product-detail/show-product-price/show-product-price.component';
import { ShowBrandingPriceComponent } from './product-detail/show-branding-price/show-branding-price.component';
import { MarketingProductsComponent } from './product-list/marketing-products/marketing-products.component';
import { MarketingproductDetailComponent } from './marketingproduct-detail/marketingproduct-detail.component';
import { MarketingimageBlockComponent } from './marketingproduct-detail/marketingimage-block/marketingimage-block.component';
import { EmployeeRRComponent } from './banner-forms/employee-rr/employee-rr.component';
import { LoyaltyPrograms } from './banner-forms/loyalty-programs/loyalty-programs.component';
import { EnquireNowComponent } from './banner-forms/enquire-now/enquire-now.component';
import { WalletTopUpComponent } from './wallet-topup/wallet-topup.component';
import {LoginMessageComponent} from './vouchers/login-message/login-message.component';
import {VouchersComponent} from './vouchers/vouchers.component';
import {VoucherBillboardComponent} from './vouchers/voucher-billboard/voucher-billboard.component'
import {HeaderVoucherComponent} from './vouchers/header-voucher/header-voucher.component'
import {VoucherProductsComponent} from './vouchers/voucher-products/voucher-products.component';
import {CreateVoucherComponent} from './vouchers/create-voucher/create-voucher.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { VoucherCheckoutComponent } from './vouchers/checkout-voucher/checkout-voucher.component';
import {VoucherLoginComponent} from './vouchers/voucher-login/voucher-login.component'
import { MultiSearchComponent } from './multiple-search/multiple-search.component';
import { VoucherFilterComponent } from './vouchers/voucher-filter/voucher-filter.component';
import { SmartWorksComponent } from './smartworks/smartworks.component';
import { ImportedUserComponent } from './smartworks/user-list/user-list.component';
import { TrackOrderComponent } from './track-order/track-order.component';
// import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { DigitOnlyDirective } from './digit-only.directive';

import { CurrencyMenuComponent } from './header-one/currency-menu/currency-menu.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    MomentModule,
    WebshopRoutingModule,
    ShopCommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule.forRoot(),
    NgbAccordionModule,
    HttpClientModule,
    MDBBootstrapModule.forRoot(),
    NgSelectModule,
    MDBBootstrapModulePro.forRoot(),
    NgPipesModule,
    NgxImageGalleryModule,
    IonRangeSliderModule,
    CarouselModule,
    TooltipModule,
    // Ng2SmartTableModule,
    // RecaptchaModule,
    // RecaptchaFormsModule,
    AngularcliStarRatingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot([{ path: '', component: HeaderOneComponent, pathMatch: 'full' }]),
    AccordionModule
  ],
  declarations: [
    OrderSampleComponent,
    HeaderOneComponent,
    AboutComponent,
    CategoryListComponent,
    CreditEnquiryComponent,
    FaqComponent,
    LogoComplimentComponent,
    LoyaltyPrograms,
    EmployeeRRComponent,
    EnquireNowComponent,
    ProductCardComponent,
    ProductListComponent,
    FooterComponent,
    HeaderTwoComponent,
    HeaderMobileComponent,
    MenuComponent,
    FilterMobileComponent,
    MenuRightComponent,
    PriceRangeComponent,
    HomePageComponent,
    ProductBlocksComponent,
    MenuLeftComponent,
    ProductDetailComponent,
    ImageBlockComponent,
    ProductLikesComponent,
    ProductFoldersComponent,
    ProductLikeModalComponent,
    CreateFolderComponent,
    ListFoldersComponent,
    ProductLikeModalComponent,
    ProductInputComponent,
    NewOrderComponent,
    JoinOrderComponent,
    PrintPriceComponent,
    ProductInfoComponent,
    ProductColorsComponent,
    ProductCostComponent,
    LogoBankComponent,
    OrderHistoryComponent,
    OrderProductionComponent,
    OrderDetailComponent,
    TrackOrderComponent,
    SettingsComponent,
    EditUserComponent,
    ChangeUserPasswordComponent,
    FiltersComponent,
    ProductPdfComponent,
    ProductSizeComponent,
    NewsLetterComponent,
    ProductInquiryComponent,
    ProductPropertiesComponent,
    ProductBatchColorsComponent,
    ShoppingCartComponent,
    ShoppingCartMobileComponent,
    CheckoutComponent,
    OrderClaimsComponent,
    StockModalComponent,
    SetTabindexDirective,
    OrderStatusComponent,
    OrderUnauthorisedComponent,
    PointsHistoryComponent,
    UserBudgetComponent,
    MoreAddressComponent,
    ProductDetailInputModifiedComponent,
    NewOrderModifiedComponent,
    JoinOrderModifiedComponent,
    StockModalModifiedComponent,
    StockModalModifiedNewComponent,
    JoinOrderModifiedNewComponent,
    NewOrderModifiedNewComponent,
    ShowProductPriceComponent,
    ShowBrandingPriceComponent,
    MarketingProductsComponent,
    MarketingproductDetailComponent,
    MarketingimageBlockComponent,
    LoginMessageComponent,
    VouchersComponent,
    VoucherBillboardComponent,
	  HeaderVoucherComponent,
    VoucherProductsComponent,
    VoucherFilterComponent,
    SmartWorksComponent,
    ImportedUserComponent,
    CreateVoucherComponent,
    VoucherLoginComponent,
    VoucherCheckoutComponent,
    WalletTopUpComponent,
    MultiSearchComponent,
    DigitOnlyDirective,
    CurrencyMenuComponent
  ],
  exports: [
    OrderSampleComponent,
    HeaderOneComponent,
    CategoryListComponent,
    CreditEnquiryComponent,
    ProductCardComponent,
    ProductListComponent,
    FooterComponent,
    HeaderTwoComponent,
    HeaderMobileComponent,
    MenuComponent,
    FilterMobileComponent,
    MenuRightComponent,
    MenuLeftComponent,
    PriceRangeComponent,
    HomePageComponent,
    ProductBlocksComponent,
    ProductLikeModalComponent,
    CreateFolderComponent,
    ListFoldersComponent,
    FiltersComponent,
    ShopCommonModule,
    SetTabindexDirective,
    UserBudgetComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [ValidationService, LoginComponent, ProductLikeModalComponent, AuthGuardService, NgbAccordionConfig,
    HeaderOneComponent, AppComponent, SettingsComponent, MessageDataService, MenuRightComponent,
    DatePipe, {
      provide: NG_SELECT_DEFAULT_CONFIG,
      useValue: {
        notFoundText: 'Not found.'
      }
    }
  ]
})

export class WebshopModule { }
