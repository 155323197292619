import { Component, OnInit, Input, ViewChild, HostListener, SimpleChanges } from '@angular/core';
import { GlobalService, CurrencySetterService } from '../../../linknet-common/globals/global.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../../../typescripts/pro/alerts';
import { ProductService } from 'app/linknet-common/restful-services/restful.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'shop-app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})

export class ProductCardComponent implements OnInit {
  public showDetail: Boolean = false;
  @Input() productDetail: any;
  @Input() hideDelete: boolean;
  @Input() isProductBlock: boolean;
  @Input() showOrderDetail: any = true;
  @Input() showProgress: any = false;
  @Input() priceStatus: any = true;
  @Input() loopValue: any;
  @ViewChild('DeleteModal') public deleteModal;

  public currency: any;
  public userSettings: any;
  public folderID: any;
  public likedProducts: any;
  public translatedMessage: any;
  public today: Date;
  public queryParams: any;
  public priceLineDisplayStatus: boolean = true;
  public colorID: any;
  public showStatus: boolean = false;
  public isvoucher:boolean = false;
  public getPopupHeight: any;
  public webShopSettings: any;
  public productData: any = '';
  public changeDeliveryDateText: boolean = false;  // for display text as delivery date / Earliest disp. date in HTML Page
  public wsaStatus: boolean = false;
  public isParentCustomer: boolean = false;
  webshopData: any;
  public isSocialMediaSharingEnabled: boolean = false; // for display Social Icons in product Card
  logIndata: any;
  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public currencySetterService: CurrencySetterService,
    private globalService: GlobalService, private toastService: ToastService,
    public _httpProductService: ProductService, private translate: TranslateService,
    private route: ActivatedRoute, private toastr: ToastrService) {
    try {
      this.webShopSettings = this.globalService.webShopSettings();
      this.today = new Date();
      this.currency = currencySetterService.currency;
      this.userSettings = this.globalService.getUserSettings();
      this.folderID = this.activatedRoute.snapshot.queryParams ? this.activatedRoute.snapshot.queryParams.FolderID : null;
      this.likedProducts = this.activatedRoute.snapshot.queryParams ? this.activatedRoute.snapshot.queryParams.likedProducts : null;
      if ((this.folderID) || (this.likedProducts)) {
        this.hideDelete = false;
      }
      if (this.folderID) {
        this.likedProducts = true;
      }
      this.showProgress = this.activatedRoute.snapshot.queryParams ? this.activatedRoute.snapshot.queryParams.joinlinkorders : false;
    } catch (error) {
      this.toastr.error(error);
    }
    const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    this.webshopData = webshopDetail;
    this.logIndata = this.globalService.getLoggedInData();
    // console.log("zzzz",this.logIndata.DomainName);
    if(this.webshopData.DomainName === "voucherkart"){
      this.isvoucher = true;
    }
    if(this.webshopData.ParentCustomerDomainName === this.webshopData.DomainName){
      this.isParentCustomer = true;
    }else{
      this.isParentCustomer = false;
    }
  }


  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const keyPressed = event.keyCode;
    if (keyPressed === 27) {
      this.showDetail = false;
    }
  }

  ngOnInit() {
   
    try {
      const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      this.webshopData = webshopDetail;

      if(this.webshopData.ParentCustomerDomainName === this.webshopData.DomainName){
        this.isParentCustomer = true;
      }else{
        this.isParentCustomer = false;
      }
      if (webshopDetail.ChangeDeliveryDateText) {
        this.changeDeliveryDateText = true;
      }
      if (Number(webshopDetail.CustomerID) === 45) {
        this.wsaStatus = true;
      }
      let socialMediasDisplayStatus = false;
      if(webshopDetail.SocialMedias.length > 0){
        const socialIcons = webshopDetail.SocialMedias.filter(ppp => (ppp.ShortCode !== 'IN' && ppp.ShortCode !== 'GP'));
         if (socialIcons.length > 0) {
          socialMediasDisplayStatus = true;
         }
      }
      /** new-code => for display Social Icons in product Card */
      if (webshopDetail.isSocialMediaSharingEnabled && socialMediasDisplayStatus) {
        this.isSocialMediaSharingEnabled = true;
      }
      /** new-code => for display Social Icons in product Card */
      
      this._httpProductService.currentShowDetail.subscribe(data => this.showDetail = data)
      const quantityQueryParam = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.Quantity : null;
      if (quantityQueryParam !== null || quantityQueryParam !== '') {
        this.priceLineDisplayStatus = false;
      }
      this.queryParams = this.router.routerState.snapshot.root.queryParams;
      if (this.queryParams.ColorID !== undefined || this.queryParams.ColorID !== '') {
        this.colorID = this.queryParams.ColorID;
      }

      if (this.queryParams.Quantity === undefined || this.queryParams.Quantity === '') {
        this.priceStatus = false;
      }
      //  console.log("point",this.productDetail)
      if (this.productDetail.OrderedQuantity && (this.productDetail.OrderedQuantityPlusMOQ === null || 
        this.productDetail.OrderedQuantityPlusMOQ === undefined)) {
        for (let _i = 0; _i < this.productDetail.ProductPrices.length; _i++) {
          if (this.productDetail.ProductPrices[_i].MinimumQuantity <=
            this.productDetail.OrderedQuantity && this.productDetail.ProductPrices[_i].MaximumQuantity >=
            this.productDetail.OrderedQuantity) {
            this.productDetail.DropMinQuantity = this.productDetail.ProductPrices[_i].MinimumQuantity;
            this.productDetail.ProductPrices[_i].activePrice = true;
          } else if (this.productDetail.ProductPrices[_i].MaximumQuantity < this.productDetail.OrderedQuantity) {
            delete this.productDetail.ProductPrices[_i];
          }
        }
      } else if (this.productDetail.OrderedQuantityPlusMOQ !== null && 
        this.productDetail.OrderedQuantityPlusMOQ !== undefined) {
        for (let _i = 0; _i < this.productDetail.ProductPrices.length; _i++) {
          if (this.productDetail.ProductPrices[_i].MinimumQuantity <=
            this.productDetail.OrderedQuantityPlusMOQ && this.productDetail.ProductPrices[_i].MaximumQuantity >=
            this.productDetail.OrderedQuantityPlusMOQ) {
            this.productDetail.DropMinQuantity = this.productDetail.ProductPrices[_i].MinimumQuantity;
            this.productDetail.ProductPrices[_i].activePrice = true;
          } else if (this.productDetail.ProductPrices[_i].MaximumQuantity < this.productDetail.OrderedQuantityPlusMOQ) {
            delete this.productDetail.ProductPrices[_i];
          }
        }
      } else {
        if (this.productDetail.ProductPrices[0]) {
          this.productDetail.ProductPrices[0].activePrice = true;
        }
      }
      this.productDetail.ProductPrices = this.productDetail.ProductPrices.filter(function (val) { return val; });
    } catch (error) {
      this.toastr.error(error);
    }
    this.getScreenheight();
    this.effectivePrice();
  }

  productDetailData($event) {
    this.productData = $event;
  }
  showProductDetail(productDetail,productID, productName) {
    let item = productDetail
    try {
      this.productData = '';
      //  this.router.navigate(['/productdetail', productID, productName], { replaceUrl: false });

      this.showDetail = true;
      if(this.webshopData.DomainName === "voucherkart"){
           this.router.navigate(['/create-voucher'], {state: {item}, replaceUrl: false});
      }
      else{
        this.router.navigate(['/productdetail', productID, productName], { replaceUrl: false });
      }
      
      // window.sessionStorage.height = window.scrollY;
    } catch (error) {
      this.toastr.error(error);
    }
  }

  effectivePrice(){
    //  this.productDetail.(e=>{ 
        // let effectivePrice = 0;
        let discountValue = (this.productDetail.price  * this.productDetail.b2c_discount)/100;
       let effectivePrice = this.productDetail.price - discountValue - this.productDetail.reward_points;
         this.productDetail.effectivePrice= effectivePrice;
    // });
}


inrFormat(val: number) {
  return Number(val).toLocaleString("en-IN");
}

  closeProductDetail() {
    window.history.replaceState(' ', '', '/');
  }

  deleteCard(param) {
    try {
      if (this.folderID) {
        const postParameters = {
          'FolderID': this.folderID,
          'ProductID': param.ProductID,
          'Action': 'delete'
        };
        this._httpProductService.insertOrDeleteFolderProduct(postParameters).subscribe(
          () => {
            // this.translatedMessage = (this.translate.get('FOLDERS.PRODUCT_REMOVED_FROM_FOLDER'));
            this.translate.get('FOLDERS.PRODUCT_REMOVED_FROM_FOLDER').subscribe((res: string) => {
              this.translatedMessage = res;
            });
            this.toastr.success(this.translatedMessage);
            this.router.navigate([this.router.url.split('?')[0]], { preserveQueryParams: true, replaceUrl: false });
          }
        );
      } else if (this.likedProducts) {
        const postParameters = {
          'ProductLikeID': param.ProductLikeID
        }
        this._httpProductService.deleteLikedProduct(postParameters).subscribe(
          data => {
            if (data === true || data === 'true') {
              //  this.translatedMessage = (this.translate.get('FOLDERS.PRODUCT_REMOVED_FROM_FOLDER_LIKED_PRODUCT'));
              this.translate.get('FOLDERS.PRODUCT_REMOVED_FROM_FOLDER_LIKED_PRODUCT').subscribe((res: string) => {
                this.translatedMessage = res;
              });
              this.toastr.success(this.translatedMessage);
              this.router.navigate([this.router.url.split('?')[0]], { preserveQueryParams: true, replaceUrl: false });
            }
          }
        );
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  deleteProductModel(param) {
    this.showStatus = true;
    this.deleteModal.show(param);
  }

  getScreenheight() {
    const body = document.body;
    const screenHeight = body.offsetHeight;
    this.getPopupHeight = screenHeight - 135;
  }

}
