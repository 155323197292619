import { Component, OnInit } from "@angular/core";
import { CampaignService, VoucherService } from "app/linknet-common/restful-services/restful.service";
import { ToastrService } from "ngx-toastr";
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from "@angular/router";
import { CommonServicesService } from "app/linknet-common/common-services/common-services.service";

@Component({
  selector: 'shop-app-vouhcers',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.scss']
})

export class VouchersComponent implements OnInit {
  public loaderShow: boolean = false;
  brandList: any;
  voucherList: any;
  public myInterval: number = 10000;
  customOptions: OwlOptions = {
    loop: false,
    margin: 10,
    autoplay: true,
    dots: false,
    navSpeed: 700,
    slideBy: 6,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 2,
        slideBy: 2
      },
      480: {
        items: 4,
        slideBy: 4
      },
      940: {
        items: 6,
        slideBy: 5
      }
    },
    nav: true
  }
  redirect: boolean = true;
  campaignList: any;
  balancePoints: any;
  isVoucherKart: boolean = false;
  loggedData: any;
  isMobile: boolean = false;
  DEALSOffer = [
    {
      brandimg: "../../../assets/Best Discount Brand-2-min.jpg",
      count: "1"
    },
    {
      brandimg: "../../../assets/Top Selling Brand-min.jpg",
      count: "2"
    },
    {
      brandimg: "../../../assets/Premium Brand-2-min.jpg",
      count: "3"
    },
    {
      brandimg: "../../../assets/Top Selling Brand-min.jpg",
      count: "2"
    },
    {
      brandimg: "../../../assets/Premium Brand-2-min.jpg",
      count: "3"
    },
  ]
  dealOption: OwlOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 1000,
    dots: false,
    navSpeed: 500,
    slideBy: 1,
    autoWidth: true,
    navText: [
      '<img src="../../../assets/leftarrow.png">',
      '<img src="../../../assets/rightarrow.png">',
    ],
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      940: {
        items: 3,
      },
    },
    nav: true,
  };
  
activeSlideIndex = 0;
  constructor(private commonService: CommonServicesService, public campaignService: CampaignService, private router: Router, private toastr: ToastrService, private httpVoucher: VoucherService) {
    const webshopData = JSON.parse(sessionStorage.getItem('webshopDetail'));
    if (sessionStorage.getItem('loggedInData')) {
      this.loggedData = JSON.parse(sessionStorage.getItem('webshopDetail'))
    }
    if (webshopData && webshopData.DomainName === "voucherkart") {
      this.isVoucherKart = true;
    } else {
      this.router.navigate(['/'])
    }
    if (window.innerWidth > 768) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }
  ngOnInit(): void {
    this.getBrandList();
    if (this.loggedData) {
      this.campaignService.getCampaignForUser().subscribe((data: any) => {
        if (data) {
          this.campaignList = data;
          this.campaignList = this.campaignList.filter((e: any) => { return e.CampaignDesc === "voucherkart" });
          sessionStorage.setItem('campaign', JSON.stringify(this.campaignList[0]));
          if (this.campaignList.length !== 0) {
            this.balancePoints = this.campaignList[0].BalancePoints
            this.commonService.setPoints(this.campaignList[0].BalancePoints);
          }
        }
      })
    }
  }

  omg(i) {
    this.router.navigate(['/vouchers', 'omg', i], { replaceUrl: false })
  }

  getBrandList() {
    this.loaderShow = true;
    this.httpVoucher.getVoucherBrands().subscribe(data => {
      this.loaderShow = false;
      this.brandList = data;
    })
  }

  voucherProducts(id) {
    this.router.navigate(['/vouchers', 'brands', id], { replaceUrl: false })
  }
}