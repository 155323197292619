import { Component, OnInit, Input, ViewChild, SimpleChanges, OnChanges, ElementRef } from '@angular/core';
import { ToastService } from 'app/typescripts/pro/alerts';
import { NgxImageGalleryComponent, GALLERY_IMAGE, GALLERY_CONF } from 'ngx-image-gallery';
import { GlobalService } from '../../../linknet-common/globals/global.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-image-block',
  templateUrl: './image-block.component.html',
  styleUrls: ['./image-block.component.scss'],
})
export class ImageBlockComponent implements OnInit, OnChanges {
  public productImageURL: URL;
  public tempProductImageUrl: URL;
  public imageID: any;
  public galleryList: any = [];
  public openImageIndex: any;
  public productId: any;
  public productBatchID: any;
  public selectImgIndex: any;
  public webShopSettings: any;
  public imageSetForLogoPosition: Boolean = false;
  public selectedProductColorID: any;
  @Input() productDetail: any;
  @Input() productImage: any = [];
  @Input() selectColorId: any;
  @Input() selectLogoPositionID: any;
  @Input() isShoppingCartPage: any;
  @Input() productDetailPrice: any;
  @Input() shoppingCartData: any;
  @Input() mrp: any;
  @Input() productCurrentQty: any = {};
  public productPriceData = [];
  public firstPrintPrice: any;
  Calculatedprice: any;
  public totalProductPrice: any;
  public currency: any;
  public isParentCustomer: boolean = false;
  public companyBranding: boolean = false;
  webshopData: any;
  // get reference to gallery component
  @ViewChild(NgxImageGalleryComponent) ngxImageGallery: NgxImageGalleryComponent;
  @ViewChild('container') 'container': ElementRef;
  @ViewChild('img') 'img': ElementRef;
  public isZoomed: Boolean = false;
  public pos: any = { top: 0, left: 0, x: 0, y: 0 };
  @ViewChild("ProductImageModal") public productImageModal;
  isSmartWorks: boolean = false
  // gallery configuration
  conf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showImageTitle: false,
    showThumbnails: false
  };
  // gallery images
  public images: GALLERY_IMAGE[];
  public isSocialMediaSharingEnabled: Boolean = false; // for display Social Icons in PDP Page
  constructor(private toastService: ToastService, private globalService: GlobalService, private toastr: ToastrService) {

  }

  ngOnInit() {
    //console.log(this.productImage)
    try {
      this.webShopSettings = this.globalService.webShopSettings();
      this.productImageURL = this.productImage.ProductImages ? this.productImage.ProductImages[0].ImageURL : this.productImage.ImageURL;
      this.productId = this.productImage.ProductID;
      this.imageID = this.productImage.ProductImages[0].ProductImageID;
      this.populateGalery();
      /** new-code => for display Social Icons in product Card */
      const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      let socialMediasDisplayStatus = false;
      if (webshopDetail.SocialMedias.length > 0) {
        const socialIcons = webshopDetail.SocialMedias.filter(ppp => (ppp.ShortCode !== 'IN' && ppp.ShortCode !== 'GP'));
        if (socialIcons.length > 0) {
          socialMediasDisplayStatus = true;
        }
      }

      this.webshopData = webshopDetail;
      if (this.webshopData.ParentCustomerDomainName === this.webshopData.DomainName) {
        this.isParentCustomer = true;
      } else {
        this.isParentCustomer = false;
      }

      if(this.webshopData.DomainName === "sw" || this.webshopData.DomainName === "smartworks"){
        this.isSmartWorks = true;
      }

      // price show in image component -04-10-2022
      //console.log("productDetailPrice : ", this.productDetailPrice);
      this.productPriceData = this.productDetailPrice;
      if (this.productPriceData.length > 0) {
        if(this.isParentCustomer){
        this.firstPrintPrice = Math.round(this.productPriceData[0].Price);
        }else{
          this.firstPrintPrice = Math.round(this.productPriceData[0].CampaignPrice);
        }
        var priceIndex = 0;
        if (this.productCurrentQty > 0) {
          this.productPriceData.forEach((prc, index) => {
            if ((this.productCurrentQty >= prc.MinimumQuantity) && (this.productCurrentQty <= prc.MaximumQuantity)) {
              if(this.isParentCustomer){
                this.firstPrintPrice = Math.round(prc.Price);
              }else{
                this.firstPrintPrice = Math.round(prc.CampaignPrice);
              }
              priceIndex = index;
            }
          });
        }

        /** old-code hide */
        if (this.productPriceData[priceIndex].ProductPrintPrices.length > 0 && this.productPriceData[priceIndex].ProductPrintPrices[0].isFixedLogo) {
          this.Calculatedprice = 0;
          this.productPriceData[priceIndex].ProductPrintPrices.forEach(element => {
            if(this.isParentCustomer){
            this.Calculatedprice += element.Price;
            }else{
              this.Calculatedprice += element.CampaignPrice;
            }
          });
          this.firstPrintPrice = Math.round(this.firstPrintPrice) + Math.round(this.Calculatedprice);
        }
        this.currency = this.globalService.getUserSettings().CurrencyCode;
        // if (this.currency !== 'zł') {
        this.firstPrintPrice = (this.firstPrintPrice).toFixed(2);
        // }

        /** new-code */
        this.totalProductPrice = this.firstPrintPrice;
        if (this.isParentCustomer) {
          this.totalProductPrice = Math.round(this.totalProductPrice);
        } else {
          this.totalProductPrice = Math.round(this.totalProductPrice);
        }
        // if (this.currency !== 'zł') {
        //   this.totalProductPrice = Number(this.totalProductPrice).toFixed(2);
        // } else {
        //   this.totalProductPrice = this.totalProductPrice.toLocaleString('pl-PL', { style: 'currency', currency: 'PLN' });
        // }
        /** new-code */
      }


      /** new-code => for display Social Icons in product Card */
      if (webshopDetail.isSocialMediaSharingEnabled && socialMediasDisplayStatus) {
        this.isSocialMediaSharingEnabled = true;
      }

      /** new-code => for display Social Icons in product Card */
    } catch (error) {
      this.toastr.error(error);
    }
    if (this.productImage.ProductCategories) {
      for (let i = 0; i < this.productImage.ProductCategories.length; i++) {
        if (this.productImage.ProductCategories[i].Name === 'Brandable Product') {
          this.companyBranding = true
        }
      }
    }
  }

  populateGalery() {
    this.galleryList = [];
    this.productImage.ProductImages.forEach(element => {
      const imageList = {
        'url': element.ImageURL,
        'extUrl': element.ImageURL,
        'thumbnailUrl': element.ImageURL
      };
      this.galleryList.push(imageList);
    });
    this.images = this.galleryList;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectLogoPositionID) {
      // If LogoPosition filter is changed in print-price.component, try to find image with that LogoPositionID and show it
      if (changes.selectLogoPositionID.currentValue != '' && changes.selectLogoPositionID.currentValue != undefined) {
        var LogoPositionImages = this.productImage.ProductImagesForLogoPositions.filter(pi => pi.LogoPositionID == changes.selectLogoPositionID.currentValue);
        if (LogoPositionImages.length == 0) {
          LogoPositionImages = this.productImage.ProductImages.filter(pi => pi.LogoPositionID == changes.selectLogoPositionID.currentValue);
        }
        if (LogoPositionImages.length > 0) {
          this.imageSetForLogoPosition = true;
          this.galleryList = [];
          var colorImgFound = false;
          if (this.selectedProductColorID != undefined) {
            var ColorLogoImage = LogoPositionImages.filter(image => image.ProductColorID == this.selectedProductColorID);
            if (ColorLogoImage.length > 0) {
              colorImgFound = true;
              this.productImageURL = ColorLogoImage[0].ImageURL;
              const imageList = {
                'url': ColorLogoImage[0].ImageURL,
                'extUrl': ColorLogoImage[0].ImageURL,
                'thumbnailUrl': ColorLogoImage[0].ImageURL
              };
              this.galleryList.push(imageList);
            }
          }
          if (!colorImgFound) {
            this.productImageURL = LogoPositionImages[0].ImageURL;
            const imageList = {
              'url': LogoPositionImages[0].ImageURL,
              'extUrl': LogoPositionImages[0].ImageURL,
              'thumbnailUrl': LogoPositionImages[0].ImageURL
            };
            this.galleryList.push(imageList);
          }
          this.images = this.galleryList;
        }
        else if (this.imageSetForLogoPosition) {
          this.populateGalery();
          if (this.selectedProductColorID != undefined) {
            var colorImages = this.productImage.ProductImages.filter(image => image.ProductColorID == this.selectedProductColorID);
            if (colorImages.length > 0) {
              this.productImageURL = colorImages[0].ImageURL;
              this.selectImgIndex = this.productImage.ProductImages.indexOf(colorImages[0]);
            }
            else {
              this.productImageURL = this.productImage.ProductImages[0].ImageURL;
              this.selectImgIndex = 0;
            }
          }
          else {
            this.productImageURL = this.productImage.ProductImages[0].ImageURL;
            this.selectImgIndex = 0;
          }
          this.imageSetForLogoPosition = false;
          this.tempProductImageUrl = this.productImageURL;
        }
      }
      else if (changes.selectLogoPositionID.previousValue !== undefined && this.imageSetForLogoPosition) {
        this.populateGalery();
        if (this.selectedProductColorID != undefined) {
          var colorImages = this.productImage.ProductImages.filter(image => image.ProductColorID == this.selectedProductColorID);
          if (colorImages.length > 0) {
            this.productImageURL = colorImages[0].ImageURL;
            this.selectImgIndex = this.productImage.ProductImages.indexOf(colorImages[0]);
          }
          else {
            this.productImageURL = this.productImage.ProductImages[0].ImageURL;
            this.selectImgIndex = 0;
          }
        }
        else {
          this.productImageURL = this.productImage.ProductImages[0].ImageURL;
          this.selectImgIndex = 0;
        }
        this.imageSetForLogoPosition = false;
        this.tempProductImageUrl = this.productImageURL;
      }
    }
    else {
      this.populateGalery();
      if (changes.selectColorId && changes.selectColorId.currentValue != undefined) {
        this.selectedProductColorID = changes.selectColorId.currentValue;
      }
      let allow = true;
      for (let i = 0; i < this.productImage.ProductImages.length; i++) {
        if (changes.selectColorId && changes.selectColorId.currentValue === this.productImage.ProductImages[i].ProductColorID) {
          if (allow) {
            allow = false;
            this.productImageURL = this.productImage.ProductImages[i].ImageURL;
            this.imageSetForLogoPosition = false;
            this.tempProductImageUrl = this.productImageURL;
            this.selectImgIndex = i;
          }
        }
      }
    }
  }

  // open gallery
  openGallery(index) {
    if (this.imageSetForLogoPosition) {
      this.ngxImageGallery.open(0);
    }
    else {
      if (this.selectImgIndex) {
        this.ngxImageGallery.open(this.selectImgIndex);
      } else {
        this.ngxImageGallery.open(index);
      }
    }
  }

  getImgId(imgId, productID, idx) {
    this.selectImgIndex = idx;
    this.imageID = imgId;
    this.productBatchID = productID;
    if (this.imageSetForLogoPosition) {
      this.populateGalery();
    }
  }

  getProductUrlOnMouseLeave() {
    if (this.tempProductImageUrl) {
      //this.productImageURL = this.tempProductImageUrl;
    }
  }

  inrFormat(val: number) {
    return Number(val).toLocaleString('en-IN');
  }

  onMouseDown(e) {
    this.pos = {
      // The current scroll
      left: this.container.nativeElement.scrollLeft,
      top: this.container.nativeElement.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };
  }

  mouseMoveHandler(e) {
    // How far the mouse has been moved
    const dx = (e.clientX - this.pos.x) * 2;
    const dy = (e.clientY - this.pos.y) * 3;

    // Scroll the element
    this.container.nativeElement.scrollTop = this.pos.top - dy;
    this.container.nativeElement.scrollLeft = this.pos.left - dx;
  }

  onLeave() {
    this.container.nativeElement.style.overflow = 'hidden';
    this.img.nativeElement.style.transform = 'scale(1)';
    this.img.nativeElement.style.cursor = 'zoom-in';
  }

  openProductImage(e: any) {
    this.isZoomed = !this.isZoomed;
    if (this.isZoomed) {
      this.container.nativeElement.style.overflow = 'hidden';
      this.img.nativeElement.style.width = '200%';
      this.img.nativeElement.style.cursor = 'zoom-out';
      this.img.nativeElement.style.cursor = 'zoom-out';
      this.img.nativeElement.style.left = `-${e.clientX}`;
      this.img.nativeElement.style.top = `-${e.clientY}`;
    } else {
      this.container.nativeElement.style.overflow = 'hidden';
      this.img.nativeElement.style.width = '100%';
      this.img.nativeElement.style.cursor = 'zoom-in';
    }
  }

  showProductImageModal() {
    this.productImageModal.show();
  }
}
