import { Injectable } from '@angular/core';
import { ToastService } from '../../typescripts/pro/alerts';
import 'rxjs/Rx';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs/Rx';

@Injectable()
export class CommonServicesService {
  redemPoints: BehaviorSubject<Boolean> = new BehaviorSubject(false);
  balancePoints: BehaviorSubject<any> = new BehaviorSubject(0);
  clearSearch: BehaviorSubject<any> = new BehaviorSubject(false);
  constructor(private toastService: ToastService, private toastr: ToastrService) { }


  /**
    * Service for Categories
  */

  // common service for reedem points update in header
  getReedem(): Observable<Boolean> {
    return this.redemPoints.asObservable();
  }

  setReedem(reedemCheck: Boolean) {
    this.redemPoints.next(reedemCheck);
  }

  setPoints(points: any){
    this.balancePoints.next(points)
  }

  getPoints(): Observable<any> {
    return this.balancePoints.asObservable();
  }

  getFileExtenstion(fileName) {
    if (fileName) {
      return fileName.substr(fileName.lastIndexOf('.') + 1)
    }
  }

  setClearAll(clear: any){
    this.clearSearch.next(clear)
  }

  getClearAll(): Observable<any>{
    return this.clearSearch.asObservable()
  }

  qtyValidation(shoppingCartData, qty) {
    let minQtyValue = 0;
    let maxQtyValue = 0;
    let productPriceLength = 0;
    if (shoppingCartData.ProductPrices.length > 0) {
      productPriceLength = (shoppingCartData.ProductPrices.length) - 1;
      minQtyValue = shoppingCartData.ProductPrices[0].MinimumQuantity;
      maxQtyValue = shoppingCartData.ProductPrices[productPriceLength].MaximumQuantity;
    }
    let orderQuantity = 0;
    if (shoppingCartData.FixedUnits) {
      orderQuantity = Number(shoppingCartData.PiecesPerUnit * qty);
    } else {
      orderQuantity = qty;
    }
    let errorStatus = false;
    if (orderQuantity > maxQtyValue || orderQuantity < minQtyValue) {
      errorStatus = true;
    }
    return errorStatus;
  }
  getTabCompletedCount(shoppingCartData) {
    // completed Step Count
    let tabCompletedCount = 1;
    if (shoppingCartData.isColorStepCompleted) {
      tabCompletedCount = tabCompletedCount + 1;
    }
    if (shoppingCartData.isSizesStepCompleted) {
      tabCompletedCount = tabCompletedCount + 1;
    }
    if (shoppingCartData.isAdditionalCostsStepCompleted) {
      tabCompletedCount = tabCompletedCount + 1;
    }
    if (shoppingCartData.isProductPropertiesStepCompleted) {
      tabCompletedCount = tabCompletedCount + 1;
    }
    if (shoppingCartData.isLogosStepCompleted && !shoppingCartData.isSampleWithoutLogo) {
      tabCompletedCount = tabCompletedCount + 1;
    }
    return tabCompletedCount;
  }

  readyForCheckout(shoppingCartList, showError) {
    let goCheckout = true;
    // completed Step Count
    for (let i = 0; i < shoppingCartList.Items.length; i++) {
      let tabCount = 1;
      if (shoppingCartList.Items[i].ProductColors && shoppingCartList.Items[i].ProductColors.length >= 1) {
        tabCount = tabCount + 1;
      }
      if (shoppingCartList.Items[i].ProductSizes && shoppingCartList.Items[i].ProductSizes.length > 0) {
        tabCount = tabCount + 1;
      }
      if (shoppingCartList.Items[i].AdditionalCosts && shoppingCartList.Items[i].AdditionalCosts.length > 0) {
        tabCount = tabCount + 1;
      } else if (shoppingCartList.Items[i].Price.GovernmentalCosts &&
        shoppingCartList.Items[i].Price.GovernmentalCosts.length > 0) {
       // tabCount = tabCount + 1;
      }
      if (shoppingCartList.Items[i].ProductProperties && shoppingCartList.Items[i].ProductProperties.length > 0) {
        tabCount = tabCount + 1;
      }
    
      if (shoppingCartList.Items[i].Logos && shoppingCartList.Items[i].Logos.length > 0 && shoppingCartList.Items[i].Logos[0].PrintPriceID !== undefined && !shoppingCartList.Items[i].isSampleWithLogo && !shoppingCartList.Items[i].isSampleWithoutLogo && !shoppingCartList.Items[i].isProceedWithoutLogo) {
        tabCount = tabCount + 1;
      }

      if(shoppingCartList.Items[i].Logos && shoppingCartList.Items[i].Logos.length > 0 && (shoppingCartList.Items[i].isProceedWithoutLogo || shoppingCartList.Items[i].isSampleWithLogo || shoppingCartList.Items[i].isSampleWithoutLogo)){
        tabCount = tabCount + 1;
      }
      
      // completed Step Count
      let tabCompletedCount = 1;
      if (shoppingCartList.Items[i].isColorStepCompleted) {
        tabCompletedCount = tabCompletedCount + 1;
      }
      if (shoppingCartList.Items[i].isSizesStepCompleted) {
        tabCompletedCount = tabCompletedCount + 1;
      }
      if (shoppingCartList.Items[i].isAdditionalCostsStepCompleted && shoppingCartList.Items[i].AdditionalCosts.length > 0) {
        tabCompletedCount = tabCompletedCount + 1;
      }
      if (shoppingCartList.Items[i].isProductPropertiesStepCompleted) {
        tabCompletedCount = tabCompletedCount + 1;
      }
      if (shoppingCartList.Items[i].Logos.length > 0 && shoppingCartList.Items[i].isLogosStepCompleted) {
        tabCompletedCount = tabCompletedCount + 1;
      }
      if (tabCount === 1 && !showError) {
        shoppingCartList.Items[i].isChecked = true;
      }
      if (tabCompletedCount !== tabCount && tabCount !== 1 && showError) {
        goCheckout = false;
        this.toastr.error(shoppingCartList.Items[i].ProductName + ' is not completed. Please fill all mandatory datas.');
      }
    }
    return goCheckout;
  }

  colorsChange(color) {
    let dropDownDisable = false;
    if (color.NumberOfColors === color.OrderLogoColors.length) {
      dropDownDisable = true;
    }
    return dropDownDisable;
  }

}
