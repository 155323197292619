import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'app/linknet-common/restful-services/message-services/message.service';
import { GlobalService } from '../../globals/global.service';

@Component({
  selector: 'shop-unread-count',
  templateUrl: './unread-count.component.html',
  styleUrls: ['./unread-count.component.scss']
})
export class UnreadCountComponent implements OnInit {
  @Input() numberOfUnreadEmails: any;
  @Output() numberOfUnreadEmailsChange = new EventEmitter();
  public page: any;
  loggedInData: any;
  constructor(public _httpMessagesService: MessageService, public globalService: GlobalService) {
    this.loggedInData = this.globalService.getLoggedInData();
   }

  ngOnInit() {
    if(this.loggedInData){
      this.getMessages();
    }
  }
  getMessages() {
    let params = {
      "UserID": this.loggedInData.UserID,
      "UserRole": this.loggedInData.Roles[0].RoleName,
      "pageNumber": 1,
      "pageSize": 10
    }
    this._httpMessagesService.getEmailList(params).subscribe(
      (data: any) => {
        if (data && data.length > 0) {
          this.numberOfUnreadEmails = data[0].UnReadCount;
          this.numberOfUnreadEmailsChange.emit(data[0].UnReadCount);
          this._httpMessagesService.setState(data[0].UnReadCount);
        }
      }
    );
  }
}
