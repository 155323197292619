import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { CookieService } from "ngx-cookie-service";
import { WebshopService, CampaignService, ProductService } from "../../../linknet-common/restful-services/restful.service";
import { GlobalService, CurrencySetterService, MessageDataService } from "../../../linknet-common/globals/global.service";
import { CommonServicesService } from "app/linknet-common/common-services/common-services.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'shop-app-shopping-cart',
    templateUrl: './shopping-cart.component.html',
    styleUrls: ['./shopping-cart.component.scss'],
    exportAs: 'child'
  })

export class ShoppingCartComponent implements OnInit {
    @ViewChild("productCartModal") public productCartModal;
    @ViewChild("DeleteModal") public deleteModal;
    @ViewChild("DeleteModalOneItem") public deleteModalOneItem;
    @ViewChild("inquiryModal") public inquiryModal;
    indexData: any = {};
    public itemsList: any;
    public itemsListA: any;
    public loaderShow: boolean = false;
    public cartParams: any;
    isVoucherKart: boolean = false;
    public shoppingCartList: any;
    totalPrice: any = 0;
    public toggleClass = [];
    public checkoutDataList = [];
    disableCheckout: Boolean = false;
    balanceRewardpoints: any = 0;
    isParentCustomer: boolean = true;
    public showBalanceMsg: boolean = false;
    public checkoutStatus: boolean = false;
    tempPoints: any = 0;
    avlPoints: any;
    public cartCount;
    balancePoints: any = 0;
    FileNameCompletePath: any;
    public joinOrderCounts: Number = 0;
    FileName: any;
    public getShoppingCartIDList: any;
    public translatedMessage: any;
    public shoppingCartPostParams: any;
    toastRef: any;
    todatalquality: boolean;
  TotalValUpdateShow: boolean = false;
  deliveryDate: any;
  isExpressOrder: any;
  isSampleWithLogo: any;
  isSampleWithoutLogo: any;
  isProceedWithoutLogo: any;
  isChecked: any;
  isInitialStepCompleted: any;
  isColorStepCompleted: any;
  isSizesStepCompleted: any;
  isAdditionalCostsStepCompleted: any;
  isProductPropertiesStepCompleted: any;
  isLogosStepCompleted: any;
  Comment: any;
  Logos: any;
  Price: any;
  SupplierID: any;
  ShoppingCartItemID: any;
  ProductionLineOrderedQuantity: any;
  isDeliveryTimeCompleted: any;
  ProductIdManual: any;
  ProductName: any;
  ProductUnitName: any;
  ProductImageURL: any;
  MinimumOrder: any;
  DeliveryWeeksFrom: any;
  DeliveryWeeksTo: any;
  PiecesPerUnit: any;
  FixedUnits: any;
  isFixedLogo: any;
  EarliestDeliveryDate: any;
  EarliestDeliveryDateForNumOfDaysTillProductStart: any;
  StockBalance: any;
  VATPercentageToInclude: any;
  ProductColorCodes: any;
  isJoinOrder: any;
  isDeliveryDateCorrected: any;
  isDisabled: any;
  isProductDeleted: any;
  isExpired: any;
  isValid: any;
  isItemCompleted: any;
  ProductImages: any;
  ProductImagesForLogoPositions: any;
  ProductPrices: any;
  ProductPrintPrices: any;
  ProductSizes: any;
  ProductColors: any;
  ProductProperties: any;
  AdditionalCosts: any;
  ProductFutureStock: any;
  ProductLogoPositions: any;
  ProductLogoMaxAreas: any;
  ProductLogoTypes: any;
  IsPickupatWarehouse: any;
  RelatedItemID: any;
  CurrencyCode: any;
  CurrencySymbol: any;
  TotalWithoutVAT: any;
  GrandTotal: any;
  OrderTotalHandlingCost: any;
  OrderTotalTransportationCost: any;
  cartProductID: any;
  userID:any;
  getCheckoutDetails:any;
  cart: any;

    constructor(private toastr: ToastrService, private cookieService: CookieService, private _httpWebshop: WebshopService, private globalService: GlobalService, private campaignService: CampaignService, private router: Router, private commonService: CommonServicesService, private translate: TranslateService){
          const webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
          if (webshopDetail.ParentCustomerDomainName === webshopDetail.DomainName ) {
            this.isParentCustomer = true;
          } else {
            this.isParentCustomer = false;
            if(webshopDetail.DomainName === "voucherkart"){
              this.isVoucherKart = true;
            }
          }
    }

    ngOnInit(){

    }

    showCartModal() {  
      this.getShoppingCartList();
    }

    getShoppingCartList(){

        try {
            let userBrowserID = "";
            const cookieExists: Boolean = this.cookieService.check(
              "shoppingCardTokenId"
            );
            if (!cookieExists) {
              let domainName = this._httpWebshop.getSubdomain(
                window.location.hostname
              );
              domainName = domainName ? domainName : 'voucherkart';
              const randomString = this._httpWebshop.randomGeneration(6);
              this.cookieService.set("shoppingCardTokenId", domainName + randomString);
              userBrowserID = domainName + randomString;
            } else {
              userBrowserID = this.cookieService.get("shoppingCardTokenId");
            }
            const userSettings = this.globalService.getUserSettings();
            const webShopSettings = this.globalService.webShopSettings();
            const loggedInData = this.globalService.getLoggedInData();
            if (loggedInData !== null) {
              this.cartParams = {
                UserID: loggedInData.UserID,
                CustomerID: webShopSettings.CustomerID,
                CurrencyID: 20,
                UserBrowserID: userBrowserID,
                InitialDeliveryCountry: loggedInData.Country,
                ReturnOnlyVisibleItems: true,
                //  'ReturnOnlyValidCheckedVisibleCompleteItems': true   // new-code
              };
            } else {
              this.cartParams = {
                UserID: null,
                CustomerID: webShopSettings.CustomerID,
                CurrencyID: 20,
                UserBrowserID: userBrowserID,
                InitialDeliveryCountry: userSettings.CountryCode,
                ReturnOnlyVisibleItems: true,
                // 'ReturnOnlyValidCheckedVisibleCompleteItems': true    // new-code
              };
            }
            this.loaderShow = true;
      
            if(this.isVoucherKart){
              this.cartParams.IsVoucher = true;
            }else{
              this.cartParams.IsVoucher = false;
            }
            
            this._httpWebshop.getShoppingCart(this.cartParams).subscribe((data: any[]) => {
                this.shoppingCartList = data;
                this.productCartModal.show();
                sessionStorage.setItem("cartData", JSON.stringify(this.shoppingCartList));
                this.loaderShow = false;
                this.itemsList = data["Items"];
                this.itemsListA = data["Items"];
                /** new-code => Restirct to display invisible: false items of shopping cart */
                const shoppingCartitemData = [];
                let k = 0;
                this.totalPrice = 0;
                for (let i = 0; i < data["Items"].length; i++) {
                  this.itemsList[i].disableUpdate = true;
                  if (this.itemsList[i].isChecked) {
                  this.totalPrice = this.totalPrice + this.itemsList[i].Price.TotalProductPrice;
                  }
                  if (data["Items"][i].isVisible) {
                    shoppingCartitemData[k] = data["Items"][i];
                    k++;
                  }
                }
                this.itemsList = shoppingCartitemData;
                this.itemsListA = shoppingCartitemData;
                this.checkOutStatusFn();
                /** new-code => To check all steps are completed for product */
                for (let i = 0; i < this.itemsList.length; i++) {
                  this.toggleClass[i] = false;
                  if (this.itemsList[i].isChecked) {
                    this.itemsList[i].isCheckboxChecked = true;
                    this.disableCheckout = false;
                    
                    this.itemsList[i].isAllStepCompleted = true;
                    this.itemsList[i].isCheckboxChecked = true;
                    
                  }
                }
                if (!this.isParentCustomer) {
                  const getLogged = this.globalService.getLoggedInData();
                  this._httpWebshop.getRedeemUser(getLogged.UserID).subscribe((_res) => {
                      //this.balancePoints = res["RewardPoints"];
                      if (this.tempPoints < 0) {
                        this.tempPoints = 0;
                      }
                      this.avlPoints = this.tempPoints;
                      let a = this.campaignService.getActiveCampaign().subscribe((res) => {
                        if (res) {
                          this.commonService.setPoints(res.BalancePoints);
                          this.balancePoints = res.BalancePoints;
                          this.tempPoints = this.balancePoints - this.totalPrice;
                        } else {
                          //this.commonService.setPoints(this.tempPoints);
                        }
                      });
                      a.unsubscribe();
                    });
                }
              
      
                for (let i = 0; i < this.itemsList.length; i++) {
                  for (let j = 0; j < this.itemsList[i].ProductColors.length; j++) {
                    if (this.itemsList[i].ProductColorID === this.itemsList[i].ProductColors[j].ProductColorID) {
                      this.itemsList[i].colorIndex = j;
                    }
                  }
                }
                this.balanceRewardpoints = this.avlPoints - this.totalPrice;
                // this._httpWebshop.changeMessage(data['Items'].length);
                this._httpWebshop.changeMessage(shoppingCartitemData.length);
                this._httpWebshop.currentMessage.subscribe(
                  (message) => (this.cartCount = message)
                );
                //  this.cartCount = data.Items.length;
                this.cartCount = shoppingCartitemData.length;
      
                /** new-code */
                // sessionStorage.setItem('shoppingCartDatas', JSON.stringify(data['Items']));
                sessionStorage.setItem("shoppingCartDatas", JSON.stringify(shoppingCartitemData));
                /** new-code */
      
                sessionStorage.setItem("shoppingCartCount", this.cartCount.toString());
                setTimeout(() => {
                  if (sessionStorage.getItem("joinOrderCounts") !== "undefined" && sessionStorage.getItem("joinOrderCounts") !== null && sessionStorage.getItem("joinOrderCounts") !== "") {
                    this.joinOrderCounts = JSON.parse(sessionStorage.getItem("joinOrderCounts"));
                  }
                }, 5000);
              },
              _error => {
                this.loaderShow = false;
                //this.toastr.error(error);
              }
            );
          } catch (error) {
            this.loaderShow = false;
            //this.toastr.error(error);
          }
    }

    shoppingCartShow(id, i) {
    
        this.itemsList[i].OrderedQuantity = this.itemsList[i].Price.OrderedQuantity;
        this.itemsList.forEach((elements) => {
          if (elements.ShoppingCartItemID === id) {
            this.deliveryDate = elements.DeliveryDate;
            this.isExpressOrder = elements.isExpressOrder;
            this.isSampleWithLogo = elements.isSampleWithLogo;
            this.isSampleWithoutLogo = elements.isSampleWithoutLogo;
            this.isProceedWithoutLogo = elements.isProceedWithoutLogo;
            this.isChecked = elements.isCheckboxChecked;
            this.isInitialStepCompleted = elements.isInitialStepCompleted;
            this.isColorStepCompleted = elements.isColorStepCompleted;
            this.isSizesStepCompleted = elements.isSizesStepCompleted;
            this.FileName = elements.FileName;
            this.FileNameCompletePath = elements.FileNameCompletePath;
            this.isAdditionalCostsStepCompleted =
              elements.isAdditionalCostsStepCompleted;
            this.isProductPropertiesStepCompleted =
              elements.isProductPropertiesStepCompleted;
            this.isLogosStepCompleted = elements.isLogosStepCompleted;
            this.Comment = elements.Comment;
            this.Logos = elements.Logos;
            this.Price = elements.Price;
            (this.SupplierID = elements.SupplierID),
              (this.ShoppingCartItemID = elements.ShoppingCartItemID),
              (this.ProductionLineOrderedQuantity =
                elements.ProductionLineOrderedQuantity),
              (this.isDeliveryTimeCompleted = elements.isDeliveryTimeCompleted),
              (this.ProductIdManual = elements.ProductIdManual),
              (this.ProductName = elements.ProductName),
              (this.ProductUnitName = elements.ProductUnitName),
              (this.ProductImageURL = elements.ProductImageURL),
              (this.MinimumOrder = elements.MinimumOrder),
              (this.DeliveryWeeksFrom = elements.DeliveryWeeksFrom),
              (this.DeliveryWeeksTo = elements.DeliveryWeeksTo),
              (this.PiecesPerUnit = elements.PiecesPerUnit),
              (this.FixedUnits = elements.FixedUnits),
              (this.isFixedLogo = elements.isFixedLogo),
              (this.EarliestDeliveryDate = elements.EarliestDeliveryDate),
              (this.EarliestDeliveryDateForNumOfDaysTillProductStart =
                elements.EarliestDeliveryDateForNumOfDaysTillProductStart),
              (this.StockBalance = elements.StockBalance),
              (this.VATPercentageToInclude = elements.VATPercentageToInclude),
              (this.ProductColorCodes = elements.ProductColorCodes),
              (this.isJoinOrder = elements.isJoinOrder),
              (this.isDeliveryDateCorrected = elements.isDeliveryDateCorrected),
              (this.isDisabled = elements.isDisabled),
              (this.isProductDeleted = elements.isProductDeleted),
              (this.isExpired = elements.isExpired),
              (this.isValid = elements.isValid),
              (this.isItemCompleted = elements.isItemCompleted),
              (this.ProductImages = elements.ProductImages),
              (this.ProductImagesForLogoPositions =
                elements.ProductImagesForLogoPositions),
              (this.ProductPrices = elements.ProductPrices),
              (this.ProductPrintPrices = elements.ProductPrintPrices),
              (this.ProductSizes = elements.ProductSizes),
              (this.ProductColors = elements.ProductColors),
              (this.ProductProperties = elements.ProductProperties),
              (this.AdditionalCosts = elements.AdditionalCosts),
              (this.ProductFutureStock = elements.ProductFutureStock),
              (this.ProductLogoPositions = elements.ProductLogoPositions),
              (this.ProductLogoMaxAreas = elements.ProductLogoMaxAreas),
              (this.ProductLogoTypes = elements.ProductLogoTypes),
              (this.IsPickupatWarehouse = elements.IsPickupatWarehouse),
              (this.RelatedItemID = elements.RelatedItemID),
              (this.CurrencyCode = elements.CurrencyCode),
              (this.CurrencySymbol = elements.CurrencySymbol),
              (this.TotalWithoutVAT = elements.Price.TotalProductPrice);
            (this.GrandTotal = elements.Price.TotalProductPrice),
              (this.OrderTotalHandlingCost = elements.OrderTotalHandlingCost),
              (this.OrderTotalTransportationCost =
                elements.OrderTotalTransportationCost);
            this.cartProductID = elements.ProductID;
          }
        });
    
        this.shoppingCartPostParams = {
          UserID: this.shoppingCartList.UserID,
          CustomerID: this.shoppingCartList.CustomerID,
          CurrencyID: 20,
          CurrencyCode: this.CurrencyCode,
          CurrencySymbol: this.CurrencySymbol,
          UserBrowserID: this.shoppingCartList.UserBrowserID,
          TotalWithoutVAT: this.TotalWithoutVAT,
          GrandTotal: this.GrandTotal,
          OrderTotalHandlingCost: this.OrderTotalHandlingCost,
          OrderTotalTransportationCost: this.OrderTotalTransportationCost,
          InitialDeliveryCountry: this.shoppingCartList.InitialDeliveryCountry,
          Item: {
            UserBrowserID: this.shoppingCartList.UserBrowserID,
            ProductID: this.cartProductID,
            SupplierID: this.SupplierID,
            ShoppingCartItemID: this.ShoppingCartItemID,
            DeliveryDate: this.deliveryDate,
            ProductionLineOrderedQuantity: this.ProductionLineOrderedQuantity,
            OrderedQuantity: this.itemsList[i].Price.OrderedQuantity,
            ProductColorID: null,
            ProductName: this.ProductName,
            ProductUnitName: this.ProductUnitName,
            isExpressOrder: this.isExpressOrder,
            isDeliveryTimeCompleted: this.isDeliveryTimeCompleted,
            isSampleWithoutLogo: this.isSampleWithoutLogo,
            isSampleWithLogo: this.isSampleWithLogo,
            isProceedWithoutLogo: this.isProceedWithoutLogo,
            isEmailLogoLater: false,
            isChecked: this.isChecked ? true : false,
            ProductIdManual: this.ProductIdManual,
            ProductImageURL: this.ProductImageURL,
            MinimumOrder: this.MinimumOrder,
            DeliveryWeeksFrom: this.DeliveryWeeksFrom,
            DeliveryWeeksTo: this.DeliveryWeeksTo,
            FixedUnits: this.FixedUnits,
            PiecesPerUnit: this.PiecesPerUnit,
            isFixedLogo: this.isFixedLogo,
            StockBalance: this.StockBalance,
            VATPercentageToInclude: this.VATPercentageToInclude,
            ProductColorCodes: this.ProductColorCodes,
            ProductImages: this.ProductImages,
            ProductImagesForLogoPositions: this.ProductImagesForLogoPositions,
            ProductPrices: this.ProductPrices,
            ProductPrintPrices: this.ProductPrintPrices,
            ProductSizes: this.ProductSizes,
            ProductColors: this.ProductColors,
            ProductProperties: this.ProductProperties,
            AdditionalCosts: this.AdditionalCosts,
            ProductFutureStock: this.ProductFutureStock,
            ProductLogoPositions: this.ProductLogoPositions,
            ProductLogoMaxAreas: this.ProductLogoMaxAreas,
            ProductLogoTypes: this.ProductLogoTypes,
            IsPickupatWarehouse: this.IsPickupatWarehouse,
            RelatedItemID: this.RelatedItemID,
            isJoinOrder: this.isJoinOrder,
            isDisabled: this.isDisabled,
            isProductDeleted: this.isProductDeleted,
            isExpired: this.isExpired,
            isItemCompleted: this.isItemCompleted,
            isDeliveryDateCorrected: this.isDeliveryDateCorrected,
            EarliestDeliveryDateForNumOfDaysTillProductStart: this.EarliestDeliveryDateForNumOfDaysTillProductStart,
            EarliestDeliveryDate: this.EarliestDeliveryDate,
            isInitialStepCompleted: this.isInitialStepCompleted,
            isColorStepCompleted: this.isColorStepCompleted,
            isSizesStepCompleted: this.isSizesStepCompleted,
            FileName:this.FileName,
            FileNameCompletePath:this.FileNameCompletePath,
            isAdditionalCostsStepCompleted: this.isAdditionalCostsStepCompleted,
            isProductPropertiesStepCompleted: this.isProductPropertiesStepCompleted,
            // 'isLogosStepCompleted': isProceedWithoutLogo,
            isLogosStepCompleted: this.isLogosStepCompleted,
            Comment: this.Comment,
            Logos: this.Logos,
            Price: this.Price,
            IsVoucher: this.isVoucherKart,
            // tslint:disable-next-line:max-line-length
            isVisible: true, // new-code code is based on to show shopping cart product in widget
          },
        };

        if(this.isVoucherKart){
          this.shoppingCartPostParams.Item.VoucherMRP = Number(this.Price.ProductPrice);
          this.shoppingCartPostParams.VoucherMRP = Number(this.Price.ProductPrice)
        }
        this._httpWebshop.addShoppingCart(this.shoppingCartPostParams).subscribe((data) => {
              //console.log("After addShoppingCart Response : ");
              sessionStorage.setItem("cartData", JSON.stringify(data));
              this.toastr.success("Product has been updated to your shopping cart");
              this.itemsList[i].disableUpdate = true;
              this.disableCheckout = false;
              this.checkOutStatusFn();
              this.router.navigate(['/'])
              /*for (let c = 0; c < this.itemsList.length; c++) {
                if (!this.itemsList[c].disableUpdate || !this.itemsList[c].isCheckboxChecked || !this.itemsList[c].Price.OrderedQuantity || parseInt(this.itemsList[c].Price.OrderedQuantity) === 0) {
                  this.disableCheckout = true;
                }
              }*/
              //this.getShoppingCartList()
            },
            (error) => {
              if (error.error.Message) {
                this.toastr.error("Error: " + error.error.Message);
              }
            }
          );
        this.balanceRewardpoints = this.avlPoints - this.totalPrice;
    }

      checkBalance(qty, price, i) {
        this.disableCheckout = true;
        qty = qty.replace(/\b0+/g, "");
        if (!this.isParentCustomer) {
          if (!this.isVoucherKart && qty > 50) {
            this.productCartModal.hide();
            this.indexData.Name = this.itemsList[i].ProductName;
            this.indexData.ProductManualId = this.itemsList[i].ProductIdManual;
            this.indexData.Quantity = qty;
            this.indexData.ImageURL = this.itemsList[i].ProductImageURL;
            setTimeout(() => {
              this.inquiryModal.showInquiryModal(this.indexData);
            }, 500);
            qty = 0;
          }
    
        } else {
          if (qty > 500) {
            this.productCartModal.hide();
            this.indexData.Name = this.itemsList[i].ProductName;
            this.indexData.ProductManualId = this.itemsList[i].ProductIdManual;
            this.indexData.Quantity = qty;
            this.indexData.ImageURL = this.itemsList[i].ProductImageURL;
            
            setTimeout(() => {
              this.inquiryModal.showInquiryModal(this.indexData);
            }, 500);
            qty = 0;
            return false;
          }
         
        }
        if (parseInt(qty) === 0 || !qty) {
          if (this.itemsList[i].isCheckboxChecked) {
            this.totalPrice = this.totalPrice - this.itemsList[i].Price.TotalProductPrice;
          }else{
            //this.disableCheckout = true;
          }
          this.itemsList[i].Price.OrderedQuantity = "";
          this.itemsList[i].Price.TotalProductPrice = 0;
          this.itemsList[i].disableUpdate = true;
          return false;
        } else {
    
          this.itemsList[i].disableUpdate = false;
          let item = this.itemsList[i];
          let productPrintPrices = price;
          for (let x = 0; x < item.ProductPrices.length; x++) {
            if (
              qty >= item.ProductPrices[x].MinimumQuantity && item.ProductPrices[x].MaximumQuantity >= qty
            ) {
              productPrintPrices = Math.round(item.ProductPrices[x].Price);
            }
          }
          
          if(this.isParentCustomer){
            this.itemsList[i].Price.ProductPrice = productPrintPrices;
          }else{
            productPrintPrices = this.itemsList[i].Price.ProductPrice;
          }
         
          this.itemsList[i].Price.TotalProductPrice = parseInt(productPrintPrices) * qty;
    
          let allTempCost = 0;
          for (let a = 0; a < this.itemsList.length; a++) {
            if (this.itemsList[a].isCheckboxChecked) {
              allTempCost = allTempCost + this.itemsList[a].Price.TotalProductPrice;
            }
          }
          this.totalPrice = allTempCost;
        }
        if (!this.isParentCustomer && !this.isVoucherKart) {
          /*if (this.balancePoints - this.totalPrice < 0){
            if (this.toastRef) {
              this.toastr.clear(this.toastRef.ToastId);
            }
            this.avlPoints = 0;
            this.toastRef = this.toastr.error("You don't have enough points to continue");
            this.itemsList[i].disableUpdate = true;
          }else{
            if (this.toastRef) {
              this.toastr.clear(this.toastRef.ToastId);
            }
          }*/
        }
      }   
    
  checkOutStatusFn(){
    let sum = 0;
    let selected = 0;
    for (let c = 0; c < this.itemsList.length; c++) {
      if (!this.itemsList[c].disableUpdate || !this.itemsList[c].Price.OrderedQuantity || parseInt(this.itemsList[c].Price.OrderedQuantity) === 0) {
        this.disableCheckout = true;
      }
      if (!this.itemsList[c].isCheckboxChecked) {
        sum++;
      }else{
        selected++;
      }
    }
    if (sum === this.itemsList.length) {
      this.disableCheckout = true;
    }
  }

  itemSelect(i) {
    setTimeout(() => {
      //this.disableCheckout = false;
      let len = this.itemsList.length;
      if (this.itemsList[i].isCheckboxChecked) {
        this.disableCheckout = false;
        this.totalPrice = this.totalPrice + this.itemsList[i].Price.TotalProductPrice;
        this.isChecked = true;
        for (let d = 0; d < len; d++) {
          if (!this.itemsList[d].disableUpdate || (this.itemsList[d].isCheckboxChecked && !this.itemsList[d].Price.OrderedQuantity)) {
            this.disableCheckout = true;
          }
        }
      } else {
        if(!this.itemsList[i].Price.OrderedQuantity){
          this.disableCheckout = true;
        }
        this.totalPrice = this.totalPrice - this.itemsList[i].Price.TotalProductPrice;
        this.isChecked = false;
      }

      if (this.totalPrice < 0) {
        this.totalPrice = 0;
      } else {
        this.totalPrice = this.totalPrice;
      }
      let sum = 0;
      let selected = 0
      for (let c = 0; c < this.itemsList.length; c++) {
        if (!this.itemsList[c].isCheckboxChecked) {
          sum++;
        }else{
          selected++;
        }
      }
      if (sum === this.itemsList.length) {
        this.disableCheckout = true;
      }else{
        this.disableCheckout = false;
      }

      for (let d = 0; d < len; d++) {
        if (!this.itemsList[d].disableUpdate || (this.itemsList[d].isCheckboxChecked && !this.itemsList[d].Price.OrderedQuantity)) {
          this.disableCheckout = true;
        }
      }
      if(selected === 0){
        this.totalPrice = 0;
      }
      /*if (!this.isParentCustomer && !this.isVoucherKart) {
        if (this.balancePoints - this.totalPrice < 0){
          if (this.toastRef) {
            this.toastr.clear(this.toastRef.ToastId);
          }
          this.avlPoints = 0;
          this.toastRef = this.toastr.error("You don't have enough points to continue");
          this.itemsList[i].disableUpdate = true;
          this.disableCheckout = true;
        }else{
          if (this.toastRef) {
            this.toastr.clear(this.toastRef.ToastId);
          }
        }
      }*/
    }, 100);
  
  }

  onlyNumberKey(event) {
    const pattern = new RegExp("^[0-9\b]+$");
    const key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    if (!pattern.test(key) && event.charCode !== 0) {
      event.preventDefault();
      return false;
    }
  }

  inrFormat(val: number) {
    return Number(val).toLocaleString("en-IN");
  }

  checkOut() {
    let goCheckout = true;
    let checkedCount = 0;
    this.getShoppingCartIDList = [];
    this.checkoutDataList = [];
    for (let i = 0; i < this.itemsList.length; i++) {
      // if (this.itemsList[i].isChecked) {
      if (this.itemsList[i].isCheckboxChecked) {
        this.itemsList[i].isChecked = true;
      } else {
        this.itemsList[i].isChecked = false;
      }
      this.checkoutStatus = true;
      this.getShoppingCartIDList.push(this.itemsList[i].ShoppingCartItemID);
      checkedCount = checkedCount + 1;
      this.checkoutDataList.push(this.itemsList[i]);
    }
    if (Number(checkedCount) === 0) {
      // this.translatedMessage = (this.translate.get('HEADER_ONE.PLEASE_SELECT_ONE'));
      this.translate.get("HEADER_ONE.PLEASE_SELECT_ONE").subscribe((res: string) => {
          this.translatedMessage = res;
        });
      this.toastr.error(this.translatedMessage);
    } else if (goCheckout && checkedCount > 0) {
      this.shoppingCartList.Items = this.checkoutDataList;

      this._httpWebshop.kycStatus(this.shoppingCartList.UserID).subscribe();
        delete this.shoppingCartList.Item;
        if(this.isVoucherKart){
         this.shoppingCartList.IsVoucher = true;
        }else{
          this.shoppingCartList.IsVoucher = false;
        }
      if(!this.isParentCustomer && !this.isVoucherKart){
          this.showBalanceMsg = true;
          setTimeout(()=>{
            this.addCart(this.shoppingCartList)
          }, 1500)
         /* if((this.balancePoints - this.totalPrice) >= 0){
            setTimeout(()=>{
              this.addCart(this.shoppingCartList)
            }, 1500)
          }else{
            //this.toastr.error("You don't have enough points to proceed.");
          }*/
      }
      // else if(this.isVoucherKart){
      //   this.addCart(this.shoppingCartList);
      
        
       
        // this._httpWebshop.getCheckout(this.userID).subscribe((data:any) => {
        //   this.getCheckoutDetails = data;
        //   console.log("second")
        //   this.loaderShow = false;
        
          // if(this.getCheckoutDetails){
          //   console.log("ffff")
          
            // this.addCart(this.shoppingCartList);
          // }else{
          //   this.loaderShow = false;
          //   this.toastr.error("The maximum allowed quantity for the cart is 10 and the value should should not exceed 20000 ");
          //   return;
          // }
       
      // }
      else{
        this.addCart(this.shoppingCartList);
      }

      
    }
    /*if(!this.isParentCustomer){
      let balanceRewardpoints = this.avlPoints-this.totalPrice;
      this.toastr.success(`Your balance point is ${balanceRewardpoints}`);
    }*/

    var mobileToggle = document.querySelectorAll('.navbar-collapse.collapse.show')[0] as HTMLElement;
    if(mobileToggle) {
      mobileToggle.classList.remove('show');
    } 
  }

  
  addCart(list){
    this.loaderShow = true;
    const logInData = JSON.parse(sessionStorage.getItem("loggedInData"));
    this.userID = logInData.UserID
    this.loaderShow = true;
    this._httpWebshop.addShoppingCarts(list).subscribe((_data) => {
      this.loaderShow = false;
      this.showBalanceMsg = false;
      if(!this.isVoucherKart){
        this.productCartModal.hide();
        this.router.navigate(["/checkout"], { replaceUrl: false });
      }else{
        this._httpWebshop.getCheckout(this.userID).subscribe((data:any) => {
          this.getCheckoutDetails = data;
        if(this.getCheckoutDetails){
          this.loaderShow = false;
          this.productCartModal.hide();
          this.router.navigate(["/checkout-voucher"], { replaceUrl: false });
        }
        else{
          this.loaderShow = false;
          this.toastr.error("The maximum allowed quantity for the cart is 10 and the value should should not exceed 20000 ");
          return;
        }
      })
    }
      // this.productCartModal.hide();
      
      // Remove class in body tag
      const bodytag = document.getElementsByTagName("body")[0];
      bodytag.classList.remove("shoppingModal"); // remove the class
    },
    _error=>{
      this.loaderShow = false;
    });
  }
  

  openDeleteModel() {

    this.getShoppingCartIDList = [];
    for (let i = 0; i < this.itemsList.length; i++) {
      /** new-code => Proceed to checkout only product which completed all steps  */
      if (this.itemsList[i].isCheckboxChecked) {
        this.getShoppingCartIDList.push(this.itemsList[i].ShoppingCartItemID); // old-code
      }
      /** new-code => Proceed to checkout only product which completed all steps  */
    }
    if (this.getShoppingCartIDList.length !== 0) {
      this.deleteModal.show();
    } else {
      // this.translatedMessage = (this.translate.get('HEADER_ONE.PLEASE_SELECT_ONE'));
      this.translate.get("HEADER_ONE.PLEASE_SELECT_ONE").subscribe((res: string) => {
          this.translatedMessage = res;
        });
      this.toastr.error(this.translatedMessage);
    }
  }

  openDeleteModelOneItem(ShoppingCartItemID) {
    this.getShoppingCartIDList = [];
    this.getShoppingCartIDList.push(ShoppingCartItemID);
    this.deleteModalOneItem.show();
  }

  deleteShoppingCartItem(type,ids?) {
   
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    const loggedInData = this.globalService.getLoggedInData();
    if (loggedInData !== null) {
      this.cartParams = {
        UserID: loggedInData.UserID,
        CustomerID: webShopSettings.CustomerID,
        CurrencyID: 20,
        DeleteShoppingCartItemIDs: ids ? ids : this.getShoppingCartIDList,
      };
    } else {
      this.cartParams = {
        UserID: null,
        CustomerID: webShopSettings.CustomerID,
        CurrencyID: 20,
        DeleteShoppingCartItemIDs: ids ? ids : this.getShoppingCartIDList,
      };
    }
    try {
      this.loaderShow = true;
      this._httpWebshop.deleteShoppingCartItem(this.cartParams).subscribe(
        (_data) => {
          this.loaderShow = false;
          if (type === 0) {
            this.deleteModal.hide();
          } else {
            this.deleteModalOneItem.hide();
          }
          this.getShoppingCartList();
          this.router.navigate(["/"]);
        },
        (error) => {
          this.toastr.error(error);
        }
      );
    } catch (error) {
      this.toastr.error(error);
    }
  }
  closeCartModel() {
    //this.commonService.setPoints(this.tempPoints);
    this.productCartModal.hide();
    // Remove class in body tag
    const bodytag = document.getElementsByTagName("body")[0];
    bodytag.classList.remove("shoppingModal"); // remove the class
    const set = document.getElementById("fullHeightModalRight");
    set.classList.remove("displayShoppingcart");
  }
  RedirecttoHome() {
    this.router.navigate(["/"], { replaceUrl: false });
    this.productCartModal.hide();
    var mobileToggle = document.querySelectorAll('.navbar-collapse.collapse.show')[0] as HTMLElement;
    if(mobileToggle) {
      mobileToggle.classList.remove('show');
    } 
  }
  }