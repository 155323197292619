import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import {
  WebshopService, CategoryService, OrdersService, PointsService,
  ProductService, SecurityService
} from './linknet-common/restful-services/restful.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { GlobalService, CurrencySetterService } from './linknet-common/globals/global.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'app/linknet-common/restful-services/message-services/message.service';
import { Title } from '@angular/platform-browser';
import { fromEvent, Observable, Subscription } from "rxjs";
import * as _ from 'lodash';

@Component({
  selector: 'shop-root',
  templateUrl: 'app.component.html',
  providers: [WebshopService, CategoryService, OrdersService, PointsService, ProductService, SecurityService],
  styleUrls: ['app.component.css']

})

export class AppComponent implements OnInit, OnDestroy {
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription;
  private _routeScrollPositions: { [url: string]: number }[] = [];
  private _subscriptions: Subscription[] = [];
  public loggedInData;
  public currency: any;
  public isWebshopReaady = false;
  public isPrivate = false;
  public isCollapsed = true;
  public bodyVar = document.querySelector('body');
  public isShowArrow: boolean = false;
  public webShopSettings: any;
  public isHomePage: boolean = false;
  public filterEnableStatus: boolean = false;
  public footerLinksList: any;
  public urlParams: any;
  public billBoardList: Array<any>;
  public isParentCustomer: boolean = true;
  public isVoucherKart: boolean = false;
  title: string = '';
  underMaintenance: boolean = false;
  isMobile: boolean = false;
  CID: any;
  enquiryForm: boolean = false;
  landingPage: boolean = false;
  isSmartWorks: boolean = false;
  webshop: any;
  constructor(private router: Router, private _http: WebshopService,
    public globalService: GlobalService, public translate: TranslateService, public currencySetterService: CurrencySetterService,
    public titleService: Title,  public _httpMessagesService: MessageService,  public _httpProductService: ProductService) {
      this.webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if(this.webshop && (this.webshop.DomainName === "sw" || this.webshop.DomainName === "smartworks")){
        this.isSmartWorks = true
      }
      if (window.innerWidth > 768) {
        this.isMobile = false;
      }else{
       this.isMobile = true;
      }
    // Remove class in body tag
    this.webShopSettings = this.globalService.webShopSettings();
    const bodytag = document.getElementsByTagName('body')[0];
    bodytag.classList.remove('productModal');   // remove the class
    this.currency = currencySetterService.currency;
    if (window.location.pathname === "/enquiryform") {
      this.enquiryForm = true;
    }
    if (window.location.pathname === "/landingpage") {
      this.landingPage = true;
    }
    if (JSON.parse(sessionStorage.getItem('loggedInData')) !== null) {
      translate.setDefaultLang(JSON.parse(sessionStorage.getItem('loggedInData')).UserLanguageCode);
    }
    if (JSON.parse(sessionStorage.getItem('loggedInData')) !== null) {
      this.globalService.overwriteSession('userSettings', 'LanguageCode', JSON.parse(sessionStorage.getItem('loggedInData')).UserLanguageCode);
      translate.setDefaultLang(JSON.parse(sessionStorage.getItem('loggedInData')).UserLanguageCode);
    }


    if (!JSON.parse(sessionStorage.getItem('webshopDetail'))) {
      this._http.getWebmshopDetail().subscribe(
        (data: any[]) => {
          if (data['isWebShopActive']) {
            if (data['LanguageID']) {
              sessionStorage.setItem('oldDefaultLanguage', data['LanguageID']);
            }
            sessionStorage.setItem('webshopDetail', JSON.stringify(data));

            this.isPrivate = true;
            if (data['isPrivate'] === 'False') {
              this.isPrivate = false;
            }
            if (JSON.parse(sessionStorage.getItem('loggedInData')) !== null) {
              this.isPrivate = false;
            }
            this.bodyVar.style.setProperty('$baseColor', data['Themes'][0].Main);
            this.bodyVar.style.setProperty('--labelColor', data['Themes'][0].Lable);
            this.bodyVar.style.setProperty('--alertColor', data['Themes'][0].Alert);

            if (JSON.parse(sessionStorage.getItem('userSettings')) === null) {
              this.globalService.overwriteSession('userSettings', 'LanguageCode', data['DefaultLanguageCode']);
              this.globalService.overwriteSession('userSettings', 'LanguageID', data['DefaultLanguageID']);
              this.globalService.overwriteSession('userSettings', 'CurrencyCode', data['DefaultCurrencyCode']);
              this.globalService.overwriteSession('userSettings', 'CurrencyID', data['DefaultCurrencyID']);
              this._http.getLocalIpDetail().subscribe(
                // tslint:disable-next-line:no-shadowed-variable
                (data: any[]) => { this.globalService.overwriteSession('userSettings', 'CountryCode', data['country']); }
              );
              translate.setDefaultLang(data['DefaultLanguageCode']);
            } else {
              const userSettings = JSON.parse(sessionStorage.getItem('userSettings'));
              translate.setDefaultLang(userSettings.LanguageCode);
              // this.isPrivate = false;
            }
            this.isWebshopReaady = true;
          }
        }
      );
    }
    // tslint:disable-next-line:one-line
    else {
      
      let ua = window.navigator.userAgent;
      let isIE = /MSIE|Trident/.test(ua);

      if (isIE) {
        //IE specific code goes here
        const data = JSON.parse(sessionStorage.getItem('webshopDetail'));

        this.isPrivate = true;
        if (data.isPrivate === 'False') {
          this.isPrivate = false;
        }
        const userSettings = JSON.parse(sessionStorage.getItem('userSettings'));
        if (JSON.parse(sessionStorage.getItem('userSettings')) === null) {
          this.globalService.overwriteSession('userSettings', 'LanguageCode', data.DefaultLanguageCode);
          this.globalService.overwriteSession('userSettings', 'LanguageID', data.DefaultLanguageID);
          this.globalService.overwriteSession('userSettings', 'CurrencyCode', data.DefaultCurrencyCode);
          this.globalService.overwriteSession('userSettings', 'CurrencyID', data.DefaultCurrencyID);
          this._http.getLocalIpDetail().subscribe(
            // tslint:disable-next-line:no-shadowed-variable
            (data: any[]) => { this.globalService.overwriteSession('userSettings', 'CountryCode', data['country']); }
          );
          translate.setDefaultLang(data.DefaultLanguageCode);
          this.isWebshopReaady = true;
          const userLogin = JSON.parse(sessionStorage.getItem('loggedInData'))
          if (userLogin !== null) {
            this.globalService.overwriteSession('userSettings', 'LanguageCode', userLogin.UserLanguageCode);
            this.globalService.overwriteSession('userSettings', 'LanguageID', userLogin.LanguageID);
            this.globalService.overwriteSession('userSettings', 'CurrencyCode', userLogin.UserCurrencyCode);
            this.globalService.overwriteSession('userSettings', 'CurrencyID', userLogin.CurrencyID);
            this._http.getLocalIpDetail().subscribe(
              // tslint:disable-next-line:no-shadowed-variable
              (data: any[]) => { this.globalService.overwriteSession('userSettings', 'CountryCode', data['country']); }
            );
            translate.setDefaultLang(userLogin.UserLanguageCode);
          }
        } else {
          const userLogin = JSON.parse(sessionStorage.getItem('loggedInData'))
          if (userLogin !== null) {
            this.globalService.overwriteSession('userSettings', 'LanguageCode', userLogin.UserLanguageCode);
            this.globalService.overwriteSession('userSettings', 'LanguageID', userLogin.LanguageID);
            this.globalService.overwriteSession('userSettings', 'CurrencyCode', userLogin.UserCurrencyCode);
            this.globalService.overwriteSession('userSettings', 'CurrencyID', userLogin.CurrencyID);
            this._http.getLocalIpDetail().subscribe(
              // tslint:disable-next-line:no-shadowed-variable
              (data: any[]) => { this.globalService.overwriteSession('userSettings', 'CountryCode', data['country']); }
            );
            translate.setDefaultLang(userLogin.UserLanguageCode);
          }
          this.bodyVar.style.setProperty('--baseColor', data.Themes[0].Main);
          this.bodyVar.style.setProperty('--labelColor', data.Themes[0].Lable);
          this.bodyVar.style.setProperty('--alertColor', data.Themes[0].Alert);
          this.isWebshopReaady = true;
          translate.setDefaultLang(userSettings.LanguageCode);
        }
      }
      else {
        let loggedData = JSON.parse(sessionStorage.getItem('loggedInData'));
        let webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
        if(webshop){
          if(webshop.MaintenanceScreen){
            this.isWebshopReaady = true;
            this.underMaintenance = true;
          }else{
            if (loggedData !== null && loggedData !== undefined) {
              // data.DefaultLanguageCode = loggedData.UserLanguageCode;
              this._http.getUserActiveStatus(loggedData).subscribe((userData: any) => {
                sessionStorage.setItem('loggedInData', JSON.stringify(userData));
                this.loadTemplate(userData)
              },
                (_error) => {
                  //console.log(error)
                  this.loadTemplate()
                })
            }
            else {
              this.loadTemplate()
            }
          }
        }
       
      }
      if (JSON.parse(sessionStorage.getItem('loggedInData')) !== null) {
        this.isPrivate = false;
      }
      this.loggedInData = JSON.parse(sessionStorage.getItem('loggedInData'));
      this._http.getAllBillBoards().subscribe((data: any[]) => {
        // this.billBoardList = data.ItemsCollection;
        this.billBoardList = data['ItemsCollection'];
        sessionStorage.setItem('billBoardList', JSON.stringify(data['ItemsCollection']))
    });
    }
    if(sessionStorage.getItem('swimport') && sessionStorage.getItem('swimport') === 'yes'){
      sessionStorage.removeItem('swimport');
      setTimeout(() => {
          this.router.navigate(['/user-imports'],{state : {hrm:true}})
      }, 100);
    }
       

  }

  loadTemplate(userData?) {
    let data: any = JSON.parse(sessionStorage.getItem('webshopDetail'));
    let getLogged = JSON.parse(sessionStorage.getItem('loggedInData'));
    if (userData) {
      data.DefaultLanguageCode = userData.UserLanguageCode;
      data.DefaultLanguageID = userData.LanguageID;
      getLogged.LanguageID = userData.LanguageID;
      getLogged.UserLanguageCode = userData.UserLanguageCode;
      sessionStorage.setItem('loggedInData', JSON.stringify(getLogged));
      this.globalService.loggedin(getLogged);

      let userSettingObj: any = {
        "LanguageCode": userData.UserLanguageCode,
        "LanguageID": userData.LanguageID
      }
      sessionStorage.setItem('webshopDetail', JSON.stringify(data));
      sessionStorage.setItem('oldDefaultLanguage', userData.LanguageID);
      this.translate.setDefaultLang(userData.UserLanguageCode);
      this.globalService.overwriteSession('userSettings', 'LanguageCode', userData.UserLanguageCode);
      this.globalService.overwriteSession('userSettings', 'LanguageID', userData.UserLanguageCode);
    }

    if (data) {
      const webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));

      if (webshop.ParentCustomerDomainName === webshop.DomainName) {
        this.isParentCustomer = true;
        this.title = 'Parent Shop';
      } else {
        if(webshop.DomainName !== "voucherkart"){
          this.title = 'Child Shop';
          data.DefaultCurrencyCode = 'Reward Points';
          data.DefaultCurrencyID = 20;
          this.globalService.overwriteSession('userSettings', 'CurrencyCode', 'Reward Points');
          this.globalService.overwriteSession('userSettings', 'CurrencyID', 20);
        }else{
          this.isVoucherKart = true;
          this.title = 'gift1to1';

          //if live build this.title = 'gift1to1'
        }
      }
      
      this.titleService.setTitle(this.title);
    }

    // data.DefaultLanguageCode = 'en';
    const oldDefaultLanguage = sessionStorage.getItem('oldDefaultLanguage');
    const getuserSettings = JSON.parse(sessionStorage.getItem('userSettings'));
    let availableLang = data.Languages;
    if (getuserSettings !== null && getuserSettings !== undefined) {
      let checkavailableLang = availableLang.filter(res => res.LanguageID === getuserSettings.LanguageID);
      if (checkavailableLang.length === 0) {
        this.globalService.overwriteSession('userSettings', 'LanguageCode', data.DefaultLanguageCode);
        this.globalService.overwriteSession('userSettings', 'LanguageID', data.DefaultLanguageID);
        this.globalService.overwriteSession('userSettings', 'CurrencyCode', data.DefaultCurrencyCode);
        this.globalService.overwriteSession('userSettings', 'CurrencyID', data.DefaultCurrencyID);
        this.translate.setDefaultLang(data.DefaultLanguageCode);
      }
    }
    else {
      this.globalService.overwriteSession('userSettings', 'LanguageCode', data.DefaultLanguageCode);
      this.globalService.overwriteSession('userSettings', 'LanguageID', data.DefaultLanguageID);
      this.globalService.overwriteSession('userSettings', 'CurrencyCode', data.DefaultCurrencyCode);
      this.globalService.overwriteSession('userSettings', 'CurrencyID', data.DefaultCurrencyID);
      this.translate.setDefaultLang(data.DefaultLanguageCode);
    }

    if (Number(oldDefaultLanguage) !== data.DefaultLanguageID) {
      this.globalService.overwriteSession('userSettings', 'LanguageCode', data.DefaultLanguageCode);
      this.globalService.overwriteSession('userSettings', 'LanguageID', data.DefaultLanguageID);
      this.globalService.overwriteSession('userSettings', 'CurrencyCode', data.DefaultCurrencyCode);
      this.globalService.overwriteSession('userSettings', 'CurrencyID', data.DefaultCurrencyID);
      this.translate.setDefaultLang(data.DefaultLanguageCode);
    }

    this.isPrivate = true;
    if (data.isPrivate === 'False') {
      this.isPrivate = false;
    }
    const userSettings = JSON.parse(sessionStorage.getItem('userSettings'));
    if (JSON.parse(sessionStorage.getItem('userSettings')) === null) {
      this.globalService.overwriteSession('userSettings', 'LanguageCode', data.DefaultLanguageCode);
      this.globalService.overwriteSession('userSettings', 'LanguageID', data.DefaultLanguageID);
      this.globalService.overwriteSession('userSettings', 'CurrencyCode', data.DefaultCurrencyCode);
      this.globalService.overwriteSession('userSettings', 'CurrencyID', data.DefaultCurrencyID);

      this._http.getLocalIpDetail().subscribe(
        // tslint:disable-next-line:no-shadowed-variable
        (data: any[]) => { this.globalService.overwriteSession('userSettings', 'CountryCode', data['country']); }
      );
      this.isWebshopReaady = true;
      const userLogin = JSON.parse(sessionStorage.getItem('loggedInData'))
      if (userData !== null) {
        this.globalService.overwriteSession('userSettings', 'LanguageCode', userData.UserLanguageCode);
        this.globalService.overwriteSession('userSettings', 'LanguageID', userData.LanguageID);
        this.globalService.overwriteSession('userSettings', 'CurrencyCode', userLogin.UserCurrencyCode);
        this.globalService.overwriteSession('userSettings', 'CurrencyID', userLogin.CurrencyID);
        this.isPrivate = false;
        this._http.getLocalIpDetail().subscribe(
          // tslint:disable-next-line:no-shadowed-variable
          (data: any[]) => { this.globalService.overwriteSession('userSettings', 'CountryCode', data['country']); }
        );
        this.translate.setDefaultLang(userData.UserLanguageCode);
      } else {
        this.translate.setDefaultLang(data.DefaultLanguageCode);
      }
    } else {
      let userLogin = JSON.parse(sessionStorage.getItem('loggedInData'))
      if (userLogin !== null) {
        const webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
        if (!this.isParentCustomer && !this.isVoucherKart) {
          userLogin.UserCurrencyCode = 'Reward Points';
          userLogin.CurrencyID = 20;
        }

        this.globalService.overwriteSession('userSettings', 'LanguageCode', userData.UserLanguageCode);
        this.globalService.overwriteSession('userSettings', 'LanguageID', userData.LanguageID);
        this.globalService.overwriteSession('userSettings', 'CurrencyCode', userLogin.UserCurrencyCode);
        this.globalService.overwriteSession('userSettings', 'CurrencyID', userLogin.CurrencyID);
        this.isPrivate = false;
        this._http.getLocalIpDetail().subscribe(
          // tslint:disable-next-line:no-shadowed-variable
          (data: any[]) => { this.globalService.overwriteSession('userSettings', 'CountryCode', data['country']); }
        );
        this.translate.setDefaultLang(userData.UserLanguageCode);
      }


      this.bodyVar.style.setProperty('--baseColor', data.Themes[0].Main);
      this.bodyVar.style.setProperty('--labelColor', data.Themes[0].Lable);
      this.bodyVar.style.setProperty('--alertColor', data.Themes[0].Alert);
      this.isWebshopReaady = true;
      this.translate.setDefaultLang(userSettings.LanguageCode);
    }

  }

  ngOnDestroy() {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  ngOnInit() {
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( _evt => {
        if (window.innerWidth > 768) {
           this.isMobile = false;
        }else{
          this.isMobile = true;
        }
    })
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      setTimeout(() => {
        this.webShopSettings = this.globalService.webShopSettings();
        if (this.webShopSettings && evt instanceof NavigationEnd) {
          this.filterEnableStatus = (this.webShopSettings.ShowFiltersOnHomePage) ? true : false;
          if ((evt.url === '/' || evt.url.indexOf('/?') > -1) || (evt.url.indexOf('search/') > -1)) {
            this.isHomePage = true;
          } else {
            this.isHomePage = false;
          }
          if (this.isHomePage) {
            if (!this.filterEnableStatus) {
              /** Filters Always enable for Search page */
              if (window.location.href.indexOf('search/') > -1 && (this.webShopSettings.ShowAdvancedSearchBranding || this.webShopSettings.ShowAdvancedSearchPrices || this.webShopSettings.ShowSupplierRating || this.webShopSettings.ShowAdvancedSearchColors || this.webShopSettings.ShowSortByFilter)) {
                this.filterEnableStatus = true;
              } else {
                this.filterEnableStatus = false;
              }
            }
          }

          this.router.navigated = false;
          this.urlParams = evt.url.split('/');
          let prePages = JSON.parse(sessionStorage.getItem('prePages'));
          let breadCrumbs = JSON.parse(sessionStorage.getItem('breadCrumbsList'));
          if (prePages === null || prePages === undefined) {
            prePages = [];
            breadCrumbs = [];
            prePages.push({ 'group': 'home', 'url': evt.url });
            breadCrumbs.push({ 'group': 'home', 'url': '/' });
            sessionStorage.setItem('currentPageIndex', '0');
            sessionStorage.setItem('prePages', JSON.stringify(prePages));
            sessionStorage.setItem('breadCrumbsList', JSON.stringify(breadCrumbs));
          } else if (prePages && prePages !== undefined && prePages[prePages.length - 1]['group'] !== this.urlParams[1]
            && this.urlParams[1] !== 'productlikes' && this.urlParams[1] !== 'productdetail') {
            sessionStorage.setItem('currentPageIndex', prePages.length.toString());
            if (this.urlParams[1] === '') {
              this.urlParams[1] = 'home';
            }
            prePages.push({ 'group': this.urlParams[1], 'url': (evt.url) });
            sessionStorage.setItem('prePages', JSON.stringify(prePages));
            //(prePages[prePages.length - 1]['group'] !== this.titleService.getTitle()) ||
            if ((prePages[prePages.length - 1]['group'] !== decodeURI(this.urlParams[3]))) {
              if (this.urlParams[1] === 'search?likedProducts=true') {
                evt.url = 'productlikes';
                this.urlParams[1] = 'productlikes'
              }
              if (this.urlParams[1] === 'search?joinlinkorders=true') {
                this.urlParams[1] = 'joinlinkorders';
              }
              if (this.urlParams[1] === 'productblocksorders') {
                this.urlParams[1] = sessionStorage.getItem('ProductBlockName');
              }

              // new pushing function for category name
              if ((this.urlParams[1].indexOf('?') > 0)) {
                const advancedSearch = this.urlParams[1].split('&');
                // tslint:disable-next-line:max-line-length
                if (advancedSearch[0].indexOf('CategoryID') > 0 || advancedSearch[0].indexOf('ProductName') > 0 || advancedSearch[0].indexOf('Quantity') > 0 ||
                  advancedSearch[0].indexOf('ShowPricesIncludingLogoType') > 0 || advancedSearch[0].indexOf('SubCategory') > 0 || advancedSearch[0].indexOf('isTagsFilterExists') > 0) {
                  this.urlParams[1] = 'Search results';
                } else if (advancedSearch[0].indexOf('FolderID') > 0) {
                  this.urlParams[1] = 'folders';
                }
              }
              breadCrumbs.push({ 'group': this.urlParams[1], 'url': (evt.url) });
              sessionStorage.setItem('breadCrumbsList', JSON.stringify(breadCrumbs));
            }
          }
          if (this.urlParams[1] === 'search' && this.urlParams[3] && (prePages[prePages.length - 1]['group'] !== decodeURI(this.urlParams[3]))) {
            // if (urlParams[3].indexOf('26') > 0) {
            //   urlParams[3] = urlParams[3].replace('%2526', '&');
            // }
            const categoryName = JSON.parse(sessionStorage.getItem('breadCrumbCategoryName'));
            breadCrumbs.push({ 'group': decodeURI(categoryName), 'url': decodeURI(evt.url) });
            sessionStorage.setItem('breadCrumbsList', JSON.stringify(breadCrumbs));
            prePages.push({ 'group': this.urlParams[1], 'url': decodeURI(evt.url) });
            sessionStorage.setItem('prePages', JSON.stringify(prePages));
          }
        }
      }, 100);
    });

    this._subscriptions.push(
      // save or restore scroll position on route change
      this.router.events.pairwise().subscribe(([prevRouteEvent, currRouteEvent]) => {
        if (prevRouteEvent instanceof NavigationEnd && currRouteEvent instanceof NavigationStart) {
          this._routeScrollPositions[prevRouteEvent.url] = window.pageYOffset;
        }
        if (currRouteEvent instanceof NavigationEnd) {
          window.scrollTo(0, this._routeScrollPositions[currRouteEvent.url] || 0);
        }
      })
    );
    if(this.webShopSettings.DomainName !== "sw" && this.webShopSettings.DomainName !== "smartworks"){
      this.getFooterLinks();
    }
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    this._subscriptions.push(
      // save or restore scroll position on route change
      this.router.events.pairwise().subscribe(([prevRouteEvent, currRouteEvent]) => {
        if (prevRouteEvent instanceof NavigationEnd && currRouteEvent instanceof NavigationStart) {
          this._routeScrollPositions[prevRouteEvent.url] = window.pageYOffset;
        }
        if (currRouteEvent instanceof NavigationEnd) {
          window.scrollTo(0, this._routeScrollPositions[currRouteEvent.url] || 0);
        }
      })
    );
  }
  @HostListener('document:mousewheel', ['$event'])
  onMouseWheel() {
    if (window.scrollY > 1200) {
      this.isShowArrow = true;
    } else {
      this.isShowArrow = false;
    }
  }
  goTop() {
    window.scrollTo(0, 0);
    this.isShowArrow = false;
  }
  @HostListener('click', ['$event.target']) onClick(btn) {
    if (btn.className === 'prodetailOverlay' || btn.className === 'headerContent' ||
      btn.className === 'prodetailOverlay ng-star-inserted' || btn.className === 'logo mh-100') {
      this._httpProductService.changeShowDetail(false);
    }
    if (btn.className === 'prodetailOverlays') {
      this.router.navigate(['/']);
    }
  }
  getFooterLinks() {
    this._http.getFooterLinks().subscribe(
      data => {
        this.footerLinksList = data['ItemsCollection'];
        // console.log("footer", this.footerLinksList);
      }
    );
  }

  signup(event){
    if(event === 'signup'){
      this.landingPage = false;
    }
    if(event === 'enquiryform'){
      this.landingPage = false;
      this.enquiryForm = true;
    }
  }
}
