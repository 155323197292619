import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastService } from '../../typescripts/pro';
import { GlobalService } from '../globals/global.service';
import { WebshopService } from '../restful-services/restful.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'shop-app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss'],
  exportAs: 'child'
})

export class TermsConditionComponent implements OnInit {
  @ViewChild('termsConditionModal') public termsConditionModal;
  public userSettings: any;
  public termsConditionPdfFileName: any;
  domainName = null;
  constructor(private sanitizer: DomSanitizer, public formBuilder: FormBuilder, public toastService: ToastService, public globalService: GlobalService,
    public _httpWebshopService: WebshopService, public translate: TranslateService) {
    this.userSettings = this.globalService.getUserSettings();
    let domains = this._httpWebshopService.getSubdomain(window.location.hostname);
    domains = domains ? domains : 'voucherkart';
    let path = environment.ENVIRONMENT.fileserver+'/UserDocuments/';
    this.domainName = this.sanitizer.bypassSecurityTrustResourceUrl(path+'terms_and_conditions_1.pdf');
  }

  ngOnInit() {
  }

  showTermsConditionModal() {
    this.termsConditionModal.show();
  }
}
