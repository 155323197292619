import { Component, OnInit, ViewChild, Input, ChangeDetectionStrategy} from "@angular/core";
import { GlobalService } from "../../linknet-common/globals/global.service";
import { WebshopService } from "app/linknet-common/restful-services/restful.service";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ValidationService } from "../../linknet-common/validators/index";
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from "../../typescripts/pro";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "shop-app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  public paymentType: Array<any>;
  public webShopSettings: any;
  public rewardkartStatus: boolean = false;
  public goldenberryStatus: boolean = false;
  public userSettings: any;
  @ViewChild("PartnerInfoModal") public partnerInfoModal;
  @ViewChild("RewardkartNewsletterModal") public rewardkartNewsletterModal;
  @ViewChild("ContactUsModal") public contactUsModal;
  @ViewChild("UserDeactiveModal") public userDeactiveModal;
  @Input() footerLinksList: any;
  @Input() isHomePage: Boolean;
  @Input() filterEnableStatus: Boolean;
  public newsForms: any;
  public translatedMessage: any;
  public isShowFooter = true;
  isLoaded: boolean;
  public contactusForm: any;
  public postParams: any;
  public isParentCustomer: boolean = false;
  isSmartworks: boolean = false;
  isHrm: boolean = false;
  isVoucherKart: boolean = false;
  isCorpKonnex: boolean = false;
  isGrowWell: boolean = false;

  constructor(
    private router: Router,
    public formBuilder: FormBuilder,
    public _httpWebshopService: WebshopService,
    private globalService: GlobalService,
    public translate: TranslateService,
    public toastService: ToastService,
    private toastr: ToastrService
  ) { 
    const webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
      if (webshopDetail.ParentCustomerDomainName === webshopDetail.DomainName ) {
        this.isParentCustomer = true;
      } else {
        this.isParentCustomer = false;
        if(webshopDetail.DomainName === "voucherkart"){
          this.isVoucherKart = true;
        }
      }

      if(webshopDetail.DomainName === "sw" || webshopDetail.DomainName === "smartworks"){
        this.isSmartworks = true;
      }
      if(webshopDetail.DomainName === "247hrm" || webshopDetail.DomainName === "247hrm"){
        this.isHrm = true;
      }

      if(webshopDetail.DomainName === "corpkonnex" || webshopDetail.DomainName === "ckonnex"){
        this.isCorpKonnex = true;
      }
      if(webshopDetail.DomainName === "growmore" || webshopDetail.DomainName === "gm"){
        this.isGrowWell = true;
      }
  }

  ngOnInit() {
    this.contactusForm = this.formBuilder.group({
      'UserName': ['', [Validators.required, this.noWhitespaceValidator]],
      'CompanyName': ['', [Validators.required, this.noWhitespaceValidator]],
      'Email': ['', [Validators.required, ValidationService.emailValidator]],
      'ProductDescription': ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
      'Comment': [''],
    });
    // let domainName = this._httpWebshopService.getSubdomain(window.location.hostname);
    // domainName = domainName ? domainName : 'voucherkart';
    // this.folderID = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.FolderID : null;

    const domainName = JSON.parse(sessionStorage.getItem("webshopDetail"));
    if (Number(domainName.CustomerID) === 1) {
      this.rewardkartStatus = true;
    }

    if (domainName.ParentCustomerDomainName === "goldenberry") {
      this.goldenberryStatus = true;
    }

    /** To Hide footer Section for INGRAM MICRO Child Webshop */
    if (domainName.Name === "Ingram Micro" || domainName.Name === "INGRAM MICRO") {
      this.isShowFooter = false;
    }
    this.webShopSettings = this.globalService.webShopSettings();    
    this.userSettings = this.globalService.getUserSettings();
    this.paymentType = this.webShopSettings.PaymentTypes;


    this.newsForms = this.formBuilder.group({
      Name: ["", [Validators.required]],
      Email: ["", [Validators.required, ValidationService.emailValidator]],
    });
    /** Based on Newsletter Append in URL then need to display Newletter Popup */
    if (window.location.href.indexOf("#newsletter") > -1 && window.location.href.indexOf('voucherkart') === -1) {
      setTimeout(() => {
        if (sessionStorage.getItem("newsLetterShow") === null) {
          this.rewardkartNewsletterModal.show();
          sessionStorage.setItem("newsLetterShow", "1");
        }
      }, 100);
    } else {
      this.rewardkartNewsletterModal.hide();
    }

    /** Based on User Activate/DeActivate Status Popup */
    if (window.location.href.indexOf("userdeactive") > -1) {
      this.router.navigate(["/"], { replaceUrl: false });
      setTimeout(() => {
        this.userDeactiveModal.show();
      }, 100);
    } else {
      this.userDeactiveModal.hide();
    }
  }

  ngOnChanges(): void { }

  // tslint:disable-next-line:eofline
  goTop() {
    window.scrollTo(0, 0);
  }

  redirectLink(status) {
    if (status === "F") {
      window.open(this.webShopSettings.LinkFacebook);
    } else if (status === "T") {
      window.open(this.webShopSettings.LinkTwitter);
    } else if (status === "L") {
      window.open(this.webShopSettings.LinkLinkedin);
    } else if(status === "I") {
      window.open(this.webShopSettings.LinkInstagram);
    }else{

    }
  }

  showPartnerInfoModal() {
    this.partnerInfoModal.show();
  }

  showRewardkartNewsletterModal() {
    this.rewardkartNewsletterModal.show();
  }

  newsLetterSubscription() {
    try {
      const postParams = {
        Name: this.newsForms.value.Name,
        Email: this.newsForms.value.Email,
      };
      this._httpWebshopService.getRewardkartNewsSubscription(postParams).subscribe(
        (_data: any[]) => { },
        (_error) => {
          this.rewardkartNewsletterModal.hide();
          this.translate.get("SETTINGS.NEW_SUBSCRIPTION").subscribe((res: string) => {
              this.translatedMessage = res;
            });
          this.toastr.success(this.translatedMessage);
          this.newsForms = this.formBuilder.group({
            Name: ["", [Validators.required]],
            Email: ["", [Validators.required, ValidationService.emailValidator]],
          });
        }
      );
    } catch (error) {
      this.toastr.error(error);
    }
  }

  showContactModal(e: any, footerDescription: String) {
    if (footerDescription == 'Contact Us' || footerDescription == 'Contact') {
      e.preventDefault();
      this.contactUsModal.show();
    }

  }

  keyPress(event: any) {
    const pattern = new RegExp("^[0-9\b]+$");
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!pattern.test(key) && event.charCode !== 0) {
      event.preventDefault();
      return false;
    }
  }

  cancel() {
    this.contactUsModal.hide();
    //this.contactusForm.reset();
  }

  sendInquiry() {
    try {
        this.postParams = {
          'Email': this.contactusForm.value.Email,
          'UserName': this.contactusForm.value.UserName,
          'CompanyName': this.contactusForm.value.CompanyName,
          'Comment': this.contactusForm.value.Comment,
          'ProductDescription':this.contactusForm.value.ProductDescription,

      }
      Object.keys(this.postParams).forEach((key) => (this.postParams[key] === '') && (this.postParams[key] === null)
        && (this.postParams[key] === undefined) && delete this.postParams[key]);
      this._httpWebshopService.sendInquiry(this.postParams).subscribe(
        _data => {
          this.translate.get('SETTINGS.INQUIRY_SUCCESS').subscribe((res: string) => {
            this.translatedMessage = res;
          });
          this.toastr.success("Contact us form submitted successfully..!!");
          this.cancel();
          this.contactusForm.reset();
        },
        error => {
          this.toastr.error(error.error.Message);
        });
    } catch (error) {
      this.toastr.error(error);
    }
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}
