import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ToastService } from "../../../typescripts/pro";
import { ValidationService } from "../../../linknet-common/validators/index";
import { GlobalService } from "../../../linknet-common/globals/global.service";
import { WebshopService } from "../../../linknet-common/restful-services/restful.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "shop-app-news-letter",
  templateUrl: "./news-letter.component.html",
  styleUrls: ["./news-letter.component.scss"],
  exportAs: "child",
})
export class NewsLetterComponent implements OnInit {
  @ViewChild("newsLetterModal") public newsLetterModal;
  public newsForm: any;
  public translatedMessage: any;
  public getLetterSubscription: any;
  constructor(
    public formBuilder: FormBuilder,
    public toastService: ToastService,
    public globalService: GlobalService,
    public _httpWebshopService: WebshopService,
    public translate: TranslateService,
    private toastr: ToastrService
  ) {
    try {
      this.newsForm = this.formBuilder.group({
        Name: ["", [Validators.required]],
        CompanyName: ["", [Validators.required]],
        Email: ["", [Validators.required, ValidationService.emailValidator]],
      });
      const userDetails = JSON.parse(sessionStorage.getItem("loggedInData"));
      if (userDetails !== null) {
        this.newsForm.patchValue(
          {
            Name: userDetails.Name,
            CompanyName: userDetails.CompanyName,
            Email: userDetails.Email,
          },
          { onlySelf: false, emitEvent: false }
        );
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  ngOnInit() {
    /** Based on Newsletter Append in URL then need to display Newletter Popup */
    if (
      window.location.href.indexOf("#newsletter") > -1 &&
      window.location.href.indexOf('voucherkart') !== -1
    ) {
      setTimeout(() => {
        if (sessionStorage.getItem("newsLetterShow") === null) {
          this.newsLetterModal.show();
          sessionStorage.setItem("newsLetterShow", "1");
        }
      }, 100);
    } else {
      this.newsLetterModal.hide();
    }
  }

  showNewsLetterModal() {
    const userDetails = JSON.parse(sessionStorage.getItem("loggedInData"));
    if (userDetails !== null) {
      this.newsForm.patchValue(
        {
          Name: userDetails.Name,
          CompanyName: userDetails.CompanyName,
          Email: userDetails.Email,
        },
        { onlySelf: false, emitEvent: false }
      );
    }
    this.newsLetterModal.show();
    // tslint:disable-next-line:quotemark
    setTimeout(function () {
      document.getElementsByName("Name")[0].focus();
    }, 1000);
  }

  newsLetterSubscription() {
    try {
      const webShopSettings = this.globalService.webShopSettings();
      const postParams = {
        Name: this.newsForm.value.Name,
        CompanyName: this.newsForm.value.CompanyName,
        CustomerID: webShopSettings.CustomerID,
        Email: this.newsForm.value.Email,
      };
      this._httpWebshopService.getNewsSubscription(postParams).subscribe(
        (data: any[]) => {
          this.getLetterSubscription = data;
          //  this.translatedMessage = (this.translate.get('SETTINGS.NEW_SUBSCRIPTION'));
          this.translate
            .get("SETTINGS.NEW_SUBSCRIPTION")
            .subscribe((res: string) => {
              this.translatedMessage = res;
            });
          this.toastr.success(this.translatedMessage);
          this.newsForm = this.formBuilder.group({
            Name: ["", [Validators.required]],
            CompanyName: ["", [Validators.required]],
            Email: [
              "",
              [Validators.required, ValidationService.emailValidator],
            ],
          });
          this.newsLetterModal.hide();
        },
        (error) => {
          // this.toastService.error(JSON.parse(error._body).Message);
          this.toastr.error(error.error.Message);
        }
      );
    } catch (error) {
      this.toastr.error(error);
    }
  }
}
