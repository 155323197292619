import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastService } from '../../typescripts/pro';
import { GlobalService } from '../globals/global.service';
import { WebshopService } from '../restful-services/restful.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'shop-app-user-data-policy',
  templateUrl: './user-data-policy.component.html',
  styleUrls: ['./user-data-policy.component.scss'],
  exportAs: 'child'
})

export class UserDataPolicyComponent implements OnInit {
  @ViewChild('userDataPolicyModal') public userDataPolicyModal;
  public userSettings: any;
  public userDataPolicyPdfFileName: any;
  domainName = null;
  heading;
  isLoading: boolean = false;
  constructor(public formBuilder: FormBuilder, public toastService: ToastService, public globalService: GlobalService,
    public _httpWebshopService: WebshopService, public translate: TranslateService, private sanitizer: DomSanitizer,private httpClient: HttpClient, private router: Router) {
    this.userSettings = this.globalService.getUserSettings();
  }

  ngOnInit() {
   
  }

  showUserDataPolicyModal(title) {
    this.heading = title;
    let domains = this._httpWebshopService.getSubdomain(window.location.hostname);
    domains = domains ? domains : 'voucherkart';
    //this.domainName = null;
    let path = environment.ENVIRONMENT.fileserver+'/UserDocuments/';

    if(title === 'About The Program' || title === 'About Us'){
      this.isLoading = true;
      if(domains === 'rgipep'){
        this.domainName = this.sanitizer.bypassSecurityTrustResourceUrl(path + domains + '_aboutus.pdf');
      }else{
        this.domainName = this.sanitizer.bypassSecurityTrustResourceUrl(path+'about_us.pdf');
      }
    }
    else if(title === 'Terms and Conditions'){
      this.isLoading = true;
      this.domainName = this.sanitizer.bypassSecurityTrustResourceUrl(path+'terms_and_conditions_1.pdf');
    }
    else if(title === 'Pricay Notice To End Users'){
      this.isLoading = true;
      if(domains === 'rgipep'){
        this.domainName = this.sanitizer.bypassSecurityTrustResourceUrl(path + domains + '_privacy_notice.pdf');
      }else{
        this.domainName = this.sanitizer.bypassSecurityTrustResourceUrl(path+'privacy_policy.pdf');
      }
    }
    else if(title === 'Return and Refund Policy'){
      this.isLoading = true;
      this.domainName = this.sanitizer.bypassSecurityTrustResourceUrl(path+'return_and_refund_policy.pdf');
    }
    else if(title === 'FAQs'){
      this.isLoading = true;
      if(domains === "rgipep" || domains === "smartworks" || domains === "sw"){
        this.domainName = this.sanitizer.bypassSecurityTrustResourceUrl(path + domains + '_faq.pdf');
      }else{
        this.domainName = this.sanitizer.bypassSecurityTrustResourceUrl(path+'faqs.pdf');
      }
    }
    else if(title === 'User Guide'){
      this.isLoading = true;
      if(domains === 'rgipep'){
        this.domainName = this.sanitizer.bypassSecurityTrustResourceUrl(path + domains + '_user_guide.pdf');
      }else{
        this.domainName = this.sanitizer.bypassSecurityTrustResourceUrl(path+'rewardkart_user_guide.pdf');
      }
    }
    else{
      this.domainName = this.sanitizer.bypassSecurityTrustResourceUrl(path+'data_protection_policy.pdf');
    }
    this.userDataPolicyModal.show();
  }

  fileExists(path){
    return this.httpClient.get(path, { observe: 'response', responseType: 'blob' }).pipe(map(() => true), catchError(() => of(false)));
  }
}