import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "../../../linknet-common/globals/global.service";
import { WebshopService } from 'app/linknet-common/restful-services/restful.service';
import { ToastrService } from "ngx-toastr";
import { environment } from "environments/environment";

@Component({
    selector: "shop-app-imported-user",
    templateUrl: "./user-list.component.html",
    styleUrls: ["./user-list.component.scss"],
})

export class ImportedUserComponent implements OnInit{
    userList: any;
    userLen: any;
    @ViewChild("userModal") public userModal;
    @ViewChild("userError") public userError;
    @ViewChild('payFail') public payFail;
    @ViewChild("paymentModal") public paymentModal;
    creationError: any;
    loaderShow: boolean = false;
    CID: any;
    hidePayment: boolean = false;
    file: any;
    paymentData: any;
    discountValue: any;
    paymentTypeID: any;
    paymentTypes: any;
    transactionID: any;
    atomID: any;
    bankRefNo: any;
    status: string;
    paymentStatus: any;
    smartWorks: boolean = false;
    hrm: boolean = false;
    campaignID:any;
    constructor(public globalService: GlobalService, public router: Router, public _httpWebservice: WebshopService, private toastr: ToastrService){
      let webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if(webshop.DomainName === "sw" || webshop.DomainName === "smartworks"){
        this.smartWorks = true
      }
      else if(webshop.DomainName === "247hrm" || webshop.DomainName === "247hrm"){
        this.hrm = true
      }
        if(history.state.data){
            this.userList = history.state.data.excelFile;
            this.file = history.state.data.file;
            this.paymentData = history.state.data.paymentData;
            this.transactionID = this.paymentData.TransId;
            sessionStorage.setItem('subscriptionid', this.transactionID);
            this.discountValue = (this.paymentData.TotalAmount  * this.paymentData.DiscountPercenate)/100;
            if(this.paymentData.DiscountPercenate){
                this.paymentData.DiscountPercenate = this.paymentData.DiscountPercenate.toFixed(2);
                this.discountValue = Math.round(this.discountValue);
            }
        }
       else if(sessionStorage.getItem('userlist')){
            this.userList = JSON.parse(sessionStorage.getItem('userlist'));
            sessionStorage.removeItem('userlist')
        }
        if(!this.userList){
            this.router.navigate(['/user-imports'])
        }else{
            this.userLen = this.userList.length;
        }

    }
    ngOnInit() {
        this.CID = this.router.routerState.snapshot.url;
        if (this.CID.indexOf("/import-user-list") !== -1) {
            let fullurl = window.location.href;
        
            let swpay = JSON.parse(sessionStorage.getItem('swpay'));
            let status = this.getParameterByName('Status', fullurl);
            let message = this.getParameterByName('Message', fullurl);
            let bankRefNo = this.getParameterByName('BankTrans', fullurl);
            let atomID = this.getParameterByName('AtomTrans', fullurl);
            let data = this.getParameterByName('data', fullurl);
            let key = this.getParameterByName('key', fullurl);
            if(atomID){
              this.atomID = atomID;
            }
            if(bankRefNo){
              this.bankRefNo = bankRefNo;
            }
            if (sessionStorage.getItem('subscriptionid')) {
              this.transactionID = sessionStorage.getItem('subscriptionid');
            }
            if (status && swpay) {
                this.status = status;
                if (sessionStorage.getItem('subscriptionid')) {
                  sessionStorage.removeItem('subscriptionid');
                  //this.createUser()
                  if(this.status === 'Y') {
                    this.proceed('Y');
                  }
                }  
            }else{
                if (sessionStorage.getItem('subscriptionid')) {
                    this.transactionID = sessionStorage.getItem('subscriptionid');
                }
            }

            setTimeout(()=>{
                if(this.status && this.status === 'N'){
                  if(!this.atomID || !this.bankRefNo){
                    this.proceed('PG')
                  }
                  else if(message && message === "CHALLAN GENERATED SUCCESSFULY"){
                    this.proceed('PNR')
                  }else{
                    this.proceed('PF')
                  }
                }
              }, 2000)
        }
        this.paymentOptions()
    }

    paymentOptions(){
        this._httpWebservice.availablePaymentTypes().subscribe(data=>{
            this.paymentTypes = data;
            this.paymentTypeID = this.paymentTypes[0].ID;
        })
    }

    paymentSelect(i){
        this.paymentTypeID = i;
    }

    getParameterByName(name, url) {
        //if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&|]" + name + "(=([^&#|]*)|&|#|$)", 'i'),
        results = regex.exec(url);
        if (!results)
          return null;
        if (!results[2])
          return '';
        return results[2].replace(/\+/g, " ");
      }

    proceed(status){
        let webshop = JSON.parse(sessionStorage.getItem("webshopDetail"));
        /*let formData = new FormData();
        formData.append("DomainName", webshop.DomainName);
        formData.append("TransActionId", this.transactionID);*/
        this.paymentStatus = status;
        let user = JSON.parse(sessionStorage.getItem('swdata'))
        let hrmuser = JSON.parse(sessionStorage.getItem("247hrm"))
        let params = {
            "TransId": this.transactionID,
            "PaymentStatus": status,
            "AtomTranId": this.atomID, 
            "BankRefNo": this.bankRefNo,
            "UserId": this.smartWorks ? user.UserID : (this.hrm ? hrmuser.UserID : undefined)
        }
        this.loaderShow = true;
        if(this.smartWorks === true){
          this._httpWebservice.updateswPayment(params).subscribe((_data:any)=>{
            this.loaderShow = false;
            this.userModal.show({
              backdrop: 'static',
              keyboard: false
            });
          }, error => {
            this.loaderShow = false;
            this.toastr.error(error.error.Message);
          })
        }
        if(this.hrm === true){
          this._httpWebservice.updatehrmPayment(params).subscribe((_data:any)=>{
            this.loaderShow = false;
            this.userModal.show({
              backdrop: 'static',
              keyboard: false
            });
          }, error => {
            this.loaderShow = false;
            this.toastr.error(error.error.Message);
          })
        }
    }

    closeModal(){
        this.userError.hide();
        this.router.navigate(['/user-imports']);
    }

    makePayment(){
        this.paymentModal.show({
          backdrop: 'static',
          keyboard: false
        });
        if(this.smartWorks === true){
          this._httpWebservice.userswPayment(this.transactionID, this.paymentTypeID).subscribe(_res =>{
    
          });
        }else if(this.hrm === true){
          this._httpWebservice.userhrmPayment(this.transactionID, this.paymentTypeID).subscribe(_res =>{
    
          });
        }
        
    }

    goToPayemnt(){
        this.paymentModal.hide();
        let logIndata = this.globalService.getLoggedInData();
        let pre;
        if(this.smartWorks === true){
          this.campaignID = 295;
          pre = "SW"
        }else if(this.hrm === true){
          this.campaignID = 433;
          pre = "CRM247"
        }

        let params = {
            "UserId": logIndata.UserID.toString(),
            "Desc": "",
            "ExpairyDate": "",
            "CampaignId": this.campaignID,
            "IsActive": true,
            "Action": "Add",
            "IsPointAdd": true,
            "Email": logIndata.Email,
            "PhoneNumber": logIndata.PhoneNumber,
            "UserName": logIndata.UserName,
            "page": 'checkout'
        }
        
        sessionStorage.setItem('swpay', JSON.stringify(params))
        sessionStorage.setItem('userlist', JSON.stringify(this.userList))
        this._httpWebservice.webTinyUrl().subscribe(data =>{
          let query = "OrderId="+ this.transactionID +"&customerID="+logIndata.UserID+"&IsRewardKart=Y&Domain="+logIndata.DomainName+"&Pre="+pre+"&ReturnURL="+data+"&Email="+logIndata.Email+"&Mobile="+logIndata.PhoneNumber+"&campaignID="+this.campaignID;
          
          const paymentURL = environment.ENVIRONMENT.pbase + 'AtomPayment/AtomPaymentRequest?' + query;
          window.location.href = paymentURL;   
        });
    }

    closeFail(){
        this.payFail.hide();
    }

    inrFormat(val: number) {
        return Number(val).toLocaleString("en-IN");
    }

    

    finishModal(){
        this.userModal.hide();
        setTimeout(()=>{
          this.router.navigate(['/user-imports'])
        },1000)
      }
}