import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { HeaderOneComponent } from "../header-one.component";
import { Router } from "@angular/router";
import { WebshopService, SecurityService, CampaignService, ProductService } from "../../../linknet-common/restful-services/restful.service";
import { FormBuilder } from "@angular/forms";
import { SettingsComponent } from "app/webshop/settings/settings.component";
import { GlobalService, CurrencySetterService, MessageDataService } from "../../../linknet-common/globals/global.service";
import { AppComponent } from "../../../app.component";
import { ToastService } from "app/typescripts/pro/alerts";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "app/linknet-common/restful-services/message-services/message.service";
import { Subscription } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from "ngx-toastr";
import { CommonServicesService } from "app/linknet-common/common-services/common-services.service";
import * as _ from "lodash";
import { data } from "jquery";

@Component({
  selector: "shop-app-menu-right",
  templateUrl: "./menu-right.component.html",
  styleUrls: ["./menu-right.component.scss"],
  exportAs: "child",
})
export class MenuRightComponent implements OnInit {
  selectedCurrencyID: any;
  selectedLanguageCode: any;
  selectedCurrencyCode: any;
  colorIndex: number;
  tabCompletedCount = 1;
  tabCount = 1;
  toastRef: any;
  public availableLanguages: any;
  public availableCurrencies: any;
  public selectedLanguage: any;
  public selectedLanguageFlag: URL;
  public selectedCurrency: any;
  public selectedCurrencySymbol: any;
  public showCurrencySymbol: any;
  public loggedInData: any;
  public type = "";
  public translatedMessage: any;
  public cartParams: any;
  public numberOfUnreadEmails:any;
  private subscription: Subscription;
  private subscription2: Subscription;
  public shoppingCartList: any;
  public currency: any;
  public priceList: any;
  public CID;
  public getShoppingCartIDList: any;
  public cartCount;
  public joinOrderCounts: Number = 0;
  public categorySelect = [];
  public categories = [];
  public colorsList: Array<any>;
  public checkoutDataList = [];
  public checkoutStatus: boolean = false;
  public showBalanceMsg: boolean = false;
  isSmartworks: boolean = false;
  isHrm: boolean = false;
  @ViewChild("productCartModal") public productCartModal;
  @ViewChild("DeleteModal") public deleteModal;
  @ViewChild("DeleteModalOneItem") public deleteModalOneItem;
  @Input() isShoppingCartPage: any;
  @Output() shoppingCarts = new EventEmitter();
  @ViewChild("inquiryModal") public inquiryModal;
  public toggleClass = [];
  public loaderShow: boolean = false;
  public cartCountClass = "";
  totalPrice: any = 0;
  public changeDeliveryDateText: Boolean = false; // for display text as delivery date / Earliest disp. date in HTML Page
  public wsaStatus: Boolean = false;
  getLoggedData: any;
  balancePoints: any = 0;
  tempPoints: any = 0;
  isParentCustomer: boolean = true;
  todatalquality: boolean;
  TotalValUpdateShow: boolean = false;
  deliveryDate: any;
  isExpressOrder: any;
  isSampleWithLogo: any;
  isSampleWithoutLogo: any;
  isProceedWithoutLogo: any;
  isChecked: any;
  isInitialStepCompleted: any;
  isColorStepCompleted: any;
  isSizesStepCompleted: any;
  isAdditionalCostsStepCompleted: any;
  isProductPropertiesStepCompleted: any;
  isLogosStepCompleted: any;
  Comment: any;
  Logos: any;
  Price: any;
  SupplierID: any;
  ShoppingCartItemID: any;
  ProductionLineOrderedQuantity: any;
  isDeliveryTimeCompleted: any;
  ProductIdManual: any;
  ProductName: any;
  ProductUnitName: any;
  ProductImageURL: any;
  MinimumOrder: any;
  DeliveryWeeksFrom: any;
  DeliveryWeeksTo: any;
  PiecesPerUnit: any;
  FixedUnits: any;
  isFixedLogo: any;
  EarliestDeliveryDate: any;
  EarliestDeliveryDateForNumOfDaysTillProductStart: any;
  StockBalance: any;
  VATPercentageToInclude: any;
  ProductColorCodes: any;
  isJoinOrder: any;
  isDeliveryDateCorrected: any;
  isDisabled: any;
  isProductDeleted: any;
  isExpired: any;
  isValid: any;
  isItemCompleted: any;
  ProductImages: any;
  ProductImagesForLogoPositions: any;
  ProductPrices: any;
  ProductPrintPrices: any;
  ProductSizes: any;
  ProductColors: any;
  ProductProperties: any;
  AdditionalCosts: any;
  ProductFutureStock: any;
  ProductLogoPositions: any;
  ProductLogoMaxAreas: any;
  ProductLogoTypes: any;
  IsPickupatWarehouse: any;
  RelatedItemID: any;
  CurrencyCode: any;
  CurrencySymbol: any;
  TotalWithoutVAT: any;
  GrandTotal: any;
  OrderTotalHandlingCost: any;
  OrderTotalTransportationCost: any;
  cartProductID: any;
  disableCheckout: Boolean = false;
  balanceRewardpoints: any = 0;
  avlPoints: any;
  FileNameCompletePath: any;
  FileName: any;
  indexData: any = {};
  orderSuccess: boolean;
  isVoucherKart: boolean = false;
  hideLogout: boolean = false;

  constructor(
    public _httpProductService: ProductService,
    public headerOneComponent: HeaderOneComponent,
    public router: Router,
    public _httpWebshop: WebshopService,
    public globalService: GlobalService,
    public campaignService: CampaignService,
    public currencySetterService: CurrencySetterService,
    public appComponent: AppComponent,
    public toastService: ToastService,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public settingsComponent: SettingsComponent,
    public _httpSecurity: SecurityService,
    public _httpMessageService: MessageService,
    public messageDataService: MessageDataService,
    public _httpWebshopService: WebshopService,
    public cookieService: CookieService,
    private toastr: ToastrService,
    private commonService: CommonServicesService
  ) {
    const webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
    if(webshopDetail.DomainName === "voucherkart"){
      this.isVoucherKart = true;
    }
    this.currency = this.globalService.getUserSettings().CurrencyCode;
    this.campaignService.getCartStatus().subscribe((e) => {
      if (e === true) {
        this._httpWebshop.getCount().subscribe((c)=>{
          this.cartCount = c;
        })
      }
    });
    this.commonService.getPoints().subscribe((data)=>{
      this.balancePoints = data;
    })
    this.subscription2 = this._httpMessageService.getState().subscribe((selectionFormatState) => {
        this.numberOfUnreadEmails = selectionFormatState;
    });
    this.getLoggedData = JSON.parse(sessionStorage.getItem("loggedInData"));

    if (
      JSON.parse(sessionStorage.getItem("oldDefaultLanguage")) === null ||
      JSON.parse(sessionStorage.getItem("oldDefaultLanguage")) === undefined
    ) {
      this._httpWebshop.getWebmshopDetail().subscribe((data: any[]) => {
        if (this.getLoggedData !== null && this.getLoggedData !== undefined) {
          sessionStorage.setItem(
            "oldDefaultLanguage",
            this.getLoggedData.LanguageID
          );
        } else {
          sessionStorage.setItem(
            "oldDefaultLanguage",
            JSON.stringify(data["DefaultLanguageID"])
          );
        }
      });
    } else {
      if (this.getLoggedData !== null && this.getLoggedData !== undefined) {
        sessionStorage.setItem(
          "oldDefaultLanguage",
          this.getLoggedData.LanguageID
        );
      }
      let getDefaultLang = sessionStorage.getItem("oldDefaultLanguage");

      this._httpWebshop.getWebmshopDetail().subscribe((data: any[]) => {
        if (data["DefaultLanguageID"] !== Number(getDefaultLang)) {
          let getDefLangID = data["DefaultLanguageID"];
          let getDefLangCode = data["DefaultLanguageCode"];
          if (this.getLoggedData !== null && this.getLoggedData !== undefined) {
            getDefLangID = this.getLoggedData.LanguageID;
            getDefLangCode = this.getLoggedData.UserLanguageCode;
          }

          this.globalService.overwriteSession(
            "userSettings",
            "LanguageCode",
            getDefLangCode
          );
          this.globalService.overwriteSession(
            "userSettings",
            "LanguageID",
            getDefLangID
          );
          translate.setDefaultLang(getDefLangCode);
          this.selectedLanguage = JSON.parse(
            sessionStorage.getItem("userSettings")
          ).LanguageID;
          const indexForFlag = this.availableLanguages
            .map(function (e) {
              return e.LanguageID;
            })
            .indexOf(this.selectedLanguage);
          if (indexForFlag !== undefined) {
            this.selectedLanguageFlag =
              this.availableLanguages[indexForFlag].ImageURL;
          }
          sessionStorage.setItem(
            "oldDefaultLanguage",
            JSON.stringify(getDefLangID)
          );
        }
      });
    }

    this.subscription = this._httpWebshopService.getCount().subscribe((selectionFormatState) => {
        this.cartCount = selectionFormatState;
        /** new-code */
        // if (this.cartCount > 0 && this.cartCount !== sessionStorage.getItem('shoppingCartCount')) {
        if (this.cartCount > 0) {
          this.cartCountClass = "graphic";
          setTimeout(() => {
            this.cartCountClass = "";
          }, 300);
        }
        /** new-code */
        // this.getShoppingCartList();
      });
    try {
      if (sessionStorage.getItem("availableLang") === null ||sessionStorage.getItem("availableLang") === undefined) {
        this._httpWebshop.getLanguages().subscribe((data: any[]) => {
            this.availableLanguages = data;
            sessionStorage.setItem("availableLang", JSON.stringify(data));
            this.selectedLanguage = JSON.parse(sessionStorage.getItem("userSettings")).LanguageID;
            const indexForFlag = this.availableLanguages.map(function (e) {
                return e.LanguageID;
              }).indexOf(this.selectedLanguage);
            this.selectedLanguageFlag = this.availableLanguages[indexForFlag].ImageURL;
          },
          (error) => {
            this.toastr.error("Error: " + error);
          }
        );
      } else {
        this.availableLanguages = JSON.parse(
          sessionStorage.getItem("availableLang")
        );
        this.selectedLanguage = JSON.parse(
          sessionStorage.getItem("userSettings")
        ).LanguageID;
        this._httpWebshop.getLanguages().subscribe(
          (data: any[]) => {
            this.availableLanguages = data;
            let availableLanguages = this.availableLanguages;
            let result = _(data)
              .differenceBy(availableLanguages, "LanguageID")
              .map(_.partial(_.pick, _, "LanguageID"))
              .value();
            if (
              result.length > 0 ||
              availableLanguages.length !== data.length
            ) {
              this.availableLanguages = data;
              sessionStorage.setItem("availableLang", JSON.stringify(data));
            }
            const indexForFlag = this.availableLanguages
              .map(function (e) {
                return e.LanguageID;
              })
              .indexOf(this.selectedLanguage);
            this.selectedLanguageFlag =
              this.availableLanguages[indexForFlag].ImageURL;
          },
          (error) => {
            this.toastr.error("Error: " + error);
          }
        );
      }
    } catch (error) {
      this.toastr.error(error);
    }
    try {
      if (
        sessionStorage.getItem("availableCurrencies") === null ||
        sessionStorage.getItem("availableCurrencies") === undefined
      ) {
        this._httpWebshop.getCurrencies().subscribe(
          (data: any[]) => {
            this.availableCurrencies = data;
            sessionStorage.setItem(
              "availableCurrencies",
              JSON.stringify(this.availableCurrencies)
            );
            this.selectedCurrency = JSON.parse(
              sessionStorage.getItem("userSettings")
            ).CurrencyID;
            if (this.selectedCurrency !== null) {
              const indexForSymbol = this.availableCurrencies
                .map(function (e) {
                  return e.CurrencyID;
                })
                .indexOf(Number(this.selectedCurrency));
              if (
                this.availableCurrencies[Number(indexForSymbol)] !== undefined
              ) {
                this.selectedCurrencySymbol =
                  this.availableCurrencies[Number(indexForSymbol)].Symbol;
                this.showCurrencySymbol =
                  this.availableCurrencies[Number(indexForSymbol)].Code;
              } else {
                this.selectedCurrencySymbol =
                  this.availableCurrencies[0].Symbol;
                this.showCurrencySymbol = this.availableCurrencies[0].Code;
              }
            }
          },
          (error) => {
            this.toastr.error(error);
          }
        );
      } else {
        this.availableCurrencies = JSON.parse(
          sessionStorage.getItem("availableCurrencies")
        );
        this.selectedCurrency = JSON.parse(
          sessionStorage.getItem("userSettings")
        ).CurrencyID;
        if (this.selectedCurrency !== null) {
          const indexForSymbol = this.availableCurrencies
            .map(function (e) {
              return e.CurrencyID;
            })
            .indexOf(Number(this.selectedCurrency));
          // this.selectedCurrencySymbol = this.availableCurrencies[Number(indexForSymbol)].Symbol;
          // this.showCurrencySymbol = this.availableCurrencies[Number(indexForSymbol)].Code;
          if (this.availableCurrencies[Number(indexForSymbol)] !== undefined) {
            this.selectedCurrencySymbol =
              this.availableCurrencies[Number(indexForSymbol)].Symbol;
            this.showCurrencySymbol =
              this.availableCurrencies[Number(indexForSymbol)].Code;
          } else {
            this.selectedCurrencySymbol = this.availableCurrencies[0].Symbol;
            this.showCurrencySymbol = this.availableCurrencies[0].Code;
          }
        }
      }
    } catch (error) {
      this.toastr.error(error);
    }

    if (
      appComponent.loggedInData != null &&
      Object.keys(appComponent.loggedInData).length > 0
    ) {
      this.loggedInData = appComponent.loggedInData;
    }

    const loggedInData = this.globalService.getLoggedInData();

    /* Intial Set of CurrencyID and LanguageID */
    if (sessionStorage.getItem("loggedInData") !== null) {
      this.selectedLanguage = loggedInData.LanguageID;
      this.selectedCurrencyID = loggedInData.CurrencyID;
      this.selectedCurrencyCode = loggedInData.UserCurrencyCode;
      this.selectedLanguageCode = loggedInData.UserLanguageCode;
    }
  }

  public browserShoppingToken: any = 0;
  public shoppingCartPostParams: any;
  

  checkLanguageArray(otherArray) {
    return function (current) {
      return (
        otherArray.filter(function (other) {
          return other.LanguageID == current.LanguageID;
        }).length == 0
      );
    };
  }

  numberOfUnreadEmailsChange(_event: any) { }

  ngOnInit() {
    this.CID = this.router.routerState.snapshot.url;
    if(this.CID.indexOf("/checkout/") !== -1){
      this.CID = this.CID.split("Status=");
      let orderData = JSON.parse(sessionStorage.getItem('order'));
      if(this.CID[1] === 'Success' && orderData){
        this.orderSuccess = true;
      }
    }

    const webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
    if (webshopDetail.ChangeDeliveryDateText) {
      this.changeDeliveryDateText = true;
    }
    if (webshopDetail.ParentCustomerDomainName === webshopDetail.DomainName) {
      this.isParentCustomer = true;
     
    } else {
      this.isParentCustomer = false;
      if(webshopDetail.DomainName === "voucherkart"){
        this.isVoucherKart = true;
      }
    }
    if(webshopDetail.DomainName === "247hrm" || webshopDetail.DomainName === "247hrm"){
      this.isHrm = true;
    }
    if(webshopDetail.DomainName === "smartworks" || webshopDetail.DomainName === "sw"){
      this.isSmartworks = true;
      let k = sessionStorage.getItem('urlkey');
      if(k && k === 'yes'){
        this.hideLogout = true;
      }
    }
    if (Number(webshopDetail.CustomerID) === 45) {
      this.wsaStatus = true;
    }
    this._httpWebshop.currentMessage.subscribe(
      (message) => (this.cartCount = message)
    );
    /** To Disable Language and Currency Dropdown for Louis Nielsen Site */
    const domainUrl = window.location.host;
    // tslint:disable-next-line:quotemark
   

    this.getShoppingCartIDList = [];
    this.getShoppingCartList();
    // this._httpWebshop.currentCurrency.subscribe(message => this.showCurrencySymbol = message)
    // const loggedInData = sessionStorage.getItem('loggedInData') ? JSON.parse(sessionStorage.getItem('loggedInData')) : null;
    // if (loggedInData !== null) {
    //   this.globalService.overwriteSession('userSettings', 'LanguageCode', loggedInData.UserLanguageCode);
    //   this.globalService.overwriteSession('userSettings', 'LanguageID', loggedInData.LanguageID);
    //   this.globalService.overwriteSession('userSettings', 'CurrencyCode', loggedInData.UserCurrencyCode);
    // }
  }
  /**
   * Set User Currency funciton
   * params consists of selected currency object
   */

  setUserCurrency(params) {
    try {
      if (this.selectedCurrencySymbol !== params.Symbol) {
        const selectedCurrencyCode = params.Code;
        this.selectedCurrencyCode = params.Code;
        this.selectedCurrencyID = params.CurrencyID;
        this.selectedCurrencySymbol = params.Symbol;
        this.showCurrencySymbol = params.Code;
        this.globalService.overwriteSession(
          "userSettings",
          "CurrencyCode",
          selectedCurrencyCode
        );
        this.globalService.overwriteSession(
          "userSettings",
          "CurrencyID",
          this.selectedCurrencyID
        );
        this.globalService.overwriteSession(
          "userSettings",
          "CurrencySymbol",
          this.selectedCurrencySymbol
        );
        this.currencySetterService.setCurrency(this.selectedCurrencySymbol);
        this._httpWebshopService.changeCurrency(params.Code);
        this._httpWebshopService.changeCurrencyByID(params.CurrencyID);
        this.router.navigate([this.router.url.split("?")[0]], {
          preserveQueryParams: true,
          replaceUrl: false,
        });
      }
      setTimeout(() => {
        if (sessionStorage.getItem("loggedInData") !== null) {
          this.setUserCurrencyLanguage();
        }
      }, 300);
    } catch (error) {
      this.toastr.error(error);
    }
  }

  /**
   * Set User Language funciton
   * params consists of selected language object
   */

  setUserLanguage(params) {
    if (this.translate.getDefaultLang() !== params.Code) {
      try {
        // this.router.navigate(['/']);
        this.translate.use(params.Code);
        this.selectedLanguageCode = params.Code;
        this.globalService.overwriteSession(
          "userSettings",
          "LanguageCode",
          this.selectedLanguageCode
        );
        this.globalService.overwriteSession(
          "userSettings",
          "LanguageID",
          params.LanguageID
        );
        sessionStorage.removeItem("webshopDetail");
        sessionStorage.removeItem("themeReApplyStatus");
        sessionStorage.removeItem("categoryList");
        sessionStorage.removeItem("categoryOptionList");
        sessionStorage.removeItem("colorsList");
        sessionStorage.removeItem("billBoardList");
        this.selectedLanguage = params.LanguageID;
        this.selectedLanguageFlag = params.ImageURL;
      } catch (error) {
        // this.toastService.error(error);
      }

      setTimeout(() => {
        if (sessionStorage.getItem("loggedInData") !== null) {
          this.setUserCurrencyLanguage();
        } else {
          location.reload();
        }
      }, 300);
    }
  }

  /**
   * Set User Language and Currency funciton
   * params consists of selected language object
   */
  setUserCurrencyLanguage() {
    try {
      const postData = {
        LanguageID: this.selectedLanguage,
        CurrencyID: 20,
      };
      setTimeout(() => {
        this._httpWebshopService
          .updateUserLanguageandCurrency(postData)
          .subscribe(
            (_data) => {
              // this.globalService.overwriteSession('loggedInData', 'LanguageID', this.selectedLanguage);
              // this.globalService.overwriteSession('loggedInData', 'CurrencyID', this.selectedCurrencyID);
              const loggedInData = JSON.parse(
                sessionStorage.getItem("loggedInData")
              );
              loggedInData.LanguageID = this.selectedLanguage;
              loggedInData.CurrencyID = this.selectedCurrencyID;
              loggedInData.UserLanguageCode = this.selectedLanguageCode;
              loggedInData.UserCurrencyCode = this.selectedCurrencyCode;
              sessionStorage.setItem(
                "loggedInData",
                JSON.stringify(loggedInData)
              );
              location.reload();
            },
            (error) => {
              // this.toastService.error(JSON.parse(error._body).Message);
              this.toastr.error(error.error.Message);
            }
          );
      }, 320);
    } catch (error) {
      this.toastr.error(error);
    }
  }

  // Logout Function
  logout() {
    try {
      // Remove saved data from sessionStorage
      if(!this.isParentCustomer){
        this.commonService.setPoints(0);
      }
      // based on filter section regarding product tags.
      if (sessionStorage.getItem("productTags") !== null && sessionStorage.getItem("productTags") !== undefined && sessionStorage.getItem("productTags") !== "") {
        sessionStorage.removeItem("productTags");
      }
      // Remove all saved data from sessionStorage
      const webshopDetail = sessionStorage.getItem("webshopDetail");
      sessionStorage.setItem("webshopDetail", webshopDetail);
      const data = JSON.parse(sessionStorage.getItem("webshopDetail"));
      this.loggedInData = '';
      this.globalService.loggedin(null);
      // this.router.navigate([''], { preserveQueryParams: false, replaceUrl: true });
      this.globalService.overwriteSession("userSettings", "LanguageCode", data.DefaultLanguageCode);
      this.globalService.overwriteSession("userSettings", "LanguageID", data.DefaultLanguageID);
      this.globalService.overwriteSession("userSettings", "CurrencyCode", data.DefaultCurrencyCode);
      this.globalService.overwriteSession("userSettings", "CurrencyID", data.DefaultCurrencyID);
      //  location.reload();
      setTimeout(() => {
        sessionStorage.removeItem("webshopDetail");
        sessionStorage.removeItem("themeReApplyStatus");
      }, 100);
      this.router.navigate(['/']);
      setTimeout(() => {
          location.reload();
      }, 200);
        // location.reload();
      
    } catch (error) {
      this.toastr.error(error);
    }
    setTimeout(() => {
    sessionStorage.clear();
    sessionStorage.removeItem("categoryList");
    sessionStorage.removeItem("colorsList");
    sessionStorage.removeItem("categoryOptionList");
    }, 100);
  }

  // moving to user settings & active class
  goUserSettings() {
    this.router.navigate(["settings/user/edituser"]);
    this.type = "settings";
  }

  // go to Join orders Function
  joinOrders() {
    sessionStorage.removeItem("pageIndex");
    sessionStorage.removeItem("pageSize");
    this.CID = this.router.routerState.snapshot.url;
    if (this.CID.indexOf("search?joinlinkorders") !== -1) {
    } else {
      const postParams = {
        joinlinkorders: true,
      };
      this.router.navigate(["/search"], {
        queryParams: postParams,
        replaceUrl: false,
      });
    }
  }

  showProductCartModal() {
    this.orderSuccess = false;
    this.productCartModal.showCartModal();
  }

  getShoppingCartList() {    
    try {
      let userBrowserID = "";
      const cookieExists: Boolean = this.cookieService.check(
        "shoppingCardTokenId"
      );
      if (!cookieExists) {
        let domainName = this._httpWebshopService.getSubdomain(
          window.location.hostname
        );
        domainName = domainName ? domainName : 'voucherkart';
        const randomString = this._httpWebshopService.randomGeneration(6);
        this.cookieService.set(
          "shoppingCardTokenId",
          domainName + randomString
        );
        userBrowserID = domainName + randomString;
      } else {
        userBrowserID = this.cookieService.get("shoppingCardTokenId");
      }
      const userSettings = this.globalService.getUserSettings();
      const webShopSettings = this.globalService.webShopSettings();
      const loggedInData = this.globalService.getLoggedInData();
      if (loggedInData !== null) {
        this.cartParams = {
          UserID: loggedInData.UserID,
          CustomerID: webShopSettings.CustomerID,
          CurrencyID: 20,
          UserBrowserID: userBrowserID,
          InitialDeliveryCountry: loggedInData.Country,
          ReturnOnlyVisibleItems: true,
          //  'ReturnOnlyValidCheckedVisibleCompleteItems': true   // new-code
        };
      } else {
        this.cartParams = {
          UserID: null,
          CustomerID: webShopSettings.CustomerID,
          CurrencyID: 20,
          UserBrowserID: userBrowserID,
          InitialDeliveryCountry: userSettings.CountryCode,
          ReturnOnlyVisibleItems: true,
          // 'ReturnOnlyValidCheckedVisibleCompleteItems': true    // new-code
        };
      }
      this.loaderShow = true;

      if(this.isVoucherKart){
        this.cartParams.IsVoucher = true;
      }else{
        this.cartParams.IsVoucher = false;
      }
      
      this._httpWebshop.getShoppingCart(this.cartParams).subscribe((data: any[]) => {
          this.shoppingCartList = data;
          sessionStorage.setItem("cartData", JSON.stringify(this.shoppingCartList));
          this.loaderShow = false;
          /** new-code => Restirct to display invisible: false items of shopping cart */
          const shoppingCartitemData = [];
          let k = 0;
          for (let i = 0; i < this.shoppingCartList["Items"].length; i++) {
            this.shoppingCartList["Items"][i].disableUpdate = true;
            if (this.shoppingCartList["Items"][i].isVisible) {
              shoppingCartitemData[k] = data["Items"][i];
              k++;
            }
          }
          this.totalPrice = 0;
         
         
          /** new-code => To check all steps are completed for product */
        
          if (!this.isParentCustomer) {
            const getLogged = this.globalService.getLoggedInData();
            if(getLogged){
            this._httpWebshopService.getRedeemUser(getLogged.UserID).subscribe((_res:any) => {
                //this.balancePoints = res["RewardPoints"];
                if(this.isVoucherKart){
                  this.balancePoints = _res.RewardPoints;
                  this.commonService.setPoints(this.balancePoints);
                }
                if (this.tempPoints < 0) {
                  this.tempPoints = 0;
                }
                this.avlPoints = this.tempPoints;
                let a = this.campaignService.getActiveCampaign().subscribe((res) => {
                  if (res) {
                   let b=  this.commonService.getPoints().subscribe((data)=>{
                      this.balancePoints = data;
                      this.commonService.setPoints(this.balancePoints);
                      this.tempPoints = this.balancePoints - this.totalPrice;
                    })
                    b.unsubscribe();
                  } else {
                    //this.commonService.setPoints(this.tempPoints);
                  }
                });
                a.unsubscribe();
               
              });
            }
          }
     

         
          this.balanceRewardpoints = this.avlPoints - this.totalPrice;
          // this._httpWebshop.changeMessage(data['Items'].length);
          this._httpWebshop.changeMessage(shoppingCartitemData.length);
          this._httpWebshop.currentMessage.subscribe(
            (message) => (this.cartCount = message)
          );
          //  this.cartCount = data.Items.length;
          this.cartCount = shoppingCartitemData.length;

          /** new-code */
          // sessionStorage.setItem('shoppingCartDatas', JSON.stringify(data['Items']));
          sessionStorage.setItem("shoppingCartDatas", JSON.stringify(shoppingCartitemData));
          /** new-code */

          sessionStorage.setItem("shoppingCartCount", this.cartCount.toString());
          this._httpWebshop.setCount(this.cartCount);
          setTimeout(() => {
            if (sessionStorage.getItem("joinOrderCounts") !== "undefined" && sessionStorage.getItem("joinOrderCounts") !== null && sessionStorage.getItem("joinOrderCounts") !== "") {
              this.joinOrderCounts = JSON.parse(sessionStorage.getItem("joinOrderCounts"));
            }
          }, 5000);
        },
        _error => {
          this.loaderShow = false;
          //this.toastr.error(error);
        }
      );
    } catch (error) {
      this.loaderShow = false;
      this.toastr.error(error);
    }
  }

  shoppingCartPage(data) {
    if(!this.isVoucherKart){
      if(data.Price.PaymentFlag.toLowerCase() !== 'true'){

        const shoppingCartItemID = data.ShoppingCartItemID;
        this.deleteModal.hide();
        this.deleteModalOneItem.hide();
        this.productCartModal.hide();
        // Remove class in body tag
        const bodytag = document.getElementsByTagName("body")[0];
        bodytag.classList.remove("shoppingModal"); // remove the class
        // this.router.navigate(['/shoppingcart', shoppingCartItemID],
        //  { replaceUrl: false });

        /** new-code => To Remove Session data for Join/New order datas */
        if (sessionStorage.getItem("joinOrderData") !== undefined && sessionStorage.getItem("joinOrderData") !== null) {
          sessionStorage.removeItem("joinOrderData");
        }
        if (sessionStorage.getItem("joinOrderId") !== undefined && sessionStorage.getItem("joinOrderId") !== null) {
          sessionStorage.removeItem("joinOrderId");
        }
        if (sessionStorage.getItem("newOrderData") !== undefined && sessionStorage.getItem("newOrderData") !== null) {
          sessionStorage.removeItem("newOrderData");
        }
        if (sessionStorage.getItem("newOrderId") !== undefined && sessionStorage.getItem("newOrderId") !== null) {
          sessionStorage.removeItem("newOrderId");
        }
        /** new-code => To Remove Session data for Join/New order datas */

        this.router.navigate(["/productdetail", data.ProductID, data.ProductName, shoppingCartItemID], { replaceUrl: false });
        this.addOpacityChatIcon();
      }
    }
  }

  totalQulityChange() {
    this.TotalValUpdateShow = !this.TotalValUpdateShow;
  }

  

 
  messageCenter() {
    const loggedInData = this.globalService.getLoggedInData();
    if (loggedInData) {
      this.router.navigate(["/messages/list/inbox"], { replaceUrl: false });
    } else {
      // this.translatedMessage = (this.translate.get('ERRORS.PLEASE_LOGIN_TO_ACCESS_MESSAGE_CENTER'));
      this.translate
        .get("ERRORS.PLEASE_LOGIN_TO_ACCESS_MESSAGE_CENTER")
        .subscribe((res: string) => {
          this.translatedMessage = res;
        });
      this.toastr.error(this.translatedMessage);
    }
  }
  

  addOpacityChatIcon() {
    try {
      document.getElementById("chatSec").style.display = "block";
    } catch (error) { }
  }

 

}
