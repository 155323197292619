import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { VoucherService } from "app/linknet-common/restful-services/restful.service";
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { ToastrService } from "ngx-toastr";

@Component({
    selector: 'shop-app-header-voucher',
    templateUrl : './header-voucher.component.html',
    styleUrls: ['./header-voucher.component.scss']
})

export class HeaderVoucherComponent implements OnInit {
  @Input() redirect: boolean;
  @Output() discount = new EventEmitter<any>();
  @Output() redeem = new EventEmitter<any>();
  @Output() clearFilter = new EventEmitter<any>();
  brandList: any;
  brandData: any;
  brandSearch: any;
  searchLabel:any;
  brandCount: any;
  topvoucherList: any;
  voucherList: any;
  voucherListFilter: any = [];
  showMenu: boolean = false;
  categoryDropdownHeight: any;
  overflow: any = '';
  overflowClassY: any = '';
  @Input() discountOption: any;
  @Input() rdOption: any;
  logIndata: any;
  isVoucherKart:boolean = false;
  offline:string = "Offline";
  both:string = "Both";
  constructor(private toastr: ToastrService, private httpVoucher: VoucherService, private router: Router, public globalService: GlobalService){
    this.logIndata = this.globalService.getLoggedInData();
    const webshopData = JSON.parse(sessionStorage.getItem('webshopDetail'));
    if (webshopData && webshopData.DomainName === "voucherkart") {
      this.isVoucherKart = true;
      this.offline = "InStore";
      this.both = "Online + InStore";
    }else{
      this.isVoucherKart = false;
    }
  }
  
  ngOnInit() {
    this.getTOPVoucherList();
    this.getVoucherList();
    // if(!this.isVoucherKart){
    //   this.getBrandList();
    // }
  }
  brandPlaceHol(){
    setTimeout(() => {
      if(this.isVoucherKart){
        this.getBrandList();
        let placeHolder = document.getElementsByClassName("search_input_gobal") as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < placeHolder.length; i++) {
          placeHolder[2].setAttribute("placeholder", "Search for your Favourite Brand");
          placeHolder[2].style.fontSize = "16px !important";
        }
      }
    }, 100);
  }
  getBrands(data){
    let brand = this.brandData.filter(e=>e.brand_name === data);
    let type = 'brands';
    sessionStorage.setItem('vouchersearch','yes');
    this.router.navigate(['/vouchers', type, brand[0].ID], { replaceUrl: false })
  }

  getBrandList(){
    this.brandList = [];
    this.httpVoucher.getVoucherBrandsSearch().subscribe((data:any)=>{
        this.brandData = data;
        this.brandData.forEach(element => {
          this.brandList.push(element.brand_name)
        });
        this.searchLabel = 'Search for your Favourite Brand';
        // if(!this.isVoucherKart){
        //   this.brandCount = this.brandData.length;
        // }
    })
}

getTOPVoucherList(){
  this.httpVoucher.getTopVoucherCategories().subscribe((data:any)=>{
    this.topvoucherList = data;
    /*/data.forEach((e:any, key:any)=>{
      if(key < 20) {
        this.voucherListFilter.push(e);
      }
    })*/
  })
}

  getVoucherList(){
    this.httpVoucher.getVoucherCategories().subscribe((data:any)=>{
      this.voucherList = data;
    })
  }

  showMenus() {
    this.showMenu = !this.showMenu;
    if(this.showMenu){
      this.brandPlaceHol();
    }
    /*if(this.logIndata){
    
    }else{
      this.toastr.error("Please login to access the feature")
    }*/
  }

  getElementHeight() {
    const x = document.getElementById('catgeoryScreen');
    this.categoryDropdownHeight = x.clientHeight;
    if (x.clientHeight < 320) {
      this.overflow = 'visible'
    } else {
      this.overflowClassY = 'auto'
    }
  }

  voucherProducts(type, id){
    this.router.navigate(['/vouchers', type, id], { replaceUrl: false })
  }

  discountFilter(type){
    this.discountOption = type;
    let discountOption = this.discountOption;
    let rdOption = this.rdOption;
    this.discount.emit(type);
    if(this.redirect){
      this.router.navigate(['/voucher-filter'], {state: {discountOption, rdOption}})
    }
  }

  redeemFilter(type){
    //this.discountOption = '';
    this.rdOption = type;
    let discountOption = this.discountOption;
    let rdOption = this.rdOption;
    this.redeem.emit(type)
    if(this.redirect){
      this.router.navigate(['/voucher-filter'], {state: {discountOption, rdOption}})
    }
  }
  clearAll(){
    this.rdOption = '';
    this.discountOption = '';
    this.clearFilter.emit(true)
  }
}