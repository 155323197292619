import { Component, OnInit , Injectable, Output, EventEmitter, ViewChild, Input, ElementRef } from '@angular/core';
import { SecurityService } from '../restful-services/restful.service';
import { GlobalService, MessageDataService } from '../globals/global.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ToastService } from '../../typescripts/pro/alerts';
import { environment } from 'environments/environment';
import { MessageService } from '../restful-services/message-services/message.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { WebshopService } from '../restful-services/restful.service';
import { ToastrService } from 'ngx-toastr';
import { ValidationService } from '../validators/index';


@Component({
  selector: 'shop-app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  exportAs: 'child'
})

@Injectable()

export class LoginComponent implements OnInit  {
  @Output() onLoggedin = new EventEmitter<boolean>();
  @ViewChild('loginModal') public loginModal;
  @Input() isPrivate: boolean;
  @Input() isCheckoutPage: any;
  public showPassword: boolean = false;
  public translatedMessage: any;
  public isLoginSuccessModalShown = false;
  public username: string;
  public password: string;
  public userDetail: Object;
  public loginForm: FormGroup;
  public loginOTPForm: FormGroup;
  public forgotPasswordForm: any;
  public forgotPassword: Boolean = false;
  public savepassword: boolean = false;
  public isshow: boolean = false;
  public shopShow: boolean = false;
  public isLoginPage: boolean = true;
  public CID: any;
  public customerImage: any;
  public siteKey:any = "6Lf9ypsjAAAAAOQ-YzD4TZGT6oK270cVJrv-8SoP";
  public captcha:any="";
  public userNameLogin: boolean = true;
  public mobileEmailLogin: Boolean = false;
  public token: string|undefined;
  public otpScreen: boolean = false;
  public aFormGroup: FormGroup;
  /** new-code => Check webshop child name to display Email/Username field in SignUp Form */
  public showEmailLabel: Boolean = false;
  isParentCustomer: boolean = true;
  IsParentShop:boolean = false;
  isVoucheKart: boolean = false;
  webshopData: any;
  /** new-code => Check webshop child name to display Email/Username field in SignUp Form */
  public queryParams: any; // new-code query param to get username and prefill in login(forgot password) form
  mobileEmail : FormControl;
  otp : FormControl;
  domainName: any;
  urlkey: any;
  urldata: any;
  swurl: any;
  isSmartworks: boolean = false;
  isHrm: boolean = false;
  tokenid: string;
  isCorpkonnex: boolean = false;
  urldomain: any;
  bgurl: any;
  backImageUrl: string = "../../../assets/bg2.jpeg"
  backSwImg: string = "../../../assets/sw_bg1.png"
  backimgRw:string = "../../../assets/bg1.jpeg"
  smartWorks: boolean;
  bgColor: any;
  isGrowWell: boolean = false;
  @ViewChild('colorDiv') colorDiv: ElementRef;
  constructor(private _http: SecurityService,
    public globalService: GlobalService,
    private toastService: ToastService, public router: Router,
    private formBuilder: FormBuilder, private _httpMessagesService: MessageService, private messageDataService: MessageDataService,
    private cookieService: CookieService, public translate: TranslateService, public _httpWebshopService: WebshopService,
    private toastr: ToastrService, private validationService: ValidationService) {
    /** new-code query param to get username and prefill in login(forgot password) form */
    this.queryParams = this.router.routerState.snapshot.root.queryParams;
    let fullurl = window.location.href;
    let url1 = this.getParameterByName('Status', fullurl)
    let url2 = this.getParameterByName('Message', fullurl);
    this.urlkey = this.getParameterByName('key', fullurl);
    this.urldata = this.getParameterByName('data', fullurl);
    this.tokenid = this.getParameterByName('token_id', fullurl);
    this.urldomain = this.getParameterByName('Domain', fullurl);

    if(url1 === 'Y' && sessionStorage.getItem('shopReg')){
      this.shopShow = true;
    }
    else if(sessionStorage.getItem('shopReg')){
      this.shopShow = true;
    }
    /** new-code query param to get username and prefill in login(forgot password) form */
    
    this.forgotPasswordForm = this.formBuilder.group({
      'username': ['', [Validators.required]]
    });

    this.customerImage = this.globalService.webShopSettings().ImageURL;
    this.token = undefined;
    const webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
    this.webshopData = webshopDetail;
    this.bgColor = this.webshopData.Themes[0].Main;
    // console.log(this.bgColor,"color");
    
    if(this.webshopData.DomainName === "sw" || this.webshopData.DomainName === "smartworks"){
      this.isSmartworks = true;
    }
    if(this.webshopData.DomainName === "247hrm" || this.webshopData.DomainName === "247hrm"){
      this.isHrm = true;
    }
    if(this.webshopData.DomainName === "corpkonnex" || this.webshopData.DomainName === "ckonnex"){
      this.isCorpkonnex = true;
    }
    if(webshopDetail.DomainName === "growmore" || webshopDetail.DomainName === "gm"){
      this.isGrowWell = true;
    }
    if (this.webshopData.ParentCustomerDomainName === this.webshopData.DomainName) {
        this.isParentCustomer = true;
        this.IsParentShop = true;
        this.domainName =  this.webshopData.DomainName
    } else {
      this.IsParentShop = false;
        if(this.webshopData.DomainName === "voucherkart"){
          this.isVoucheKart = true;
        }else{
          this.isParentCustomer = false;
        }
    }
    if(this.isParentCustomer || this.isVoucheKart){
      this.bgurl = this.webshopData.ShopImageUrl ? this.webshopData.ShopImageUrl : this.backimgRw;
    }else if(this.isSmartworks){
      if(this.webshopData.ShopDetailsName){
        this.smartWorks = false;
      }else{
        this.smartWorks = true;
      }
      this.bgurl = this.webshopData.ShopImageUrl ? this.webshopData.ShopImageUrl : this.backSwImg;
    }else{
      this.bgurl = this.webshopData.ShopImageUrl ? this.webshopData.ShopImageUrl : this.backImageUrl;
    }
    this.swurl = window.location.href;
    if((this.webshopData.DomainName === "smartworks" || this.webshopData.DomainName === "sw") && this.urlkey && this.urldata){
      sessionStorage.setItem('urlkey', 'yes');
      this._http.keylogin(this.urlkey, this.urldata, this.isParentCustomer).subscribe(
        (data:any) => {
          if(data.IsRequireUsersApproved){
            sessionStorage.setItem('swdata', JSON.stringify(data));
            this.loggedIn(data);
            }else{
              this.toastr.error('Invalid credentials!');
            }
        })
    }
    if((this.webshopData.DomainName === "247hrm") && this.urlkey && this.urldata){
      sessionStorage.setItem('urlkey', 'yes');
      this._http.hrmKeylogin( this.urldata,this.urlkey, this.IsParentShop).subscribe(
        (data:any) => {
          if(data.IsRequireUsersApproved){
            sessionStorage.setItem("247hrm", JSON.stringify(data));
            this.loggedIn(data);
            }else{
              this.toastr.error('Invalid credentials!');
            }
        })
    }

    if((this.webshopData.DomainName === "rewardkart") && this.urlkey && this.urldata && this.IsParentShop && this.urldomain){
      sessionStorage.setItem('urlkey', 'yes');
      this._http.rwdKeylogin( this.urldata,this.urlkey, this.IsParentShop, this.urldomain).subscribe(
        (data:any) => {
          if(data.IsRequireUsersApproved){
            sessionStorage.setItem("247hrm", JSON.stringify(data));
            this.loggedIn(data);
            }else{
              this.toastr.error('Invalid credentials!');
            }
        })
    }

    if(this.webshopData.DomainName === "pc" && this.tokenid){
      sessionStorage.setItem('urlkey', 'yes');
      this._http.tokenlogin(this.tokenid, this.webshopData.DomainName).subscribe(
        (data:any) => {
          if(data.IsRequireUsersApproved){
            sessionStorage.setItem('pcdata', JSON.stringify(data));
            this.loggedIn(data);
            }else{
              this.toastr.error('Invalid credentials!');
            }
        })
    }
  }

  ngOnInit() {

    if(sessionStorage.getItem('signuppay') && sessionStorage.getItem('signuppay') === this.webshopData.DomainName){
      this.isshow = true;
    }

    if(!this.isVoucheKart){
      this.loginForm = this.formBuilder.group({
        'username': ['', [Validators.required, removeSpaces]],
        'password': ['', [Validators.required, removeSpaces]],
        'recaptcha': ['', Validators.required]
      });
  
    }else{
      this.loginForm = this.formBuilder.group({
        'username': ['', [Validators.required, removeSpaces]],
        'password': ['', [Validators.required, removeSpaces]]
      });
    }

    this.loginOTPForm = this.formBuilder.group({
      'mobileEmail': ['', [Validators.required, removeSpaces]],
      'otp': ['', [Validators.required, removeSpaces]]
    });

    // this.aFormGroup = this.formBuilder.group({
    //   recaptcha: ['', Validators.required]
    // });
   
    const cookieExists: Boolean = this.cookieService.check('loginUsername');
    if (cookieExists) {
      this.loginForm.patchValue({
        username: this.cookieService.get('loginUsername'),
        password: this.cookieService.get('loginUserPassword')
      }, { onlySelf: false, emitEvent: false });
      this.savepassword = true;
    }
    if (this.isPrivate) {
      /*setTimeout(() => {
        this.loginModal.show();
      }, 300);*/
    }

    /** new-code query param to get username and prefill in login(forgot password) form */
    setTimeout(() => {
      if (window.location.href.indexOf('?activeusername') > -1) {
        //this.loginModal.show();
        this.forgotPassword = true;
        const username = window.location.href.split('?activeusername=');
        this.loginForm.patchValue({
          username: username[1]
        }, { onlySelf: false, emitEvent: false });
      }
    }, 300);
    /** new-code query param to get username and prefill in login(forgot password) form */

    /** new-code => Check webshop child name to display Email/Username field in SignUp Form */
    const domainName = this._httpWebshopService.getSubdomain(window.location.hostname);
    // const customerName = JSON.parse(sessionStorage.getItem('webshopDetail')).Name;
    const customerName = JSON.parse(sessionStorage.getItem('webshopDetail')).CustomerID;
  
    if (Number(customerName) === 45) {
      this.showEmailLabel = true;
    }
    /** new-code => Check webshop child name to display Email/Username field in SignUp Form */
  }

  ngAfterViewInit(){
    if(!this.isVoucheKart && !this.isParentCustomer){
      this.colorDiv.nativeElement.style.backgroundColor = this.bgColor;
    }
  }

  getParameterByName(name, url) {
    //if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&|]" + name + "(=([^&#|]*)|&|#|$)", 'i'),
    results = regex.exec(url);
    if (!results)
      return null;
    if (!results[2])
      return '';
    return results[2].replace(/\+/g, " ");
  }

  showModal() {
    this.forgotPassword = false;
    this.loginModal.show();
  }

  hideModal() {
   this.loginModal.hide();
  }

  /* Login Function */
  login(_isLogin, _isForgot) {

    if (!this.loginForm.value.username){
      this.toastr.error("Please enter username");
    }

    else if (_isLogin && !this.loginForm.value.password){
      this.toastr.error("Please enter password");
    }

    else if (!this.isVoucheKart && !this.loginForm.value.recaptcha){
      this.toastr.error("Please select I\'m not a robot");
    }
    else if ((this.loginForm.value.username && this.loginForm.value.password) || _isForgot) {
      if (_isForgot === true) {
        delete this.loginForm.value.password;
      }

      this._http.login(this.loginForm.value.username, this.loginForm.value.password).subscribe(
        (data:any) => {

          if (data === 'Your password has been sent to your e-mail address.') {
            this.toastr.success(data.toString());
            if (this.isPrivate) {
              location.reload();
            } else {
              //this.loginModal.hide();
            }
          } else {
            if(data.IsRequireUsersApproved){
              this.loggedIn(data);
            }else{
              this.toastr.error('Your access has not yet been approved by administrator');
              sessionStorage.clear();
              setTimeout(() => {
                location.reload();
              }, 3000);
            }
          }
        }, error => {
          if (error.status === 404) {
            // this.toastService.error(JSON.parse(error._body)['Message']);
            this.toastr.error(error.error.Message);
          } else {
            // this.toastService.error(JSON.parse(error._body)['Message']);
            this.toastr.error(error.error.Message);
          }
        });
    } else {
      // this.translatedMessage = (this.translate.get('LOGIN.FILL_ALL_FIELDS'));
      this.translate.get('LOGIN.FILL_ALL_FIELDS').subscribe((res: string) => {
        this.translatedMessage = res;
      });
      this.toastr.error(this.translatedMessage);
    }
  }
  savePassword(data) {
    if (data.currentTarget.checked) {
      const cookieExists: Boolean = this.cookieService.check('loginUsername');
      if (cookieExists) {
        this.cookieService.delete('loginUsername');
        this.cookieService.delete('loginUserPassword');
      }
      setTimeout(() => {
        this.cookieService.set('loginUsername', this.loginForm.value.username);
        this.cookieService.set('loginUserPassword', this.loginForm.value.password);
      }, 50);
    } else {
      this.cookieService.delete('loginUsername');
      this.cookieService.delete('loginUserPassword');
    }
  }
  showSignup() {
    this.isshow = true;
    //this.loginModal.hide();
  }

  showSignupShop(){
    this.shopShow = true;
  }

 
  public send(form:NgForm):void{
    if(form.invalid){
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }
    console.debug(`Token [${this.token}] generated`);
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  fieldclear(){     
   this.loginOTPForm.controls['otp'].setValue('');
   this.mobileEmailLogin=true;
   this.otpScreen=false;  
  }

  // ngOnDestroy() {

  //   if(this.mobileEmailLogin=true ){

  //     this.loginOTPForm.value.otp = '';
  //     this.loginOTPForm.value.otp = null;
  //   }
 
  // }

 

  getMobileEmail(){
    this.userNameLogin = false;
    this.mobileEmailLogin = true;
  }

  requestOTP(){      
    let em = this.validationService.emailMobileValidate(this.loginOTPForm.value.mobileEmail);
    if(em === 'invalidinput' || !this.loginOTPForm.value.mobileEmail){
        this.toastr.error('Please enter valid Email Id/Mobile number')
    }
    else{
      let param = '';
      if(em === 'email'){
        param = 'Mobile&Email='+this.loginOTPForm.value.mobileEmail+'&UserName'
      }
      else{
        param = 'Mobile='+this.loginOTPForm.value.mobileEmail+'&Email&UserName'
      }

      this._http.triggerOTP(param).subscribe(
        data=>{
          if(data === 1){
            this.mobileEmailLogin = false;
            this.otpScreen = true;
          }
      })  
    }   
   
    this.loginOTPForm.controls['mobileEmail'].setValue('');
   
  }

  validateOTP(){
    let em = this.validationService.emailMobileValidate(this.loginOTPForm.value.mobileEmail);
  
      let param = '';
      if(em === 'email'){
        param = 'Mobile&Email='+this.loginOTPForm.value.mobileEmail+'&UserName&OTP='+ this.loginOTPForm.value.otp
      }else{
        param = 'Mobile='+this.loginOTPForm.value.mobileEmail+'&Email&UserName&OTP='+ this.loginOTPForm.value.otp
      }

      this._http.validateOTP(param).subscribe(
        data=>{
          if(data){
            this.loggedIn(data);
          }
      })
    
  }

  loggedIn(data){
    let loginStatus = true;
    if (data['RequireUsersApproval']) {
      if (data['IsRequireUsersApproved']) {
        loginStatus = true;
      } else {
        loginStatus = false;
        this.translate.get('LOGIN.PENDING_USER_ERROR_MSG').subscribe((res: string) => {
          this.translatedMessage = res;
        });
        this.toastr.error(this.translatedMessage);
      }
      //this.loginModal.hide();
    } else {
      loginStatus = true;
    }

    if (loginStatus) {
      this.userDetail = data;
      // Save data to sessionStorage
      sessionStorage.setItem('loggedInData', JSON.stringify(this.userDetail));
      this.globalService.loggedin(data);
      this.globalService.overwriteSession('userSettings', 'LanguageCode', data['UserLanguageCode']);
      this.globalService.overwriteSession('userSettings', 'LanguageID', data['LanguageID']);
      this.globalService.overwriteSession('userSettings', 'CurrencyCode', data['UserCurrencyCode']);
      this.globalService.overwriteSession('userSettings', 'CurrencyID', data['CurrencyID']);
      sessionStorage.removeItem('categoryList');
      sessionStorage.removeItem('categoryOptionList');
      sessionStorage.removeItem('colorsList');
      //this.loginModal.hide();
      // if (this.router.navigate(['/'], { replaceUrl: false })) {
      //   setTimeout(() => {
      //     location.reload();
      //   }, 100);
      // }
      this.CID = window.location.pathname;
      this.CID = this.CID.split('/');
      this.CID = this.CID[1];
      if(this.swurl.indexOf('Userimport') !== -1){
        sessionStorage.setItem('swimport', 'yes');
        setTimeout(() => {
          location.reload();
        }, 100);
      }
      else if (this.isCheckoutPage || this.CID === 'orderhistory' || this.CID === 'productdetail' || this.CID === 'search') {
        setTimeout(() => {
          location.reload();
        }, 100);
      } else {
        let check = this.router.navigate(['/'], { replaceUrl: false });
        if (window.location.href.indexOf('#newsletter') > -1) {
          check = this.router.navigate(['/#newsletter'], { replaceUrl: false });
        }
         if (check) {
          setTimeout(() => {
              location.reload();
          }, 100);
        }
      }
      const pages = {
        'pageIndex': 1,
        'pageSize': environment.ENVIRONMENT.minPageSize
      }
      this._httpMessagesService.getEmails(pages, 'Inbox').subscribe(
        dataMsg => {
          this.messageDataService.setNumberOfUnreadEmails(dataMsg['UnReadCount']);
        })
      //this.loginModal.hide();
    }
  
  }

}

export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}
