import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { SecurityService, WebshopService } from '../restful-services/restful.service';
import { ValidationService } from '../validators/index';
import { Router } from '@angular/router';
import { ToastService } from '../../typescripts/pro/alerts';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import 'rxjs/Rx';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';

@Component({
    selector: 'shop-app-signup-shop',
    templateUrl: './signup-shop.component.html',
    styleUrls: ['./signup-shop.component.scss']
})

export class SignupShopComponent implements OnInit{ currencyDropDownClicked: string;
    langaugeDropDownClicked: string;
    dropDownClicked: string;
    message: string;
    indexForCurrency: any;
    @Input() isSetting: any;
    // @ViewChild('userDataPolicyModal') public userDataPolicyModal;
    public currentStepTitle: string;
    public fullfeature: string;
    public chooseplan: string;  
    public countries: Array<any>;
    public register: any;
    public customerID: string;
    public userName: string;
    public languageID: string;
    public optionsSelect: Array<any>;
    public signupForm: any;
    public isVatRequired: any;
    public translatedMessage: any;
    public userDetail: any;
    public isSettingPage: any;
    public loggedInData: any;
    public priFillLanguage: any;
    public priFillCountry: any;
    public postParams: any;
    public availableCurrencies: any;
    public selectedCurrency: any;
    public termsAndConditionText: any;
    public agreeTermsConditionsStatus: boolean = false;
    public agreeTermsConditionsStatusCheckbox: boolean = false;
    registerParameters: Object;
    public userResponseData: any;
    public postalCodeErrorCount = 0;
    public loaderShow: boolean = false;
    public isParentCustomer: boolean = false;
    /** new-code => Check webshop child name to display Email/Username field in SignUp Form */
    public showEmailLabel: boolean = false;
    /** new-code => Check webshop child name to display Email/Username field in SignUp Form */
    public mobileotpValidated: boolean = false;
    public emailotpValidated: boolean = false;
    public customerImage: any;
    isMobile: boolean;
    public customerLogoName: any;
    public customerLogoURL: any = null;
    public customOptions = { allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'] };
    public goldenberryStatus: boolean = false;
    public optionsPaymentTerms: Array<any>;
    step1: boolean = true;
    step2: boolean = false;
    step3: boolean = false;
    planList: any;
    subscriptionPlanId: any;
    btnSelect: any;
    queryParams: any;
    otpSection: boolean = false;
    shopParams: any;
  webshopDetail: any;
    constructor(public router: Router,
      public _httpSecurity: SecurityService, public _httpWebshop: WebshopService,
      public _httpWebshopService: WebshopService, private toastService: ToastService, public formBuilder: FormBuilder,
      private translate: TranslateService, public globalService: GlobalService, private toastr: ToastrService) {
        if (/Android|webOS|iPhone|iPad|iPod|pocket|psp|kindle|avantgo|blazer|midori|Tablet|Palm|maemo|plucker|phone|BlackBerry|symbian|IEMobile|mobile|ZuneWP7|Windows Phone|Opera Mini/i.test(navigator.userAgent)) {
                  this.isMobile = true;
              } else {
                  this.isMobile = false;
              }
      try {
        this._httpWebshopService.currentCurrency.subscribe(message => this.message = message)
        this.currentStepTitle = 'GENERAL_INFORMATION_SHOP';
        this.fullfeature = 'FULL_FEATURE_COMPARE';
        this.chooseplan = 'CHOOSE_PLAN';
     
        this.register = {};
        this.customerImage = this.globalService.webShopSettings().ImageURL;
      } catch (error) {
        this.toastr.error(error);
      }

      this.queryParams = this.router.routerState.snapshot.root.queryParams;
      let fullurl = window.location.href;
      let url1 = this.getParameterByName('Status', fullurl)
      let url2 = this.getParameterByName('Message', fullurl)
  
      if(url1 === 'Y' && sessionStorage.getItem('shopReg')){
        this.createShop();
      }else if(sessionStorage.getItem('shopReg')){
        this.step1 = false;
        this.step2 = true;
      }
    }
  
    ngOnInit() {
      const domainUrl = window.location.host;
      // tslint:disable-next-line:quotemark
        this.signupForm = this.formBuilder.group({
          'Name': ['', [Validators.required]],
          'CompanyName': ['', [Validators.required]],
          'Password': ['', [Validators.required, ValidationService.passwordValidator]],
          'Confirmpassword': ['', [Validators.required]],
          'ContactEmail': ['', [Validators.required, ValidationService.emailValidator]],
          'ContactNumber': ['', [Validators.required]],
          'DomainName': ['', [Validators.required]],
          'mobileOTP': [''],
          'emailOTP': ['']
        });
  
      /** new-code => Check webshop child name to display Email/Username field in SignUp Form */
      const domainName = this._httpWebshopService.getSubdomain(window.location.hostname);
      // const customerName = JSON.parse(sessionStorage.getItem('webshopDetail')).Name;
      const customerName = JSON.parse(sessionStorage.getItem('webshopDetail')).CustomerID;
     
      if (Number(customerName) === 45) {
        this.showEmailLabel = true;
      }
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if(this.webshopDetail.ParentCustomerDomainName === this.webshopDetail.DomainName){
        this.isParentCustomer = true;
      }else{
        this.isParentCustomer = false;
      }
      if (this.webshopDetail.ParentCustomerDomainName === 'goldenberry') {
        this.goldenberryStatus = true;
      }
      this.getSubscription();
      /** new-code => Check webshop child name to display Email/Username field in SignUp Form */
    }

    getParameterByName(name, url) {
      //if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&|]" + name + "(=([^&#|]*)|&|#|$)", 'i'),
      results = regex.exec(url);
      if (!results)
        return null;
      if (!results[2])
        return '';
      return results[2].replace(/\+/g, " ");
    }
  
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      const charCode = (evt.which) ? evt.which : evt.keyCode;
      //console.log("charCode : ", charCode);
      if ((charCode < 48 || charCode > 58)) {
        return false;
      }
      return true;
    }

    setUploadedImageData(ev) {
      try {
        this.customerLogoName = ev[1];
        this.customerLogoURL = ev[2];
      } catch (error) {
        this.toastr.error(error);
      }
    }
  
    onInputChange(event: any) {
      const regex = /[^a-zA-Z]/g;
      const input = event.target.value;
      event.target.value = input.replace(regex, '');
    }
  
    onlyNumberKey(event) {
      const pattern = new RegExp('^[0-9\b]+$');
      const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!pattern.test(key) && event.charCode !== 0) {
        event.preventDefault();
        return false;
      }
    }

    getSubscription(){
      this._httpWebshopService.getallSubScriptions().subscribe(data=>{
       this.planList = data;
       this.planList = this.planList.reverse();
      });
    }

    next(){
      const fullname = this.signupForm.value.Name;
      const data = 'Mobile='+ this.signupForm.value.ContactNumber.trim() + '&CustomerName='+fullname+'&UserName='+ this.signupForm.value.ContactEmail.trim() + '&language_id=1&CustomerTheme=#9dbd17';
      this.loaderShow = true;
      this.otpSection = true;
      this._httpWebshopService.triggerMobileOTP(data).subscribe((_res)=>{
        this.loaderShow = false;
      },_error=>{
        this.toastr.error('Error in generating OTP');
        this.loaderShow = false;
      });
      const emaildata = "Email=" + this.signupForm.value.ContactEmail + "&UserName=" + this.signupForm.value.ContactEmail+ "&DomainName="+this.webshopDetail.DomainName + "&language_id=1&CustomerTheme=#9dbd17";
      this._httpWebshopService.triggerEmailOTP(emaildata).subscribe();
    }

    validate(){
      this.loaderShow = true;
    const data = 'Mobile='+ this.signupForm.value.ContactNumber.trim() + '&UserName='+ this.signupForm.value.ContactEmail.trim() + '&OTP=' + this.signupForm.value.mobileOTP;
    this._httpWebshopService.validateMobileOTP(data).subscribe((res)=>{
      if(res){
        this.mobileotpValidated = true;
        this.loaderShow = false;
        this.valOTP();
      }
    },_error=>{
      this.toastr.error('Error: Please enter the correct OTP');
      this.loaderShow = false;
    });

    const emaildata = 'Email='+ this.signupForm.value.ContactEmail.trim() + '&UserName='+ this.signupForm.value.ContactEmail.trim() + '&OTP=' + this.signupForm.value.emailOTP;
    this._httpWebshopService.validateEmailOTP(emaildata).subscribe((res)=>{
      if(res){
        this.emailotpValidated = true;
        this.valOTP();
      }
    },_error=>{
      this.toastr.error('Error: Please enter the correct OTP');
      this.loaderShow = false;
    });
    }

    valOTP(){
      if(this.emailotpValidated && this.mobileotpValidated){
        this.step1 = false;
        this.step2 = true;
      }
    }

    inrFormat(val: number) {
      return Number(val).toLocaleString("en-IN");
    }

    plans(id, i){
      this.subscriptionPlanId = id;
      this.btnSelect = i;
    }

    publish(){
      let url = window.location.origin;
      const param = {

        'OrderId': this.subscriptionPlanId,
			  'IsRewardKart': 'Y',
			  'Domain': this.signupForm.value.DomainName,
			  'Pre': 'SubScription',
			  'ReturnURL': url,
			  'Email': this.signupForm.value.ContactEmail,
			  'Mobile': this.signupForm.value.ContactNumber		 
			        
      }     

      
      /*const queryString = Object.keys(param)
      .map(key => key + '=' + encodeURIComponent(param[key]))
      .join('&');*/

      if(!this.signupForm.value.Domain && sessionStorage.getItem('shopReg')){
        let shop = JSON.parse(sessionStorage.getItem('shopReg'));
        this.signupForm.controls['Name'].setValue(shop.Name);
        this.signupForm.controls['ContactEmail'].setValue(shop.ContactEmail);
        this.signupForm.controls['ContactNumber'].setValue(shop.ContactNumber);
        this.signupForm.controls['DomainName'].setValue(shop.DomainName);
        this.signupForm.controls['Password'].setValue(shop.Password);
        this.signupForm.controls['CompanyName'].setValue(shop.CompanyName);
        this.customerLogoName = shop.ImageName;
      }

      const queryString = 'OrderId='+this.subscriptionPlanId+'&IsRewardKart=Y&Domain='+this.signupForm.value.DomainName+'&Pre=SubScription&ReturnURL='+url+'&Email='+this.signupForm.value.ContactEmail+'&Mobile='+this.signupForm.value.ContactNumber;

  
    const paymentURL = environment.ENVIRONMENT.pbase + 'AtomPayment/AtomPaymentRequest?' + queryString;



      let params = {
        'Name': this.signupForm.value.Name,
			  'ContactEmail': this.signupForm.value.ContactEmail,
			  'ContactNumber': this.signupForm.value.ContactNumber,
			  'DomainName': this.signupForm.value.DomainName,
        'Password': this.signupForm.value.Password,
			  'Action': 'insert',
        'isWebShopActive': false,
        'DefaultInvoiceType': 1,
        'CompanyName': this.signupForm.value.CompanyName,
        'SubscriptionPlanID': this.subscriptionPlanId,
        'ImageName': this.customerLogoName,
			  'Translations': [
          {
            "LanguageID": 1,
            "LanguageCode": "en",
            "ObjectID": 0,
            "ObjectType": "Name",
            "PropertyName": "Name",
            "PropertyValue": this.signupForm.value.Name,
            "Action": 1
          }],
        'Themes': [
            {
                'Name': ' ',
                'Main': '#9dbd17',
                'Lable': '#666666',
                'Alert': '#da522f'
            }
        ]
      }

    sessionStorage.setItem('shopReg', JSON.stringify(params));

    if(this.planList[this.btnSelect].SubscriptionAmount){
      window.location.href = paymentURL;   
    }else{
      this.createShop();
    }
  }

  createShop(){
      let params = JSON.parse(sessionStorage.getItem('shopReg'));
      this._httpWebshopService.addShopDetails(params).subscribe((_data)=>{
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.shopParams = params;
        //this.toastr.success('Your pack has been successfully subscribed. Please check your inbox for login credentials.');
        sessionStorage.removeItem('shopReg');
        //this.router.navigate(['/']);
       /* setTimeout(() => {
          location.reload();
        }, 2000);*/
      })
  }

  proceed(){
    window.location.href = 'http://'+this.shopParams.DomainName+'.constient.us';
  }

  domainExists(postParams) {
    try {
      if (postParams === '' || postParams === null) {

      } else {
        this._httpWebshopService.getExistDomain(postParams).subscribe(
          (data: any[]) => {
            if (Boolean(data) === true) {
              this.toastr.error('Sorry! Domain name taken')
                this.signupForm.patchValue({
                  DomainName: '',
                }, { onlySelf: false, emitEvent: false });
              
            }
          })
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  companyExists(postParams) {
    try {
      if (postParams === '' || postParams === null) {

      } else {
        this._httpWebshopService.getExistCompany(postParams).subscribe(
          (data: any[]) => {
            if (Boolean(data) === true) {
              this.toastr.error('Sorry! Company name exists')
                this.signupForm.patchValue({
                  CompanyName: '',
                }, { onlySelf: false, emitEvent: false });
              
            }
          })
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

}
  