import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "app/linknet-common/globals/global.service";
import { Title } from "@angular/platform-browser";
import { CampaignService, CategoryService, ProductService } from "app/linknet-common/restful-services/restful.service";

@Component({
    selector: 'shop-app-multi-search',
    templateUrl: './multiple-search.component.html',
    styleUrls: ['./multiple-search.component.scss']
})

export class MultiSearchComponent implements OnInit {
    categoryids:any;
    perPageCount: any = 20;
    currentPage: any = 1;
    products: any;
    spinnerShow: boolean = false;
    productFiveColumnStatus: boolean = false;
    subCategoryDisplayStatus: boolean = true;
    isShowFilter: boolean = true;
    hideDelete: boolean = true;
    campaignList: boolean = false;
    totalCount: any = 0;
    optionSubCategory: any = [];
    campaignId: any;
    isParentCustomer: boolean = true;
    constructor(public titleService: Title, public campaignService: CampaignService,private productService: ProductService, private router: Router,  public globalService: GlobalService,  private _httpCategories: CategoryService,
        private service: ProductService
    ){
        let url1 = window.location + '';
        let url = decodeURIComponent(url1);
        let s = url.split('multi-search/')
        let ids = s[1].split('/')
        this.categoryids = ids.filter(function (el) {
            return el !== '';
        });
        let categoryList = JSON.parse(sessionStorage.getItem('categoryList'));
        if(!categoryList){
          categoryList = this._httpCategories.getCategories().toPromise();
        }
        this.optionSubCategory.push({
            'Name': 'Select Sub-Category',
            'CategoryID': null,
            'ParentID': null
        });
        categoryList.forEach(element => {
            if(this.categoryids.includes(element.CategoryID.toString())){
                if(element.SubCategories.length !== 0){
                    element.SubCategories.forEach(e=>{
                        this.optionSubCategory.push(e)
                    })
                }
            }
        });

        const webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
        if (webshopDetail.ParentCustomerDomainName === webshopDetail.DomainName) {
          this.isParentCustomer = true;
          this.titleService.setTitle('Parent Shop');
        } else {
          this.isParentCustomer = false;
          this.titleService.setTitle('Child Shop');
          if(webshopDetail.DomainName === "voucherkart"){
            let query = window.location.search;
    
            if(query.indexOf("?Status=Y") !== -1){
            }else{
            //   this.titleService.setTitle("Voucherkart");
              this.titleService.setTitle("gift1to1");
              this.router.navigate(['/vouchers']);
            }
          }
        else{
          this.campaignService.getActiveCampaign().subscribe((res) => {
            if (res) {
              const loggedInData = this.globalService.getLoggedInData();
              if(loggedInData){
              this.campaignId = res.CampaignId;
             }
            }
          });
        }
       }
       
       this.getProducts();
    }

    ngOnInit() {
        
    }

    getProducts(){
        const webShopSettings = this.globalService.webShopSettings();
        const userData = this.globalService.getLoggedInData();
        let currencyiD = this.service.getCurrenct() ? this.service.getCurrenct() : JSON.parse(sessionStorage.getItem("countryID"));
        let countryID = this.service.getCountryID() ? this.service.getCountryID() : JSON.parse(sessionStorage.getItem("dropCountryID"));
        let param = {
            "BrandId": null,
	        "CategoryIDs": this.categoryids,
            "ShowPricesIncludingLogoType": null,
            "ProductName": null,
            "Quantity": null,
            "PriceFrom": null,
            "PriceTo": null,
            "ColorID": null,
            "DeliveryDate": null,
            "SortBy": null,
            "CampaignId": parseInt(this.campaignId),
            "MinimumSupplierQualityLevel": null,
            "FilterBatchProducts": true,
            "ProductTagIDs": [],
            "CurrencyID": 20,
            "CustomerID": webShopSettings.CustomerID,
            "isWebshop": true,
            "UserID": userData.UserID,
            "FirstSubcategoryRule": false
        }
        if(currencyiD !== "ALL" && currencyiD !== null && currencyiD !== undefined) {
            param["NewCurrencyID"] = currencyiD;
        }
        if(countryID !== "ALL" && countryID !== null && countryID !== undefined) {
            param["NewCountryID"] = countryID;
        }

        this.spinnerShow = true;
        let url = '';

        if(!this.isParentCustomer){
            url = 'Searchv3'
        }else{
            url = 'PostSearchv2';
        }

        this.productService.searchCategoriesProducts(url, param, this.currentPage, this.perPageCount).subscribe(data=>{
            this.products = data['ItemsCollection'];
            this.totalCount = data['TotalItemsCount'];
            this.spinnerShow = false;
        })
    }

    paginate(page) {
        this.currentPage = page.pageIndex;
        this.perPageCount = page.pageSize;
        this.getProducts();
        window.scrollTo(0,0);
    }

    searchProduct(item){
        if(item.CategoryID){
            sessionStorage.setItem('selectedCatId', item.ParentID);
            if(item.Name === 'Select Sub-Category'){
                item.Name = sessionStorage.getItem('selectedCatName')
            }
            const str = item.Name;
            item.Name = str.replace(/ +/g, '');
            sessionStorage.setItem('pageSize', JSON.stringify(20));
            sessionStorage.setItem('pageIndex', JSON.stringify(1));
            this.router.navigate(['/search', item.CategoryID, item.Name], { replaceUrl: false });
        }else{
            this.router.navigate(['/']);
        }
    }
}